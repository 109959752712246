import React, { useCallback } from 'react'

import PersonalInfoForm from 'components/personal-info-form'

const FormWizardStepOne = ({ checkout, onSubmit }) => {
  const getFormFields = useCallback(() => {
    let fields = {}

    if (checkout?.confirmar_nome) fields.nome = checkout?.nome
    if (checkout?.confirmar_celular) fields.celular = checkout?.celular
    if (checkout?.confirmar_sexo) fields.sexo = checkout?.sexo
    if (checkout?.confirmar_cpf) fields.cpf = checkout?.cpf
    if (checkout?.confirmar_dtnasc) fields.data_nascimento = checkout?.data_nascimento

    return fields
  }, [checkout])

  return <PersonalInfoForm fields={getFormFields()} onSubmit={onSubmit} />
}

export default FormWizardStepOne
