import React, { useState } from 'react'
import { Table, Typography } from 'antd'
import { useFetch } from 'use-http'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'
import TrendInfo from '../../trend-info'
import DateRangePicker from '../../range-picker'

import { createAdminGetUrl } from 'utils'

const { Column } = Table
const { Text } = Typography

const LeadsPorAfiliados = () => {
  const [dateRange, setDateRange] = useState({
    startDate: '01%2F01%2F1900',
    endDate: '01%2F01%2F1900',
  })

  const { data, loading, error } = useFetch(
    createAdminGetUrl(
      'DashMKTVendasLeadsporAfiliado',
      `${dateRange.startDate}/${dateRange.endDate}`
    ),
    null,
    [dateRange]
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="Leads por afiliado"
      rightComponent={
        <DateRangePicker
          initialValues={{ startDate: data?.inicio, endDate: data?.fim }}
          onDateSelection={setDateRange}
        />
      }
    >
      <Table size="small" showHeader={false} pagination={false} dataSource={data ? data.itens : []}>
        <Column
          dataIndex="leads"
          key="leads"
          align="right"
          render={value => {
            return (
              <MetricNumber value={Number(value)}>
                {({ formatedNumber }) => formatedNumber}
              </MetricNumber>
            )
          }}
        />
        <Column
          dataIndex="afiliado"
          key="afiliado"
          render={(label, row) => (
            <>
              <Text type="secondary">
                {label} {row.evolucao ? <TrendInfo info={row.evolucao} /> : ''}
              </Text>
            </>
          )}
        />
      </Table>
    </GenericCard>
  )
}

export { LeadsPorAfiliados }
