import React, { useState } from 'react'
import { useFetch } from 'use-http'
import { Responsive, WidthProvider } from 'react-grid-layout'

import * as AdminCards from '../cards'
import { useAdminPanel, ACTIONS, DEFAULT_GRID_LAYOUTS } from 'context/admin-panel-context'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

const ResponsiveGridLayout = WidthProvider(Responsive)

const postUrl = `
  ${process.env.REACT_APP_API_POST_URL}DashGravaLayout
`

const GridLayout = ({ id, settings }) => {
  const [context, dispatch] = useAdminPanel()
  const [breakpoint, setBreakpoint] = useState()

  const { gridLayouts } = context

  const { post } = useFetch(postUrl, null, [])

  const handleLayoutChange = layoutChange => {
    const userSession = JSON.parse(window.localStorage.getItem('adp_admin:user_session'))

    if (breakpoint && userSession) {
      const newLayout = {
        ...gridLayouts,
        [id]: {
          ...gridLayouts[id],
          layouts: {
            ...gridLayouts[id].layouts,
            [breakpoint]: layoutChange,
          },
        },
      }

      const layout = JSON.stringify(newLayout)

      const payload = {
        token_app: process.env.REACT_APP_TOKEN_APP,
        token_sessao: userSession.token,
        layout,
      }
      return post(payload)
    }

    return console.error('Cannot handle layout change')
  }

  return (
    <>
      <ResponsiveGridLayout
        className="layout"
        rowHeight={30}
        containerPadding={[0, 0]}
        margin={[15, 15]}
        layouts={settings.layouts || []}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 2, xs: 1, xxs: 1 }}
        onLayoutChange={handleLayoutChange}
        onBreakpointChange={setBreakpoint}
      >
        {settings.components.map(component => {
          const Card = AdminCards[component]

          return (
            <div key={component}>
              <Card />
            </div>
          )
        })}
      </ResponsiveGridLayout>
    </>
  )
}

GridLayout.propTypes = {}

GridLayout.defaultProps = {}

export default GridLayout
