import React, { PureComponent } from 'react'
import { Button } from 'antd'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Upload } from 'antd'

import './styles.css'

class UploadAndCrop extends PureComponent {
  state = {
    crop: {
      unit: 'px',
      width: 200,
      aspect: 1 / 1,
    },
    file: null,
    imageUrl: null,
    croppedImageUrl: null,
  }

  onChange = info => {
    const reader = new FileReader()
    this.setState({ file: info.file })

    reader.addEventListener('load', () => {
      this.setState({ imageUrl: reader.result })
      this.props.onImageChange(reader.result)
    })
    reader.readAsDataURL(info.file)
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop)
  }

  onCropChange = crop => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop })
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop)
      this.props.onImageChange(croppedImageUrl)
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const croppendImageUrl = canvas.toDataURL()
    return croppendImageUrl
  }

  render() {
    const { crop, imageUrl } = this.state

    return (
      <div className="upload-body">
        {imageUrl && (
          <div className="crop-wrapper">
            <ReactCrop
              src={imageUrl}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </div>
        )}

        <Upload
          style={{ width: '100%' }}
          method="post"
          listType="text"
          showUploadList={false}
          beforeUpload={() => false}
          onChange={this.onChange}
        >
          {imageUrl ? (
            <Button size="small" type="text">
              Selecionar outra imagem
            </Button>
          ) : (
            <Button type="primary">Selecionar uma imagem</Button>
          )}
        </Upload>
      </div>
    )
  }
}

export default UploadAndCrop
