import React from 'react'
import { Select } from 'antd'

import * as S from './syles'

const InstallmentsSelect = ({ onSelect, installments, error = '' }) => {
  return (
    <>
      <S.Label>
        Selecione as parcelas:
        <S.Select onChange={onSelect} placeholder="Não informado" size="small" error={error}>
          {installments.map(installment => (
            <Select.Option
              key={`InstallmentsSelect-${installment.parcelas}`}
              value={installment.parcelas}
            >
              {installment.parcelas}x de {installment.valor}
            </Select.Option>
          ))}
        </S.Select>
      </S.Label>

      {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
    </>
  )
}

export default InstallmentsSelect
