import styled from 'styled-components'

import { colors, sizes } from 'styles'

export const QuestionLetter = styled.div`
  width: 24px;
  height: 24px;
  border-radius: ${sizes.smallBorderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background-color: ${({ active }) => (active ? colors.primaryColor : colors.lighterBackground)};
  text-transform: uppercase;
`
