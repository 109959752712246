import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { colors } from 'styles'
import MetricNumber from '../metric-number'

const ChartStyles = createGlobalStyle`
  .recharts-text.recharts-cartesian-axis-tick-value {
    font-size: 12px;
  }
`

const TooltipBox = styled.div`
  background-color: #3e3e3e;
  color: #fff;
  border: 0;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
`

const LineChart = ({ data, legendPrefix, isMoney }) => {
  const formatedData = useMemo(
    () =>
      data.map(item => {
        const formattedNumber = item.y.toFixed(2)
        return { ...item, y: Number(formattedNumber) }
      }),
    [data]
  )

  return (
    <>
      <ResponsiveContainer width="100%" height="70%">
        <RechartsLineChart data={formatedData} margin={{ top: 5, right: 5, bottom: 5, left: -10 }}>
          <Tooltip
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                if (payload.length > 1) {
                  return (
                    <TooltipBox>
                      <label style={{ fontSize: 14 }}>{label}</label>
                      {payload.map(({ value, stroke, name, payload: lineData }) => (
                        <div key={`${name}-${stroke}-${value}`}>
                          <MetricNumber
                            value={value}
                            prefix={legendPrefix}
                            style={{ fontSize: 16, color: stroke }}
                            isMoney={isMoney}
                            sideLabel={lineData[`legenda_${name}`]}
                          >
                            {({ formatedNumber }) => formatedNumber}
                          </MetricNumber>
                        </div>
                      ))}
                    </TooltipBox>
                  )
                }

                return (
                  <TooltipBox>
                    <label style={{ fontSize: 14 }}>{label}</label>
                    <div>
                      <MetricNumber
                        value={payload[0].value}
                        prefix={legendPrefix}
                        isMoney={isMoney}
                        style={{ fontSize: 16, color: colors.primaryColor }}
                      >
                        {({ formatedNumber }) => formatedNumber}
                      </MetricNumber>
                    </div>
                  </TooltipBox>
                )
              }

              return null
            }}
            contentStyle={{
              backgroundColor: '#2b2b2b',
              color: '#fff',
              border: 0,
              boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.2)',
              borderRadius: 4,
            }}
          />
          <YAxis
            axisLine={false}
            domain={['dataMin', 'dataMax']}
            tickLine={{ stroke: 'rgba(255, 255, 255, 0.2)' }}
          />

          <XAxis
            padding={{ left: 4, right: 4 }}
            dataKey="x"
            axisLine={false}
            tickLine={{ stroke: 'rgba(255, 255, 255, 0.2)' }}
          />

          <Line
            type="linear"
            dataKey="y"
            stroke={colors.primaryColor}
            strokeWidth={2}
            dot={false}
            activeDot={false}
          />

          <Line
            type="linear"
            dataKey="w"
            stroke={colors.lightGreen}
            strokeWidth={2}
            dot={false}
            activeDot={false}
          />

          <Line
            type="linear"
            dataKey="r"
            stroke={colors.orange}
            strokeWidth={2}
            dot={false}
            activeDot={false}
          />

          <Line
            type="linear"
            dataKey="z"
            stroke={colors.blue}
            strokeWidth={2}
            dot={false}
            activeDot={false}
          />
        </RechartsLineChart>
      </ResponsiveContainer>
      <ChartStyles />
    </>
  )
}

LineChart.propTypes = {
  legendPrefix: PropTypes.string,
  isMoney: PropTypes.bool,
}

LineChart.defaultProps = {
  legendPrefix: '',
  isMoney: false,
}

export default LineChart
