import React, { useEffect, useState } from 'react'
import {
  Button,
  Typography,
  Card,
  Input as InputAntd,
  Space,
  Row,
  Col,
  Tabs,
  Table,
  Tag,
  Tooltip,
  message,
  Select,
  Menu,
  Modal,
  Popover,
} from 'antd'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faBars } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Formik } from 'formik'
import { Form, FormItem, Input } from 'formik-antd'
import InputMask from 'react-input-mask'
import * as Yup from 'yup'
import useFetch from 'use-http'

import { Container, BaseTemplate, AfiliateWithdrawModal } from 'components'
import { createAuthUrl, getTokenApp, getAuthData } from 'utils'
import { IS_FALSE, IS_TRUE } from 'enums'

import './styles.scss'

const { TabPane } = Tabs
const { Column } = Table
const { Option } = Select

const AffiliatesPage = () => {
  const [withdrawModal, setWithdrawModal] = useState(false)
  const [statementLength, setStatementLength] = useState(5)
  const [historyLength, setHistoryLength] = useState(5)
  const [period, setPeriod] = useState('este_mes')
  const [activeTab, setActiveTab] = useState('afiliates:tab:1')
  const [withdrawInfo, setWithdrawInfo] = useState({})
  const [shareModal, setShareModal] = useState(false)
  const [shouldOpenWithdrawModalAfterSave, setShouldOpenWithdrawModalAfterSave] = useState(false)

  const {
    get: getGlobalInfo,
    cache: infoCache,
    loading: infoLoading,
    error: infoError,
    data: info = {},
  } = useFetch(
    `${process.env.REACT_APP_API_URL}AfiliadosGlobaisInfos/${createAuthUrl()}}`,
    null,
    []
  )

  const { data: hints = {} } = useFetch(
    `${process.env.REACT_APP_API_URL}AfiliadosGlobaisIndicadosTotais/${createAuthUrl()}/${period}`,
    null,
    [period]
  )

  const {
    loading: afiliatesLoading,
    error: afiliatesError,
    data: afiliatesList = [],
  } = useFetch(
    `${process.env.REACT_APP_API_URL}AfiliadosGlobaisIndicados/${createAuthUrl()}}/${period}`,
    null,
    [period]
  )

  const {
    get: getStatement,
    cache: statementCache,
    loading: statementLoading,
    error: statementError,
    data: statementList = [],
  } = useFetch(
    `${
      process.env.REACT_APP_API_URL
    }AfiliadosGlobaisExtrato/${createAuthUrl()}}/${statementLength}`,
    null,
    [statementLength]
  )

  const {
    get: getWithdrawInfo,
    loading: withdrawLoading,
    error: withdrawError,
    response: withdrawResponse = {},
    cache,
  } = useFetch(`${process.env.REACT_APP_API_URL}AfiliadosSaqueInfos/${createAuthUrl()}}/`)

  const { loading: historyLoading, error: historyError, data: historyList = [] } = useFetch(
    `${
      process.env.REACT_APP_API_URL
    }AfiliadosGlobaisHistoricoSaques/${createAuthUrl()}}/${historyLength}`,
    null,
    [historyLength]
  )

  const { loading: banksLoading, error: banksError, data: banksList = [] } = useFetch(
    `${process.env.REACT_APP_API_URL}CarregaBancos/${getTokenApp()}}`,
    null,
    [historyLength]
  )

  const {
    post: saveBankAccount,
    loading: saveBankAccountLoading,
    response: saveBankAccountResponse = {},
  } = useFetch(`${process.env.REACT_APP_API_POST_URL}AfiliadosGlobaisCadastraConta`)

  const { post: doWithdraw, response: doWithdrawResponse = {} } = useFetch(
    `${process.env.REACT_APP_API_POST_URL}AfiliadosGlobaisSaque`
  )

  useEffect(() => {
    if (infoError || afiliatesError || historyError || banksError || withdrawError) {
      message.error(`Ocorreu um error ao tentar carregar a um dado!`, 0)
    }
  }, [infoError, afiliatesError, banksError, withdrawError, historyError])

  const fetchWithdrawInfo = async () => {
    const data = await getWithdrawInfo()

    if (withdrawResponse.ok) {
      setWithdrawInfo(data)
    }
  }

  useEffect(() => {
    fetchWithdrawInfo()
    // eslint-disable-next-line
  }, [])

  const getCurrentBank = bankId => {
    const bankFound = banksList.filter(bank => bank.id_banco === bankId)

    if (bankFound.length) {
      return bankFound[0]
    }

    return null
  }

  return (
    <BaseTemplate>
      <Helmet>
        <title>Afiliados - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <Container boxed>
        <br />
        <br />
        <Typography.Title>{info.titulo_pagina}</Typography.Title>
        <Row gutter={32}>
          <Col xs={24} sm={24} md={9} xl={9}>
            <Space direction="vertical" size="large">
              <Card loading={infoLoading}>
                {infoError ? (
                  <div>Erro!</div>
                ) : (
                  <Space direction="vertical" size="middle">
                    <span style={{ textAlign: 'center' }}>
                      Copie o link abaixo e compartilhe. Você ganha a cada pessoa que se matricular
                      pelo seu link:
                    </span>
                    <InputAntd value={info.url_afiliado} />

                    <Button type="primary" onClick={() => setShareModal(true)} block>
                      Compartilhar link
                    </Button>

                    <h4 style={{ textAlign: 'center' }}>{info.frase_chamada}</h4>

                    <Button type="link" block>
                      <a href={info.url_como_funciona} target="_blank" rel="noopener noreferrer">
                        Saiba mais
                      </a>
                    </Button>
                  </Space>
                )}
              </Card>

              <Card loading={infoLoading}>
                {infoError ? (
                  <div>Erro!</div>
                ) : (
                  <Space direction="vertical" size="middle">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <Typography.Text type="secondary">
                          <Tooltip placement="top" title={info.frase_saldo_liberado}>
                            <Space>
                              <small>Saldo liberado</small>
                              <FontAwesomeIcon icon={faQuestionCircle} />
                            </Space>
                          </Tooltip>
                        </Typography.Text>
                        <Typography.Title level={3} style={{ fontWeight: 400, marginTop: 0 }}>
                          {info.saldo_liberado}
                        </Typography.Title>
                      </div>

                      <div>
                        <Typography.Text type="secondary">
                          <Tooltip placement="top" title={info.frase_saldo_total}>
                            <Space>
                              <small>Saldo pendente</small>
                              <FontAwesomeIcon icon={faQuestionCircle} />
                            </Space>
                          </Tooltip>
                        </Typography.Text>
                        <Typography.Title
                          type="secondary"
                          level={3}
                          style={{ fontWeight: 400, marginTop: 0 }}
                        >
                          {info.saldo_total}
                        </Typography.Title>
                      </div>
                    </div>

                    <Button
                      block
                      onClick={async () => {
                        if (withdrawInfo.saque_autorizado === IS_FALSE) {
                          return message.error(withdrawInfo.frase_saque)
                        }

                        cache.clear()
                        fetchWithdrawInfo()

                        if (withdrawResponse.ok) {
                          setWithdrawModal(true)
                        }
                      }}
                      loading={withdrawLoading}
                      disabled={
                        info.saldo_liberado &&
                        info.saque_minimo &&
                        Number(info.saldo_liberado.split(' ')[1]) <
                          Number(info.saque_minimo.split(' ')[1])
                      }
                    >
                      {info.saldo_liberado &&
                      info.saque_minimo &&
                      Number(info.saldo_liberado.split(' ')[1]) <
                        Number(info.saque_minimo.split(' ')[1])
                        ? `Saque mínimo de ${info?.saque_minimo}`
                        : 'Solicitar saque'}
                    </Button>
                  </Space>
                )}
              </Card>
            </Space>

            <br />
            <br />
          </Col>

          <Col xs={24} sm={24} md={15} xl={15}>
            <Tabs
              defaultActiveKey={activeTab}
              activeKey={activeTab}
              renderTabBar={props => (
                <Menu
                  mode="horizontal"
                  selectedKeys={[activeTab]}
                  style={{ marginBottom: '1rem' }}
                  overflowedIndicator={
                    <Button type="secondary" shape="circle" size="small" style={{ border: 0 }}>
                      <FontAwesomeIcon icon={faBars} />
                    </Button>
                  }
                >
                  <Menu.Item key="afiliates:tab:1" onClick={props => setActiveTab(props.key)}>
                    Pessoas indicadas
                  </Menu.Item>
                  <Menu.Item key="afiliates:tab:2" onClick={props => setActiveTab(props.key)}>
                    Extrato
                  </Menu.Item>
                  <Menu.Item key="afiliates:tab:3" onClick={props => setActiveTab(props.key)}>
                    Histórico de saque
                  </Menu.Item>
                  <Menu.Item key="afiliates:tab:4" onClick={props => setActiveTab(props.key)}>
                    Dados bancários
                  </Menu.Item>
                </Menu>
              )}
            >
              <TabPane tab="Pessoas indicadas" key="afiliates:tab:1" className="affiliates-tabs">
                {afiliatesError ? (
                  <div>Error!</div>
                ) : (
                  <Space direction="vertical" size="large">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={6} xl={6}>
                        <Select
                          placeholder="Selecione um periodo"
                          style={{ width: '100%' }}
                          loading={infoLoading}
                          defaultValue="ESTE MÊS"
                          onChange={value => {
                            setPeriod(value)
                          }}
                        >
                          {info.itens_combo_periodo?.length &&
                            info.itens_combo_periodo.map(item => (
                              <Option key={`items-combo-${item.valor}`} value={item.valor}>
                                {item.nome}
                              </Option>
                            ))}
                        </Select>
                      </Col>
                      <Col xs={24} sm={24} md={6} xl={6}>
                        <Card size="small">
                          <label>
                            <Tooltip placement="top" title={hints.hint_cadastrados}>
                              <small>Cadastrados </small>
                              <FontAwesomeIcon icon={faQuestionCircle} style={{ opacity: 0.6 }} />
                            </Tooltip>

                            <div>
                              <Typography.Text style={{ fontSize: 20, marginBottom: 0 }}>
                                {hints.total_cadastrados || '-'}
                              </Typography.Text>
                            </div>
                          </label>
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={6} xl={6}>
                        <Card size="small">
                          <label>
                            <Tooltip placement="top" title={hints.hint_pendentes}>
                              <Typography.Text type="warning">
                                <small>Pendentes </small>
                              </Typography.Text>
                              <FontAwesomeIcon icon={faQuestionCircle} style={{ opacity: 0.6 }} />
                            </Tooltip>

                            <div>
                              <Typography.Text
                                type="warning"
                                style={{ fontSize: 20, marginBottom: 0 }}
                              >
                                {hints.total_pendentes || '-'}
                              </Typography.Text>
                            </div>
                          </label>
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={6} xl={6}>
                        <Card size="small">
                          <label>
                            <Tooltip placement="top" title={hints.hint_assinantes}>
                              <Typography.Text type="success">
                                <small>Assinantes </small>
                              </Typography.Text>

                              <FontAwesomeIcon icon={faQuestionCircle} style={{ opacity: 0.6 }} />
                            </Tooltip>

                            <div>
                              <Typography.Text
                                type="success"
                                style={{ fontSize: 20, marginBottom: 0 }}
                              >
                                {hints.total_assinantes || '-'}
                              </Typography.Text>
                            </div>
                          </label>
                        </Card>
                      </Col>
                    </Row>
                    <Table
                      locale={{
                        emptyText: 'Nenhum registro encontrado',
                        filterConfirm: 'Filtrar',
                        filterReset: 'Limpar',
                      }}
                      pagination={false}
                      bordered
                      loading={afiliatesLoading}
                      dataSource={afiliatesList}
                      scroll={{ x: 600 }}
                    >
                      <Column width="200" title="Nome" dataIndex="nome" key="nome" />
                      <Column
                        width="200"
                        title="Status"
                        dataIndex="status"
                        key="status"
                        filters={[
                          {
                            text: 'Cadastrado',
                            value: 'cadastrado',
                          },
                          {
                            text: 'Pendente',
                            value: 'pendente',
                          },
                          {
                            text: 'Assinante',
                            value: 'assinante',
                          },
                        ]}
                        onFilter={(value, record) => record.status.indexOf(value) === 0}
                        render={status =>
                          status === 'cadastrado' ? (
                            <Tag key={status}>{status}</Tag>
                          ) : status === 'pendente' ? (
                            <Tag color="gold" key={status}>
                              {status}
                            </Tag>
                          ) : (
                            <Tag color="green" key={status}>
                              {status}
                            </Tag>
                          )
                        }
                      />
                      <Column
                        width="200"
                        title="Data"
                        dataIndex="data_cadastro"
                        key="data_cadastro"
                        align="right"
                      />
                    </Table>
                  </Space>
                )}
              </TabPane>

              <TabPane tab="Extrato" key="afiliates:tab:2" className="affiliates-tabs">
                {statementError ? (
                  <div>Error!</div>
                ) : (
                  <Space direction="vertical" align="center" size="middle">
                    <Table
                      pagination={false}
                      bordered
                      loading={statementLoading}
                      dataSource={statementList}
                      locale={{ emptyText: 'Nenhum registro encontrado' }}
                      scroll={{ x: 600 }}
                    >
                      <Column
                        title="Descrição"
                        dataIndex="descricao"
                        key="descricao"
                        render={(description, item) => (
                          <>
                            {description}{' '}
                            {item.detalhamento !== '' && (
                              <Popover
                                content={<p>{item.detalhamento}</p>}
                                title="Detalhamento"
                                trigger="click"
                              >
                                <Button type="link" size="small">
                                  Detalhes
                                </Button>
                              </Popover>
                            )}
                          </>
                        )}
                      />
                      <Column title="Data" dataIndex="data" key="data" />
                      <Column title="Valor" dataIndex="valor" key="valor" align="right" />
                    </Table>

                    <Button
                      type="secondary"
                      size="small"
                      onClick={() => setStatementLength(statementLength + 5)}
                    >
                      Carregar mais
                    </Button>
                  </Space>
                )}
              </TabPane>

              <TabPane tab="Histórico de saque" key="afiliates:tab:3" className="affiliates-tabs">
                {historyError ? (
                  <div>Error!</div>
                ) : (
                  <Space direction="vertical" align="center" size="middle">
                    <Table
                      pagination={false}
                      bordered
                      loading={historyLoading}
                      dataSource={historyList}
                      locale={{ emptyText: 'Nenhum registro encontrado' }}
                      scroll={{ x: 600 }}
                    >
                      <Column width={300} title="Descrição" dataIndex="descricao" key="descricao" />
                      <Column title="Situação" dataIndex="situacao" key="situacao" />
                      <Column title="Solicitação" dataIndex="data" key="data" />
                      <Column title="Previsão" dataIndex="data_previsao" key="data_previsao" />
                      <Column title="Valor" dataIndex="valor" key="valor" align="right" />
                    </Table>

                    <Button
                      type="secondary"
                      size="small"
                      onClick={() => setHistoryLength(historyLength + 5)}
                    >
                      Carregar mais
                    </Button>
                  </Space>
                )}
              </TabPane>

              <TabPane tab="Dados bancários" key="afiliates:tab:4" className="affiliates-tabs">
                <Formik
                  initialValues={{
                    titular_conta: withdrawInfo.titular_conta || '',
                    tipo_conta_pf_pj: withdrawInfo.tipo_conta_pf_pj || undefined,
                    cpf_cnpj: withdrawInfo.cpf_cnpj || '',
                    id_banco: withdrawInfo.id_banco || '',
                    agencia: withdrawInfo.agencia || '',
                    conta: withdrawInfo.conta || '',
                    digito_conta: withdrawInfo.digito_conta || '',
                    tipo_conta_cc_cp: withdrawInfo.tipo_conta_cc_cp || undefined,
                  }}
                  validationSchema={Yup.object({
                    titular_conta: Yup.string().required('Titular da conta é obrigatório'),
                    tipo_conta_pf_pj: Yup.mixed().oneOf(['0', '1']).required('Selecione uma opção'),
                    cpf_cnpj: Yup.string().required('Este campo é obrigatório'),
                    id_banco: Yup.string().required('Banco é obrigatório'),
                    agencia: Yup.string()
                      .required('Agência é obrigatório')
                      .max(4, 'Insira até 4 digitos'),
                    conta: Yup.string().required('Conta é obrigatório'),
                    digito_conta: Yup.string().required('Digito da conta é obrigatório'),
                    tipo_conta_cc_cp: Yup.mixed()
                      .oneOf(['0', '1'])
                      .required('Tipo de conta é obrigatório'),
                  })}
                  onSubmit={async values => {
                    await saveBankAccount({
                      ...getAuthData(),
                      ...values,
                    })

                    if (saveBankAccountResponse.ok) {
                      message.success('Dados bancários atualizados com sucesso!')

                      if (shouldOpenWithdrawModalAfterSave) {
                        cache.clear()
                        fetchWithdrawInfo()
                        setShouldOpenWithdrawModalAfterSave(false)
                        setTimeout(() => {
                          setWithdrawModal(true)
                        }, 500)
                      }
                    }
                  }}
                >
                  {({ values, errors, touched, setFieldValue }) => {
                    return (
                      <Form layout="vertical">
                        <FormItem
                          label="Nome do titular da conta"
                          name="titular_conta"
                          validateStatus={touched.titular_conta && errors.titular_conta && 'error'}
                          help={touched.titular_conta && errors.titular_conta}
                        >
                          <Input
                            placeholder="Nome do Titular da conta"
                            name="titular_conta"
                            size="small"
                          />
                        </FormItem>

                        <Row gutter={[16, 8]}>
                          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <FormItem
                              label="Entidade"
                              validateStatus={
                                touched.tipo_conta_pf_pj && errors.tipo_conta_pf_pj && 'error'
                              }
                              help={touched.tipo_conta_pf_pj && errors.tipo_conta_pf_pj}
                            >
                              <Select
                                size="small"
                                placeholder="Selecione"
                                onChange={value => setFieldValue('tipo_conta_pf_pj', value)}
                                defaultValue={values.tipo_conta_pf_pj}
                              >
                                <Option value="1">Pessoa Física</Option>
                                <Option value="0">Pessoa Jurídica</Option>
                              </Select>
                            </FormItem>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                            <FormItem
                              label={`Número do ${
                                values.tipo_conta_pf_pj === '1' ? 'CPF' : 'CNPJ'
                              }`}
                              validateStatus={touched.cpf_cnpj && errors.cpf_cnpj && 'error'}
                              help={touched.cpf_cnpj && errors.cpf_cnpj}
                            >
                              <InputMask
                                mask={
                                  values.tipo_conta_pf_pj === '1'
                                    ? '999.999.999-99'
                                    : '99.999.999/9999-99'
                                }
                                placeholder={
                                  values.tipo_conta_pf_pj === '1'
                                    ? '***.***.***-**'
                                    : '**.***.***/****-**'
                                }
                                name="cpf_cnpj"
                                size="small"
                                value={values.cpf_cnpj}
                                onChange={e => setFieldValue('cpf_cnpj', e.target.value)}
                              >
                                {inputProps => <Input {...inputProps} />}
                              </InputMask>
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={[16, 8]}>
                          <Col xs={24} sm={24} md={12} lg={18} xl={18}>
                            <FormItem
                              label="Banco"
                              validateStatus={touched.id_banco && errors.id_banco && 'error'}
                              help={touched.id_banco && errors.id_banco}
                            >
                              <Select
                                placeholder="Selecione um banco"
                                size="small"
                                loading={banksLoading}
                                onChange={value => {
                                  setFieldValue('tipo_conta_cc_cp', undefined)
                                  setFieldValue('id_banco', value)
                                }}
                                defaultValue={values.id_banco}
                              >
                                {banksList.map(bank => (
                                  <Option key={`bank-id-${bank.id_banco}`} value={bank.id_banco}>
                                    {bank.nome_banco}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          </Col>

                          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <FormItem
                              label="Tipo de conta"
                              validateStatus={
                                touched.tipo_conta_cc_cp && errors.tipo_conta_cc_cp && 'error'
                              }
                              help={touched.tipo_conta_cc_cp && errors.tipo_conta_cc_cp}
                            >
                              <Select
                                size="small"
                                onChange={value => setFieldValue('tipo_conta_cc_cp', value)}
                                disabled={!values.id_banco}
                                placeholder="Selecione um banco"
                                value={values.tipo_conta_cc_cp}
                              >
                                {getCurrentBank(values.id_banco)?.aceita_poupanca === IS_TRUE && (
                                  <Option value="1">Conta poupança</Option>
                                )}
                                {getCurrentBank(values.id_banco)?.aceita_corrente === IS_TRUE && (
                                  <Option value="0">Conta corrente</Option>
                                )}
                              </Select>
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={[16, 8]}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <FormItem
                              label="Agência"
                              validateStatus={touched.agencia && errors.agencia && 'error'}
                              help={touched.agencia && errors.agencia}
                            >
                              <Input
                                placeholder="Agência"
                                name="agencia"
                                size="small"
                                maxLength={4}
                              />
                            </FormItem>
                          </Col>

                          <Col xs={14} sm={14} md={8} lg={8} xl={8}>
                            <FormItem
                              label="Nº da Conta"
                              validateStatus={touched.conta && errors.conta && 'error'}
                              help={touched.conta && errors.conta}
                            >
                              <Input placeholder="Nº Conta" name="conta" size="small" />
                            </FormItem>
                          </Col>

                          <Col xs={10} sm={10} md={8} lg={8} xl={8}>
                            <FormItem
                              label="Dígito da conta"
                              validateStatus={
                                touched.digito_conta && errors.digito_conta && 'error'
                              }
                              help={touched.digito_conta && errors.digito_conta}
                            >
                              <Input
                                placeholder="Dígito da conta"
                                name="digito_conta"
                                size="small"
                              />
                            </FormItem>
                          </Col>
                        </Row>

                        <Button
                          htmlType="submit"
                          type="primary"
                          size="small"
                          loading={saveBankAccountLoading}
                        >
                          Salvar dados bancários
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Container>

      <Modal
        title="Compartilhar link de indicação"
        visible={shareModal}
        footer={null}
        onCancel={() => setShareModal(false)}
      >
        <Space direction="vertical" size="large">
          <Button style={{ borderColor: '#548bff', color: '#548bff' }} size="small" block>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${info.url_afiliado}&quote=${info.frase_rede_social}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} size="lg" />
              <span style={{ paddingLeft: 8 }}>Compartilhar no Facebook</span>
            </a>
          </Button>

          <Button style={{ borderColor: '#07d2a3', color: '#07d2a3' }} size="small" block>
            <a
              href={`https://api.whatsapp.com/send?text=${info.frase_rede_social}%20${info.url_afiliado}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} size="lg" />
              <span style={{ paddingLeft: 8 }}>Compartilhar no WhatsApp</span>
            </a>
          </Button>

          <CopyToClipboard
            text={info.url_afiliado}
            onCopy={() => {
              message.success('Link de indicação copiado!')
              setShareModal(false)
            }}
          >
            <Button size="small" block>
              Copiar link de indicação
            </Button>
          </CopyToClipboard>
        </Space>
      </Modal>

      <AfiliateWithdrawModal
        title={
          withdrawInfo.tem_conta === IS_TRUE
            ? 'Confirme a conta para depósito'
            : 'Cadastre uma conta bancária'
        }
        visible={withdrawModal}
        data={withdrawInfo}
        openBankTab={() => {
          setWithdrawModal(false)
          setShouldOpenWithdrawModalAfterSave(true)
          setActiveTab('afiliates:tab:4')
        }}
        onClose={() => setWithdrawModal(false)}
        onOk={async () => {
          const data = await doWithdraw(getAuthData())

          if (doWithdrawResponse.ok) {
            if (data.resultado === '0') {
              infoCache.clear()
              statementCache.clear()
              getStatement()
              getGlobalInfo()
              setWithdrawModal(false)
              setActiveTab('afiliates:tab:2')

              return message.success(data.frase_saque)
            }

            return message.error(data.frase_saque)
          }
          setWithdrawModal(false)
        }}
      />
    </BaseTemplate>
  )
}

export default AffiliatesPage
