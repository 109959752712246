import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Picker } from 'emoji-mart'
import { Transition } from 'react-transition-group'
import 'emoji-mart/css/emoji-mart.css'
import OutsideClickHandler from 'react-outside-click-handler'

import * as S from './styles'

import { SendIcon, EmojiIcon } from './icons'
import pickerI18n from './picker-i18n'

const MessageInput = ({ onSendMessage, activeChannelId }) => {
  const [emojiPicker, setEmojiPicker] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef(null)
  const inputStateRef = useRef(inputValue)

  const handleSendMessage = message => {
    onSendMessage(encodeURIComponent(message))
    setInputValue('')
    inputStateRef.current = ''
  }

  const handleEnterPress = e => {
    if (!inputStateRef.current.trim().length) return

    const message = inputStateRef.current

    if (e.keyCode === 13 && message.length) {
      handleSendMessage(message)
    }
  }

  const handleInputChange = e => {
    const value = e.target.value

    inputStateRef.current = value
    setInputValue(value)
  }

  // const handleOpenEmojiPicker = () => {
  //   setEmojiPicker(!emojiPicker)
  // }

  const handleClickOutsidePicker = () => {
    if (emojiPicker) return setEmojiPicker(false)
  }

  const handleSelectEmoji = emojiEvent => {
    setInputValue(`${inputValue}${emojiEvent.native}`)
  }

  useEffect(() => {
    const input = inputRef.current

    if (input) {
      input.focus()

      input.addEventListener('keyup', handleEnterPress)
    }

    return () => input.removeEventListener('keyup', handleEnterPress)
    // eslint-disable-next-line
  }, [])

  const defaultStyle = {
    transition: `opacity 150ms ease-in-out`,
    position: 'absolute',
    bottom: 56,
    right: -44,
    zIndex: -99,
    pointerEvents: 'none',
  }

  const pickerTransitionStyles = {
    entering: { opacity: 1, zIndex: 99, pointerEvents: 'all' },
    entered: { opacity: 1, zIndex: 99, pointerEvents: 'all' },
    exiting: { opacity: 0, zIndex: -99, pointerEvents: 'none' },
    exited: { opacity: 0, zIndex: -99, pointerEvents: 'none' },
  }

  return (
    <S.MessageInput>
      <S.StyledInput
        ref={inputRef}
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Escreva aqui sua mensagem..."
        tabIndex="0"
        type="text"
        autocomplete="off"
      />

      <S.RightButtonsContainer>
        <OutsideClickHandler onOutsideClick={handleClickOutsidePicker}>
          <S.EmojiPickerWrapper>
            <S.EmojiButton onClick={() => setEmojiPicker(!emojiPicker)} active={emojiPicker}>
              <EmojiIcon />
            </S.EmojiButton>
            <Transition in={emojiPicker} timeout={50}>
              {state => (
                <div
                  style={{
                    ...defaultStyle,
                    ...pickerTransitionStyles[state],
                  }}
                >
                  <Picker
                    onSelect={handleSelectEmoji}
                    sheetSize={32}
                    title={null}
                    showPreview={false}
                    showSkinTones={false}
                    theme="light"
                    i18n={pickerI18n}
                    emoji=""
                  />
                </div>
              )}
            </Transition>
          </S.EmojiPickerWrapper>
        </OutsideClickHandler>

        <S.SendButton
          disabled={!inputValue.trim().length}
          onClick={() => handleSendMessage(inputValue)}
        >
          <SendIcon />
        </S.SendButton>
      </S.RightButtonsContainer>
    </S.MessageInput>
  )
}

MessageInput.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
}

const areEqual = (prevProps, nextProps) => {
  return prevProps.activeChannelId === nextProps.activeChannelId
}

export default React.memo(MessageInput, areEqual)
