import React from 'react'
import { Row, Col, Select, Button } from 'antd'
import InputMask from 'react-input-mask'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import * as S from './syles'
import { removeUderline } from 'utils'
import { validateFields } from 'utils/validate-fields'

const fieldValdiations = {
  endereco: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  estado: Yup.string().required('Selecione uma opção'),
  cidade: Yup.string().required('Campo obrigatório'),
  end_numero: Yup.string().required('Campo obrigatório'),
  cep: Yup.string()
    .test('is-cep-valid', 'Digite um CEP válido', value => {
      return removeUderline(value).length === 9
    })
    .required('Campo obrigatório'),
  complemento: Yup.string(),
}

const AndressForm = ({ onSubmit, onBack, helpText, initialValues = {} }) => {
  const { values, errors, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues,
    onSubmit: async (values, { setFieldError }) => {
      const errors = validateFields(values, fieldValdiations)

      if (errors.length) {
        errors.forEach(error => {
          setFieldError(error.field, error.message)
        })
        return
      }

      if (onSubmit) onSubmit({ ...values, pais: 'Brasil' })
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={[16, 0]}>
        {helpText && (
          <Col>
            <p style={{ marginTop: 16 }}>{helpText}</p>
          </Col>
        )}
        <Col span={18}>
          <S.Label>
            Endereço (Rua, Avenida):
            <S.Input
              size="small"
              name="endereco"
              value={values.endereco}
              onChange={handleChange}
              placeholder="Endereço"
              error={errors.endereco}
            />
          </S.Label>
          {errors.endereco && <S.ErrorMessage>{errors.endereco}</S.ErrorMessage>}
        </Col>

        <Col span={6}>
          <S.Label>
            Número:
            <S.Input
              size="small"
              name="end_numero"
              value={values.end_numero}
              onChange={handleChange}
              placeholder="Número"
              error={errors.end_numero}
            />
          </S.Label>
          {errors.end_numero && <S.ErrorMessage>{errors.end_numero}</S.ErrorMessage>}
        </Col>

        <Col span={6}>
          <S.Label>
            Estado:
            <S.Select
              value={values.estado}
              onChange={value => setFieldValue('estado', value)}
              placeholder="Não informado"
              size="small"
              error={errors.estado}
            >
              <Select.Option value="AC">AC</Select.Option>
              <Select.Option value="AL">AL</Select.Option>
              <Select.Option value="AP">AP</Select.Option>
              <Select.Option value="AM">AM</Select.Option>
              <Select.Option value="BA">BA</Select.Option>
              <Select.Option value="CE">CE</Select.Option>
              <Select.Option value="DF">DF</Select.Option>
              <Select.Option value="ES">ES</Select.Option>
              <Select.Option value="GO">GO</Select.Option>
              <Select.Option value="MA">MA</Select.Option>
              <Select.Option value="MT">MT</Select.Option>
              <Select.Option value="MS">MS</Select.Option>
              <Select.Option value="MG">MG</Select.Option>
              <Select.Option value="PA">PA</Select.Option>
              <Select.Option value="PB">PB</Select.Option>
              <Select.Option value="PR">PR</Select.Option>
              <Select.Option value="PE">PE</Select.Option>
              <Select.Option value="PI">PI</Select.Option>
              <Select.Option value="RJ">RJ</Select.Option>
              <Select.Option value="RN">RN</Select.Option>
              <Select.Option value="RS">RS</Select.Option>
              <Select.Option value="RO">RO</Select.Option>
              <Select.Option value="RR">RR</Select.Option>
              <Select.Option value="SC">SC</Select.Option>
              <Select.Option value="SP">SP</Select.Option>
              <Select.Option value="SE">SE</Select.Option>
              <Select.Option value="TO">TO</Select.Option>
            </S.Select>
          </S.Label>

          {errors.estado && <S.ErrorMessage>{errors.estado}</S.ErrorMessage>}
        </Col>

        <Col span={10}>
          <S.Label>
            Cidade:
            <S.Input
              size="small"
              name="cidade"
              value={values.cidade}
              onChange={handleChange}
              placeholder="Cidade"
              error={errors.cidade}
            />
          </S.Label>
          {errors.cidade && <S.ErrorMessage>{errors.cidade}</S.ErrorMessage>}
        </Col>

        <Col span={8}>
          <S.Label>
            Bairro:
            <S.Input
              size="small"
              name="bairro"
              value={values.bairro}
              onChange={handleChange}
              placeholder="Bairro"
              error={errors.bairro}
            />
          </S.Label>
          {errors.bairro && <S.ErrorMessage>{errors.bairro}</S.ErrorMessage>}
        </Col>

        <Col span={12}>
          <S.Label>
            CEP:
            <InputMask
              mask="99999-999"
              placeholder="_____-___"
              name="cep"
              size="small"
              value={values.cep}
              onChange={handleChange}
            >
              {inputProps => <S.Input error={errors.cep} {...inputProps} />}
            </InputMask>
          </S.Label>
          {errors.cep && <S.ErrorMessage>{errors.cep}</S.ErrorMessage>}
        </Col>

        <Col span={12}>
          <S.Label>
            Complemento:
            <S.Input
              size="small"
              name="complemento"
              value={values.complemento}
              onChange={handleChange}
              placeholder="Complemento"
            />
          </S.Label>
        </Col>

        <Col span={24}>
          <S.Footer>
            {onBack ? (
              <Button onClick={onBack} size="small" type="text" htmlType="button">
                Voltar
              </Button>
            ) : (
              <div></div>
            )}
            <Button type="primary" size="small" htmlType="submit">
              Continuar
            </Button>
          </S.Footer>
        </Col>
      </Row>
    </form>
  )
}

AndressForm.propTypes = {}

AndressForm.defaultProps = {}

export default AndressForm
