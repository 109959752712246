import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFetch } from 'use-http'
import { createAdminGetUrl } from 'utils'

const AdminPanelContext = React.createContext()
const AdminPanelDispatch = React.createContext()

const ACTIONS = {
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER',
  SET_USER_PERMISSIONS: 'SET_USER_PERMISSIONS',
  SAVE_LAYOUT: 'SAVE_LAYOUT',
}

const DEFAULT_GRID_LAYOUTS = {
  controlCenter: {
    components: [
      'QuantidadeVendasNoPeriodo',
      'MatriculasAtivasNoPeriodo',
      'CancelamentosNoPeriodo',
      'Alunos',
      'EfetividadeNoPagamento',
      'Detalhamento',
      'Cobrancas',
      'Renovacoes',
      'MetaDeVendas',
      'MetaDeChurn',
      'MetaDeRenovacoes',
      'MetaDeAlunosAtivos',
    ],
    layouts: {
      lg: [
        { w: 4, minW: 3, h: 8, minH: 8, x: 0, y: 0, i: 'QuantidadeVendasNoPeriodo' },
        { w: 4, minW: 3, h: 8, minH: 8, x: 4, y: 0, i: 'MatriculasAtivasNoPeriodo' },
        { w: 4, minW: 3, h: 8, minH: 8, x: 8, y: 0, i: 'CancelamentosNoPeriodo' },
        { w: 4, minW: 3, h: 6, minH: 6, x: 0, y: 6, i: 'Alunos' },
        { w: 4, minW: 4, maxW: 4, h: 6, minH: 6, maxH: 6, x: 4, y: 6, i: 'EfetividadeNoPagamento' },
        { w: 4, minW: 3, h: 12, minH: 12, x: 8, y: 6, i: 'Detalhamento' },
        { w: 3, minW: 3, h: 6, minH: 5, x: 0, y: 12, i: 'Cobrancas' },
        { w: 5, minW: 4, h: 6, minH: 6, x: 3, y: 12, i: 'Renovacoes' },
        { w: 12, minW: 6, h: 4, minH: 4, x: 0, y: 18, i: 'MetaDeVendas' },
        { w: 12, minW: 6, h: 4, minH: 4, x: 0, y: 22, i: 'MetaDeChurn' },
        { w: 12, minW: 6, h: 4, minH: 4, x: 0, y: 26, i: 'MetaDeRenovacoes' },
        { w: 12, minW: 6, h: 4, minH: 4, x: 0, y: 30, i: 'MetaDeAlunosAtivos' },
      ],
      md: [
        { w: 6, minW: 5, h: 8, minH: 8, x: 0, y: 0, i: 'QuantidadeVendasNoPeriodo' },
        { w: 6, minW: 5, h: 8, minH: 8, x: 6, y: 0, i: 'MatriculasAtivasNoPeriodo' },
        { w: 6, minW: 5, h: 8, minH: 8, x: 0, y: 6, i: 'CancelamentosNoPeriodo' },
        { w: 6, minW: 5, h: 6, minH: 6, x: 6, y: 6, i: 'Alunos' },
        { w: 6, minW: 6, maxW: 6, h: 6, minH: 6, maxH: 6, x: 6, y: 6, i: 'EfetividadeNoPagamento' },
        { w: 6, minW: 4, h: 12, minH: 12, x: 6, y: 12, i: 'Detalhamento' },
        { w: 6, minW: 3, h: 6, minH: 5, x: 0, y: 18, i: 'Cobrancas' },
        { w: 12, minW: 6, h: 6, minH: 6, x: 0, y: 24, i: 'Renovacoes' },
        { w: 12, minW: 6, h: 4, minH: 4, x: 0, y: 30, i: 'MetaDeVendas' },
        { w: 12, minW: 6, h: 4, minH: 4, x: 0, y: 34, i: 'MetaDeChurn' },
        { w: 12, minW: 6, h: 4, minH: 4, x: 0, y: 38, i: 'MetaDeRenovacoes' },
        { w: 12, minW: 6, h: 4, minH: 4, x: 0, y: 42, i: 'MetaDeAlunosAtivos' },
      ],
      sm: [
        { w: 12, h: 7, x: 0, y: 0, i: 'QuantidadeVendasNoPeriodo', moved: false, static: true },
        { w: 12, h: 7, x: 0, y: 7, i: 'MatriculasAtivasNoPeriodo', moved: false, static: true },
        { w: 12, h: 7, x: 0, y: 14, i: 'CancelamentosNoPeriodo', moved: false, static: true },
        { w: 12, h: 6, x: 0, y: 21, i: 'Alunos', moved: false, static: true },
        { w: 12, h: 6, x: 0, y: 27, i: 'EfetividadeNoPagamento', moved: false, static: true },
        { w: 12, h: 12, x: 0, y: 33, i: 'Detalhamento', minH: 12, moved: false, static: true },
        { w: 12, h: 6, x: 0, y: 45, i: 'Cobrancas', moved: false, static: true },
        { w: 12, h: 8, x: 0, y: 51, i: 'Renovacoes', moved: false, static: true },
        { w: 12, h: 4, x: 0, y: 59, i: 'MetaDeVendas', moved: false, static: true },
        { w: 12, h: 4, x: 0, y: 63, i: 'MetaDeChurn', moved: false, static: true },
        { w: 12, h: 4, x: 0, y: 67, i: 'MetaDeRenovacoes', moved: false, static: true },
        { w: 12, h: 4, x: 0, y: 71, i: 'MetaDeAlunosAtivos', moved: false, static: true },
      ],
      xs: [
        { w: 1, h: 7, x: 0, y: 0, i: 'QuantidadeVendasNoPeriodo', moved: false, static: true },
        { w: 1, h: 7, x: 0, y: 7, i: 'MatriculasAtivasNoPeriodo', moved: false, static: true },
        { w: 1, h: 7, x: 0, y: 14, i: 'CancelamentosNoPeriodo', moved: false, static: true },
        { w: 1, h: 6, x: 0, y: 21, i: 'Alunos', moved: false, static: true },
        { w: 1, h: 6, x: 0, y: 27, i: 'EfetividadeNoPagamento', moved: false, static: true },
        { w: 1, h: 12, x: 0, y: 33, i: 'Detalhamento', minH: 12, moved: false, static: true },
        { w: 1, h: 6, x: 0, y: 45, i: 'Cobrancas', moved: false, static: true },
        { w: 1, h: 8, x: 0, y: 51, i: 'Renovacoes', moved: false, static: true },
        { w: 1, h: 4, x: 0, y: 59, i: 'MetaDeVendas', moved: false, static: true },
        { w: 1, h: 4, x: 0, y: 63, i: 'MetaDeChurn', moved: false, static: true },
        { w: 1, h: 4, x: 0, y: 67, i: 'MetaDeRenovacoes', moved: false, static: true },
        { w: 1, h: 4, x: 0, y: 71, i: 'MetaDeAlunosAtivos', moved: false, static: true },
      ],
      xxs: [
        { w: 1, h: 7, x: 0, y: 0, i: 'QuantidadeVendasNoPeriodo', moved: false, static: true },
        { w: 1, h: 7, x: 0, y: 7, i: 'MatriculasAtivasNoPeriodo', moved: false, static: true },
        { w: 1, h: 7, x: 0, y: 14, i: 'CancelamentosNoPeriodo', moved: false, static: true },
        { w: 1, h: 6, x: 0, y: 21, i: 'Alunos', moved: false, static: true },
        { w: 1, h: 6, x: 0, y: 27, i: 'EfetividadeNoPagamento', moved: false, static: true },
        { w: 1, h: 12, x: 0, y: 33, i: 'Detalhamento', minH: 12, moved: false, static: true },
        { w: 1, h: 6, x: 0, y: 45, i: 'Cobrancas', moved: false, static: true },
        { w: 1, h: 8, x: 0, y: 51, i: 'Renovacoes', moved: false, static: true },
        { w: 1, h: 4, x: 0, y: 59, i: 'MetaDeVendas', moved: false, static: true },
        { w: 1, h: 4, x: 0, y: 63, i: 'MetaDeChurn', moved: false, static: true },
        { w: 1, h: 4, x: 0, y: 67, i: 'MetaDeRenovacoes', moved: false, static: true },
        { w: 1, h: 4, x: 0, y: 71, i: 'MetaDeAlunosAtivos', moved: false, static: true },
      ],
    },
  },
  marketingAndSells: {
    components: [
      'CacCard',
      'RoiCard',
      'MatriculasAtivasNoPeriodo',
      'VendasLTVCard',
      'LtvPlanoCard',
      'LtvFormaPagamentoCard',
      'AlunosAtivosPorPeriodicidade',
      'AlunosAtivosPorPlano',
      'VendasNoPeriodo',
      'MatriculasPorFormaDePagamento',
      'MatriculasPorPlano',
      'MatriculasPorAfiliado',
      'LeadsNoPeriodo',
      'LeadsPorAfiliados',
      'TempoComoLead',
      'AlunosAtivosPorAfiliado',
    ],
    layouts: {
      lg: [
        { w: 4, h: 8, x: 0, y: 0, i: 'CacCard', minW: 3, minH: 8, moved: false, static: false },
        { w: 4, h: 8, x: 8, y: 0, i: 'RoiCard', minW: 3, minH: 8, moved: false, static: false },
        {
          w: 4,
          h: 8,
          x: 4,
          y: 0,
          i: 'MatriculasAtivasNoPeriodo',
          minW: 3,
          minH: 8,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 6,
          x: 8,
          y: 13,
          i: 'VendasLTVCard',
          minW: 3,
          minH: 5,
          moved: false,
          static: false,
        },
        {
          w: 6,
          h: 16,
          x: 0,
          y: 25,
          i: 'LtvPlanoCard',
          minW: 6,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 5,
          x: 8,
          y: 8,
          i: 'LtvFormaPagamentoCard',
          minW: 4,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 8,
          x: 0,
          y: 17,
          i: 'AlunosAtivosPorPeriodicidade',
          minW: 4,
          moved: false,
          static: false,
        },
        {
          w: 6,
          h: 16,
          x: 6,
          y: 25,
          i: 'AlunosAtivosPorPlano',
          minW: 6,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 9,
          x: 0,
          y: 8,
          i: 'VendasNoPeriodo',
          minW: 3,
          minH: 8,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 6,
          x: 8,
          y: 19,
          i: 'MatriculasPorFormaDePagamento',
          minW: 4,
          minH: 4,
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 16,
          x: 0,
          y: 41,
          i: 'MatriculasPorPlano',
          minW: 12,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 16,
          x: 0,
          y: 57,
          i: 'MatriculasPorAfiliado',
          minW: 12,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 9,
          x: 4,
          y: 8,
          i: 'LeadsNoPeriodo',
          minW: 3,
          minH: 8,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 8,
          x: 4,
          y: 17,
          i: 'LeadsPorAfiliados',
          minW: 3,
          minH: 8,
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 16,
          x: 0,
          y: 73,
          i: 'TempoComoLead',
          minW: 6,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 22,
          x: 0,
          y: 89,
          i: 'AlunosAtivosPorAfiliado',
          minW: 6,
          minH: 16,
          moved: false,
          static: false,
        },
      ],
      md: [
        { w: 4, h: 8, x: 0, y: 0, i: 'CacCard', minW: 3, minH: 8, moved: false, static: false },
        { w: 4, h: 8, x: 8, y: 0, i: 'RoiCard', minW: 3, minH: 8, moved: false, static: false },
        {
          w: 4,
          h: 8,
          x: 4,
          y: 0,
          i: 'MatriculasAtivasNoPeriodo',
          minW: 3,
          minH: 8,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 6,
          x: 8,
          y: 13,
          i: 'VendasLTVCard',
          minW: 3,
          minH: 5,
          moved: false,
          static: false,
        },
        {
          w: 6,
          h: 16,
          x: 0,
          y: 25,
          i: 'LtvPlanoCard',
          minW: 6,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 5,
          x: 8,
          y: 8,
          i: 'LtvFormaPagamentoCard',
          minW: 4,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 8,
          x: 0,
          y: 17,
          i: 'AlunosAtivosPorPeriodicidade',
          minW: 4,
          moved: false,
          static: false,
        },
        {
          w: 6,
          h: 16,
          x: 6,
          y: 25,
          i: 'AlunosAtivosPorPlano',
          minW: 6,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 9,
          x: 0,
          y: 8,
          i: 'VendasNoPeriodo',
          minW: 3,
          minH: 8,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 6,
          x: 8,
          y: 19,
          i: 'MatriculasPorFormaDePagamento',
          minW: 4,
          minH: 4,
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 16,
          x: 0,
          y: 41,
          i: 'MatriculasPorPlano',
          minW: 12,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 12,
          h: 16,
          x: 0,
          y: 57,
          i: 'MatriculasPorAfiliado',
          minW: 12,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 6,
          h: 16,
          x: 0,
          y: 57,
          i: 'TempoComoLead',
          minW: 6,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 6,
          h: 22,
          x: 6,
          y: 57,
          i: 'AlunosAtivosPorAfiliado',
          minW: 6,
          minH: 16,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 9,
          x: 4,
          y: 8,
          i: 'LeadsNoPeriodo',
          minW: 3,
          minH: 8,
          moved: false,
          static: false,
        },
        {
          w: 4,
          h: 8,
          x: 4,
          y: 17,
          i: 'LeadsPorAfiliados',
          minW: 3,
          minH: 8,
          moved: false,
          static: false,
        },
      ],
      sm: [
        { w: 12, h: 8, x: 0, y: 0, i: 'CacCard', minW: 3, minH: 8, moved: false, static: true },
        { w: 12, h: 8, x: 0, y: 8, i: 'RoiCard', minW: 3, minH: 8, moved: false, static: true },
        {
          w: 12,
          h: 8,
          x: 0,
          y: 16,
          i: 'MatriculasAtivasNoPeriodo',
          minH: 8,
          moved: false,
          static: true,
        },
        { w: 12, h: 6, x: 0, y: 47, i: 'VendasLTVCard', minH: 5, moved: false, static: true },
        { w: 12, h: 16, x: 0, y: 75, i: 'LtvPlanoCard', minH: 16, moved: false, static: true },
        { w: 12, h: 5, x: 0, y: 24, i: 'LtvFormaPagamentoCard', moved: false, static: true },
        { w: 12, h: 8, x: 0, y: 53, i: 'AlunosAtivosPorPeriodicidade', moved: false, static: true },
        {
          w: 12,
          h: 20,
          x: 0,
          y: 91,
          i: 'AlunosAtivosPorPlano',
          minH: 18,
          moved: false,
          static: true,
        },
        { w: 12, h: 9, x: 0, y: 29, i: 'VendasNoPeriodo', minH: 8, moved: false, static: true },
        {
          w: 12,
          h: 6,
          x: 0,
          y: 69,
          i: 'MatriculasPorFormaDePagamento',
          minH: 4,
          moved: false,
          static: true,
        },
        {
          w: 12,
          h: 26,
          x: 0,
          y: 111,
          i: 'MatriculasPorPlano',
          minH: 18,
          moved: false,
          static: true,
        },
        {
          w: 12,
          h: 20,
          x: 0,
          y: 137,
          i: 'MatriculasPorAfiliado',
          minH: 18,
          moved: false,
          static: true,
        },
        { w: 12, h: 9, x: 0, y: 38, i: 'LeadsNoPeriodo', minH: 8, moved: false, static: true },
        { w: 12, h: 8, x: 0, y: 61, i: 'LeadsPorAfiliados', minH: 8, moved: false, static: true },
        { w: 12, h: 16, x: 0, y: 157, i: 'TempoComoLead', minH: 16, moved: false, static: true },
        {
          w: 12,
          h: 16,
          x: 0,
          y: 173,
          i: 'AlunosAtivosPorAfiliado',
          minH: 16,
          moved: false,
          static: true,
        },
      ],
      xs: [
        { w: 1, h: 8, x: 0, y: 0, i: 'CacCard', minW: 3, minH: 8, moved: false, static: true },
        { w: 1, h: 8, x: 0, y: 8, i: 'RoiCard', minW: 3, minH: 8, moved: false, static: true },
        {
          w: 1,
          h: 8,
          x: 0,
          y: 16,
          i: 'MatriculasAtivasNoPeriodo',
          minH: 8,
          moved: false,
          static: true,
        },
        { w: 1, h: 6, x: 0, y: 47, i: 'VendasLTVCard', minH: 5, moved: false, static: true },
        { w: 1, h: 16, x: 0, y: 75, i: 'LtvPlanoCard', minH: 16, moved: false, static: true },
        { w: 1, h: 5, x: 0, y: 24, i: 'LtvFormaPagamentoCard', moved: false, static: true },
        { w: 1, h: 8, x: 0, y: 53, i: 'AlunosAtivosPorPeriodicidade', moved: false, static: true },
        {
          w: 1,
          h: 20,
          x: 0,
          y: 91,
          i: 'AlunosAtivosPorPlano',
          minH: 18,
          moved: false,
          static: true,
        },
        { w: 1, h: 9, x: 0, y: 29, i: 'VendasNoPeriodo', minH: 8, moved: false, static: true },
        {
          w: 1,
          h: 6,
          x: 0,
          y: 69,
          i: 'MatriculasPorFormaDePagamento',
          minH: 4,
          moved: false,
          static: true,
        },
        {
          w: 1,
          h: 26,
          x: 0,
          y: 111,
          i: 'MatriculasPorPlano',
          minH: 18,
          moved: false,
          static: true,
        },
        {
          w: 1,
          h: 20,
          x: 0,
          y: 137,
          i: 'MatriculasPorAfiliado',
          minH: 18,
          moved: false,
          static: true,
        },
        { w: 1, h: 9, x: 0, y: 38, i: 'LeadsNoPeriodo', minH: 8, moved: false, static: true },
        { w: 1, h: 8, x: 0, y: 61, i: 'LeadsPorAfiliados', minH: 8, moved: false, static: true },
        { w: 1, h: 12, x: 0, y: 157, i: 'TempoComoLead', minH: 16, moved: false, static: true },
        {
          w: 1,
          h: 20,
          x: 0,
          y: 169,
          i: 'AlunosAtivosPorAfiliado',
          minH: 16,
          moved: false,
          static: true,
        },
      ],
      xxs: [
        { w: 1, h: 8, x: 0, y: 0, i: 'CacCard', minW: 3, minH: 8, moved: false, static: true },
        { w: 1, h: 8, x: 0, y: 8, i: 'RoiCard', minW: 3, minH: 8, moved: false, static: true },
        {
          w: 1,
          h: 8,
          x: 0,
          y: 16,
          i: 'MatriculasAtivasNoPeriodo',
          minH: 8,
          moved: false,
          static: true,
        },
        { w: 1, h: 6, x: 0, y: 47, i: 'VendasLTVCard', minH: 5, moved: false, static: true },
        { w: 1, h: 16, x: 0, y: 75, i: 'LtvPlanoCard', minH: 16, moved: false, static: true },
        { w: 1, h: 5, x: 0, y: 24, i: 'LtvFormaPagamentoCard', moved: false, static: true },
        { w: 1, h: 8, x: 0, y: 53, i: 'AlunosAtivosPorPeriodicidade', moved: false, static: true },
        {
          w: 1,
          h: 20,
          x: 0,
          y: 91,
          i: 'AlunosAtivosPorPlano',
          minH: 18,
          moved: false,
          static: true,
        },
        { w: 1, h: 9, x: 0, y: 29, i: 'VendasNoPeriodo', minH: 8, moved: false, static: true },
        {
          w: 1,
          h: 6,
          x: 0,
          y: 69,
          i: 'MatriculasPorFormaDePagamento',
          minH: 4,
          moved: false,
          static: true,
        },
        {
          w: 1,
          h: 26,
          x: 0,
          y: 111,
          i: 'MatriculasPorPlano',
          minH: 18,
          moved: false,
          static: true,
        },
        {
          w: 1,
          h: 20,
          x: 0,
          y: 137,
          i: 'MatriculasPorAfiliado',
          minH: 18,
          moved: false,
          static: true,
        },
        { w: 1, h: 9, x: 0, y: 38, i: 'LeadsNoPeriodo', minH: 8, moved: false, static: true },
        { w: 1, h: 8, x: 0, y: 61, i: 'LeadsPorAfiliados', minH: 8, moved: false, static: true },
        { w: 1, h: 16, x: 0, y: 157, i: 'TempoComoLead', minH: 16, moved: false, static: true },
        {
          w: 1,
          h: 28,
          x: 0,
          y: 173,
          i: 'AlunosAtivosPorAfiliado',
          minH: 16,
          moved: false,
          static: true,
        },
      ],
    },
  },
}

const initialState = {
  user: undefined,
  permissions: [],
  gridLayouts: DEFAULT_GRID_LAYOUTS,
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return {
        ...state,
        user: action.payload,
      }

    case ACTIONS.REMOVE_USER:
      window.localStorage.removeItem('adp_admin:user_session')
      return {
        ...state,
        user: undefined,
      }

    case ACTIONS.SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }

    case ACTIONS.SAVE_LAYOUT:
      return {
        ...state,
        gridLayouts: JSON.parse(action.payload),
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const AdminPanelContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const userSession = JSON.parse(window.localStorage.getItem('adp_admin:user_session'))

  const { get, response } = useFetch(createAdminGetUrl('DashCarregaLayout'), null)

  const getGrid = async () => {
    const gridSettings = await get()

    if (response.ok) {
      if (gridSettings) {
        dispatch({ type: ACTIONS.SAVE_LAYOUT, payload: gridSettings })
      }
    }
  }

  useEffect(() => {
    if (userSession) {
      getGrid()
    }
  }, [])

  return (
    <AdminPanelContext.Provider value={state}>
      <AdminPanelDispatch.Provider value={dispatch}>{children}</AdminPanelDispatch.Provider>
    </AdminPanelContext.Provider>
  )
}

AdminPanelContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useAdminPanelContext = () => {
  const context = React.useContext(AdminPanelContext)

  if (context === undefined) {
    throw new Error('AdminPanelContext must be used within a AdminPanelContextProvider')
  }

  return context
}

const useAdminPanelDispatch = () => {
  const context = React.useContext(AdminPanelDispatch)

  if (context === undefined) {
    throw new Error('useAdminPanelDispatch must be used within a AdminPanelContextProvider')
  }

  return context
}

const useAdminPanel = () => [useAdminPanelContext(), useAdminPanelDispatch()]

export { ACTIONS, AdminPanelContextProvider, useAdminPanel, DEFAULT_GRID_LAYOUTS }
