import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Typography, Row, Col, Button, Card, Space } from 'antd'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import useFetch from 'use-http'
import queryString from 'query-string'
import Cookie from 'js-cookie'

import { Container, BaseTemplate } from 'components'

import { createGetUrl, getLegacyWebsiteUrl } from 'utils'

const StartExamPage = () => {
  const { search } = useLocation()
  const history = useHistory()
  const params = useParams()

  const { loading, data = {} } = useFetch(createGetUrl('InfoPreProva', params.examKey), null, [])

  const urlParams = queryString.parse(search)

  useEffect(() => {
    if (urlParams.next) {
      Cookie.set('adp:nextClassRedirect', urlParams.next)
    } else {
      Cookie.remove('adp:nextClassRedirect')
    }
  }, [urlParams])

  return (
    <BaseTemplate cleanHeader>
      <Helmet>
        <title>Atividade - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <Container boxed>
        <Row style={{ textAlign: 'center' }}>
          <Col
            xs={{ span: 24, push: 0 }}
            sm={{ span: 24, push: 0 }}
            md={{ span: 14, push: 5 }}
            lg={{ span: 10, push: 7 }}
            xl={{ span: 10, push: 7 }}
          >
            <Card loading={loading} style={{ margin: '64px 0' }}>
              <Space direction="vertical" size="large">
                <img
                  src={data.imagem_prova}
                  style={{ width: 208, borderRadius: 8 }}
                  alt={data.nome_prova}
                />
                <Typography.Title level={2}>{data.nome_prova}</Typography.Title>
                <Typography.Text>{data.frase_iniciar}</Typography.Text>

                <Button
                  type="primary"
                  size="large"
                  onClick={() => history.push(`/atividade/${params.examKey}/responder`)}
                  block
                >
                  {data.frase_botao}
                </Button>

                <Button type="link" block>
                  <a href={`${getLegacyWebsiteUrl()}/curso/${data.slug_curso}`}>
                    Voltar para o curso
                  </a>
                </Button>
              </Space>
            </Card>
          </Col>
        </Row>
      </Container>
    </BaseTemplate>
  )
}

export default StartExamPage
