import { Input } from 'antd'
import React, { forwardRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import * as S from './styles'

export const PostSubmitForm = forwardRef(({ placeholder, isSubmittig, onSubmit }, ref) => {
  const [content, setContent] = useState('')

  return (
    <S.Form>
      <div style={{ width: '100%', position: 'relative' }}>
        <Input.TextArea
          value={content}
          style={{ minHeight: 80 }}
          onChange={e => {
            setContent(e.target.value)
          }}
          placeholder={placeholder}
          autoSize
          maxLength={2000}
          ref={ref}
        />
        <div style={{ position: 'absolute', right: 0, marginBottom: 8, textAlign: 'right' }}>
          <S.HelpText>{content.length}/2000</S.HelpText>
        </div>
        <div style={{ paddingRight: 50 }}>
          <S.HelpText>
            Ao enviar sua pergunta ou resposta você está aceitando os{' '}
            <a href={process.env.REACT_APP_TERMS_PAGE_URL} target="_blank">
              termos de uso
            </a>{' '}
            da perguntas e respostas{' '}
          </S.HelpText>
        </div>

        <S.Button
          disabled={isSubmittig || content.replaceAll(' ', '').length === 0}
          onClick={() => onSubmit(content, () => setContent(''))}
          type="primary"
          size="small"
          icon={<FontAwesomeIcon icon={faPaperPlane} />}
        ></S.Button>
      </div>
    </S.Form>
  )
})
