import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons'

import * as S from './styles'

const EmptyChatState = ({ title, description }) => {
  return (
    <S.EmptyMessageList>
      <FontAwesomeIcon
        icon={faCommentAlt}
        size="4x"
        style={{ margin: '0 auto', marginBottom: 16, opacity: 0.5 }}
      />
      <Typography.Text>{title}</Typography.Text>
      <Typography.Text type="secondary">
        <small>{description}</small>
      </Typography.Text>
    </S.EmptyMessageList>
  )
}

EmptyChatState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default EmptyChatState
