import React from 'react'
import { Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'

import { colors } from 'styles/variables'

import styles from './styles.module.scss'

const { Column } = Table

const generateDays = () => {
  return Array.apply(null, Array(30)).reduce((acc, _, index) => {
    return { ...acc, [`dia${index + 1}`]: Number((Math.random() * 100).toFixed(2)) }
  }, {})
}

const DATA = [
  {
    cohort: 'Fev 2021',
    leads: 1500,
    ...generateDays(),
    dia1: -1,
  },
  {
    cohort: 'Fev 2021',
    leads: 1500,
    ...generateDays(),
  },
  {
    cohort: 'Fev 2021',
    leads: 1500,
    ...generateDays(),
  },
  {
    cohort: 'Fev 2021',
    leads: 1500,
    ...generateDays(),
  },
  {
    cohort: 'Fev 2021',
    leads: 1500,
    ...generateDays(),
  },
  {
    cohort: 'Fev 2021',
    leads: 1500,
    ...generateDays(),
  },
  {
    cohort: 'Fev 2021',
    leads: 1500,
    ...generateDays(),
  },
]

const getCohortCellClassName = percent => {
  const stages = [0, 20, 30, 40, 50, 60, 70, 80, 90]

  let color

  stages.forEach(stage => {
    if (percent >= stage) return (color = `moreThan${stage}`)
  })

  return color
}

const MarketingCohort = () => {
  return (
    <GenericCard
      error={false}
      loading={false}
      title="Análise cohort"
      rightComponent={<FontAwesomeIcon icon={faHistory} color={colors.primaryColor} />}
    >
      <Table
        size="small"
        pagination={false}
        dataSource={DATA}
        pagination={false}
        className={styles.cohortTable}
      >
        <Column title="Cohort" width={200} fixed={true} dataIndex="cohort" key="cohort" />

        <Column
          title="Leads"
          width={150}
          dataIndex="leads"
          key="leads"
          render={value => (
            <MetricNumber value={Number(value)}>
              {({ formatedNumber }) => formatedNumber}
            </MetricNumber>
          )}
        />

        {Array.apply(null, Array(30)).map((_, index) => (
          <Column
            title={`Dia ${index + 1}`}
            dataIndex={`dia${index + 1}`}
            key={`dia${index + 1}`}
            width={100}
            align="center"
            className={styles.cohortTableCell}
            render={value =>
              value < 0 ? (
                ''
              ) : (
                <div
                  className={`${styles[getCohortCellClassName(value)]} ${styles.coloredTableCell}`}
                >
                  {value} %
                </div>
              )
            }
          />
        ))}
      </Table>
    </GenericCard>
  )
}

export { MarketingCohort }
