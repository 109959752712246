import React from 'react'
import PropTypes from 'prop-types'
import { Spin, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import styled from 'styled-components'

const Card = styled.div`
  border-radius: 6px;
  background: #2b2b2b;
  padding: 1rem;
  height: 100%;

  &:active {
    cursor: grabbing;
  }
`

const CardHeader = styled.header`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  font-size: 16px;
  color: #fff;
  height: 16px;
  line-height: 16px;
  flex: 2;

  @media (max-width: 768px) {
    height: auto;
    flex: ${({ isIcon }) => (isIcon ? 5 : 3)};
  }
`

const RightComponent = styled.div`
  margin-left: 16px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const SubHeaderComponent = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: flex-end;
`

const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const GenericCard = ({
  title,
  loading,
  error,
  rightComponent,
  subHeaderComponent,
  children,
  ...props
}) => {
  const shouldShowContent = () => !loading && !error

  return (
    <Card {...props} className="generic-card">
      <CardHeader isIcon={!!rightComponent.props.icon}>
        <Title isIcon={!!rightComponent.props.icon}>{title}</Title>
        {shouldShowContent() && rightComponent && <RightComponent>{rightComponent}</RightComponent>}
      </CardHeader>

      {shouldShowContent() && subHeaderComponent && (
        <SubHeaderComponent>{subHeaderComponent}</SubHeaderComponent>
      )}

      {loading && !error && (
        <Center>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Center>
      )}

      {shouldShowContent() && children}

      {error && !loading && (
        <Center>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div>Ocorreu um erro</div>
          </div>
        </Center>
      )}
    </Card>
  )
}

GenericCard.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  error: PropTypes.bool,
}

GenericCard.defaultProps = {
  children: '',
  loading: false,
  error: false,
}

export default GenericCard
