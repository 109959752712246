import React from 'react'
import PropTypes from 'prop-types'
import { PieChart as RechartsPieChart, Pie, Tooltip, ResponsiveContainer, Legend } from 'recharts'
import styled from 'styled-components'

import { typography } from 'styles'

const ChartColors = [
  '#019bcd',
  '#00c9b8',
  '#fdc200',
  '#ffb3c1',
  '#fe5e54',
  '#ffbf46',
  '#cfeefb',
  '#7DEDFF',
  '#297F87',
  '#FFF7AE',
  '#F6D167',
  '#DF2E2E',
  '#F6A9A9',
  '#96BAFF',
  '#FFBF86',
  '#7C83FD',
  '#FFBF86',
  '#C2F784',
  '#88FFF7',
  '#C5D7BD',
  '#7a8fe4',
  '#FB743E',
  '#9FB8AD',
  '#019bcd',
  '#00c9b8',
  '#fdc200',
  '#ffb3c1',
  '#fe5e54',
  '#ffbf46',
  '#cfeefb',
  '#7DEDFF',
  '#297F87',
  '#FFF7AE',
  '#F6D167',
  '#DF2E2E',
  '#F6A9A9',
  '#96BAFF',
  '#FFBF86',
  '#7C83FD',
  '#FFBF86',
  '#C2F784',
  '#88FFF7',
  '#C5D7BD',
  '#7a8fe4',
  '#FB743E',
  '#9FB8AD',
]

const LegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const LegendDot = styled.div`
  height: 8px;
  width: 8px;
  display: block;
  margin-right: 8px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
`

const StyledLegend = styled.div`
  display: flex;
  width: ${({ legendColWidth }) => legendColWidth}px;
  align-items: center;
  font-family: ${typography.fontFamily};
`

const LegendText = styled.div`
  color: ${({ color }) => color};
`

const StyledTooltip = styled.div`
  background-color: #141414;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 2px 2px 8px 8px rgba(0, 0, 0, 0.1);
  color: ${({ color }) => color};
  font-family: ${typography.fontFamily};
`

const renderLegend = (props, legendColWidth) => {
  const { payload } = props
  return (
    <LegendContainer>
      {payload.map((entry, index) => (
        <StyledLegend legendColWidth={legendColWidth} key={`tempo-lead-legend-${index}`}>
          <LegendDot color={entry.color} />
          <LegendText color={entry.color}>
            <strong>{entry.payload.y}%</strong> {entry.value}
          </LegendText>
        </StyledLegend>
      ))}
    </LegendContainer>
  )
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        {payload[0].name}: <strong>{payload[0].value}%</strong>
      </StyledTooltip>
    )
  }

  return null
}

const manipulateData = data => {
  if (!data.length) return []

  return data.map((item, index) => ({
    ...item,
    fill: ChartColors[index] || 'gray',
    stroke: '#2b2b2b',
    strokeWidth: 1,
    y: Number(item.y.toFixed(2)),
  }))
}

const PieChart = ({ data, height, legendHeight, legendColWidth }) => {
  return (
    <ResponsiveContainer height={height}>
      <RechartsPieChart>
        <Pie nameKey="x" dataKey="y" data={manipulateData(data)} />
        <Legend
          verticalAlign="bottom"
          height={legendHeight}
          content={props => renderLegend(props, legendColWidth)}
        />
        <Tooltip content={<CustomTooltip />} />
      </RechartsPieChart>
    </ResponsiveContainer>
  )
}

PieChart.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  legendHeight: PropTypes.number,
  legendColWidth: PropTypes.number,
  height: PropTypes.number,
}

PieChart.defaultProps = {
  legendHeight: 60,
  legendColWidth: 168,
  height: 500,
}

export default PieChart
