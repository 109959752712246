import styled from 'styled-components'

import { colors } from 'styles'

export const Wrapper = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${colors.defaultBackground};
  position: relative;
`

export const Fill = styled.div`
  height: 4px;
  background-color: ${colors.primaryColor};
  position: absolute;
  left: 0;
  top: 0;
  transition: width ease-in-out 500ms;
`
