import React, { useState } from 'react'
import { Space } from 'antd'
import { useFetch } from 'use-http'

import GenericCard from '../../generic-card'
import TrendInfo from '../../trend-info'
import LineChart from '../../line-chart'
import MetricNumber from '../../metric-number'
import DateRangePicker from '../../range-picker'

import { createAdminGetUrl } from 'utils'

const VendasNoPeriodo = () => {
  const [dateRange, setDateRange] = useState({
    startDate: '01%2F01%2F1900',
    endDate: '01%2F01%2F1900',
  })

  const { data, loading, error } = useFetch(
    createAdminGetUrl('DashMKTVendasMatriculas', `${dateRange.startDate}/${dateRange.endDate}`),
    null,
    [dateRange]
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="Quantidade matrículas no período"
      subHeaderComponent={
        <Space size="large">
          <MetricNumber isPrimaryNumber value={data?.atual} label="Atual:">
            {({ formatedNumber }) => (
              <Space>
                {formatedNumber} <TrendInfo info={data?.evolucao} />
              </Space>
            )}
          </MetricNumber>
          <MetricNumber value={data?.anterior} label="Anterior:">
            {({ formatedNumber }) => formatedNumber}
          </MetricNumber>
        </Space>
      }
      rightComponent={
        <DateRangePicker
          initialValues={{ startDate: data?.inicio, endDate: data?.fim }}
          onDateSelection={setDateRange}
        />
      }
    >
      {data?.vendas && <LineChart data={data?.vendas} />}
    </GenericCard>
  )
}

export { VendasNoPeriodo }
