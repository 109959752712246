import 'react-app-polyfill/ie9'
import 'core-js'
import 'raf/polyfill'
import 'moment/locale/pt-br'

import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import { ConfigProvider } from 'antd'
import ptBR from 'antd/lib/locale/pt_BR'
import moment from 'moment'

import initI18n from 'languages'
import AppContextProvider from 'context/app-context'
import { ChatContextProvider } from 'context/chat-context'
import AppRouter from './router'
import * as serviceWorker from './serviceWorker'
import { I18nProvider } from 'context/i18n'
import { applyColorsVars } from 'styles'

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TG_ID,
  })
}

moment.locale('pt-br')
applyColorsVars()

const App = ({ t }) => {
  return (
    <I18nProvider tFunc={t}>
      <AppContextProvider>
        <ChatContextProvider>
          <Helmet>
            <title>{process.env.REACT_APP_APP_NAME}</title>

            {process.env.NODE_ENV === 'production' && (
              <script>
                {`(function (w,i,d,g,e,t,s) {w[d] = w[d]||[];t= i.createElement(g);
            t.async=1;t.src=e;s=i.getElementsByTagName(g)[0];s.parentNode.insertBefore(t, s);
          })(window, document, '_gscq','script','//widgets.getsitecontrol.com/${process.env.REACT_APP_GET_SITE_CONTROL_ID}/script.js');`}
              </script>
            )}
          </Helmet>
          <ConfigProvider locale={ptBR}>
            <AppRouter />
          </ConfigProvider>
        </ChatContextProvider>
      </AppContextProvider>
    </I18nProvider>
  )
}

initI18n().then(t => {
  ReactDOM.render(<App t={t} />, document.getElementById('root'))
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
