import styled from 'styled-components'

import { colors, medias } from 'styles'

export const Title = styled.h2`
  font-size: 20px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 700;

  & > span {
    display: inline-block;
    margin-right: 8px;
    color: ${colors.textColorSecondary};
  }

  @media (max-width: ${medias.xs}) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const QuestionHtml = styled.div`
  b {
    font-weight: 700;
  }
`

export const Subtitle = styled.div`
  font-weight: 700;
  font-size: 18px;

  @media (max-width: ${medias.xs}) {
    font-size: 16px;
  }
`

export const LeftCol = styled.div`
  text-align: center;
  margin-bottom: 32px;
`

export const YourGrade = styled.div`
  position: absolute;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`

export const CircleContainer = styled.div`
  position: relative;
  width: 75%;
  margin: 0 auto;
  margin-bottom: -32px;
`
