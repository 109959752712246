import React, { useState, useContext } from 'react'
import { Spin, Tabs } from 'antd'

import { ChatContext } from 'context/chat-context'
import { MessageList, MessageInput, EditProfileImageModal } from 'components'
import { getLegacyWebsiteUrl } from 'utils'
import { POST_MESSAGES_ACTIONS } from 'enums'

import ChatHeader from '../chat-header'
import ChatList from '../chat-list'
import ChatContactList from '../chat-contacts-list'

import * as S from './styles'

const { TabPane } = Tabs

const ChatTemplate = ({
  handleSendMessage,
  handleLoadNextMessages,
  handleCheckinToChannel,
  handleStartChatWithContact,
  handleRemoveContact,
  handleAddContact,
  handleCheckoutFromChannel,
  conversations,
  conversationsLoading,
  contacts,
  contactsLoading,
  messageListLoading,
}) => {
  const [avatarModal, setAvatarModal] = useState(false)

  const {
    state: { socketIsConnected, activeChannelId, messageListHeight, messageListHeightStatus },
    chatMethods: {
      getChannel,
      userCanSendMessage,
      updateUserAvatar,
      getUnreadMessagesLength,
      getUnreadMessagesFromChannelId,
      updateMessageListHeightStatus,
      updateMessageListHeight,
    },
  } = useContext(ChatContext)

  const activeChannel = getChannel(activeChannelId)

  const handleEditAvatarClick = () => {
    setAvatarModal(true)
    window.parent.postMessage(
      { action: POST_MESSAGES_ACTIONS.EDIT_PHOTO_ON, isFromApp: true },
      getLegacyWebsiteUrl()
    )
  }

  const onFinishEditAvatar = () => {
    window.parent.postMessage(
      { action: POST_MESSAGES_ACTIONS.EDIT_PHOTO_OFF, isFromApp: true },
      getLegacyWebsiteUrl()
    )
  }

  if (socketIsConnected)
    return !activeChannelId ? (
      <>
        <ChatHeader title="" chatListIsOpen handleOpenChatList={handleCheckoutFromChannel} />
        <S.StyledChatTabs defaultActiveKey="1">
          <TabPane tab="Conversas" key="1">
            <ChatList
              conversationsLoading={conversationsLoading}
              conversations={conversations}
              onItemClick={handleCheckinToChannel}
              getUnreadMessagesFromChannelId={getUnreadMessagesFromChannelId}
            />
          </TabPane>
          <TabPane tab="Contatos" key="2">
            <ChatContactList
              contactsLoading={contactsLoading}
              contacts={contacts}
              handleStartChatWithContact={handleStartChatWithContact}
              handleRemoveContact={handleRemoveContact}
            />
          </TabPane>
        </S.StyledChatTabs>
      </>
    ) : (
      <>
        <ChatHeader
          title={activeChannel?.nome_canal}
          handleOpenChatList={handleCheckoutFromChannel}
          unreadMessagesCounter={getUnreadMessagesLength()}
        />

        <MessageList
          messages={activeChannel?.messages || []}
          onSelfAvatarClick={handleEditAvatarClick}
          activeChannel={activeChannel}
          messageListLoading={messageListLoading}
          onNextMessage={handleLoadNextMessages}
          handleStartChatWithContact={handleStartChatWithContact}
          handleAddContact={handleAddContact}
          handleRemoveContact={handleRemoveContact}
          messageListHeight={messageListHeight}
          messageListHeightStatus={messageListHeightStatus}
          updateMessageListHeightStatus={updateMessageListHeightStatus}
          updateMessageListHeight={updateMessageListHeight}
        />
        {userCanSendMessage(activeChannelId) ? (
          <MessageInput onSendMessage={handleSendMessage} activeChannelId={activeChannelId} />
        ) : (
          <S.BlockedInput>
            <a
              href={activeChannel && activeChannel.url_assine}
              target="_blank"
              rel="noopener noreferrer"
            >
              Assine agora para interagir no chat
            </a>
          </S.BlockedInput>
        )}
        <EditProfileImageModal
          visible={avatarModal}
          updateUserAvatar={updateUserAvatar}
          onFinishEditAvatar={onFinishEditAvatar}
          onClose={() => setAvatarModal(false)}
        />
      </>
    )

  return (
    <S.LoadingContainer direction="vertical" align="center">
      <Spin />
      <p>Estabelecendo conexão...</p>
    </S.LoadingContainer>
  )
}

ChatTemplate.defaultProps = {
  conversations: [],
  conversationsLoading: false,
  contacts: [],
  contactsLoading: false,
  messageListLoading: false,
}

export default ChatTemplate
