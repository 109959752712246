import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { FormItem, Input, Form } from 'formik-antd'
import { Button, Typography, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'
import { getLegacyWebsiteUrl } from '../../../utils'
import { useI18n } from 'context/i18n'

const PasswordForm = ({
  onSubmit,
  loading,
  handleEmbedLinkClick,
  embed,
  shouldShowSMSLogin,
  onSmsLoginClick,
}) => {
  const { t } = useI18n()
  const validationSchema = Yup.object({
    password: Yup.string().min(3, t('auth.passwordMinLength')).required(t('auth.passwordRequired')),
  })

  return (
    <>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={({ password }) => {
          onSubmit(password)
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form layout="vertical">
              <FormItem
                label={t('auth.password')}
                name="password"
                validateStatus={touched.password && errors.password && 'error'}
                help={touched.password && errors.password}
              >
                <Input.Password
                  placeholder={t('auth.password')}
                  name="password"
                  type="password"
                  iconRender={visible =>
                    visible ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )
                  }
                />
              </FormItem>

              <Button htmlType="submit" type="primary" disabled={loading} loading={loading} block>
                {t('auth.signIn')}
              </Button>

              {!shouldShowSMSLogin &&
                (embed ? (
                  <Space direction="vertical" size={24} align="center">
                    <Button
                      htmlType="button"
                      type="link"
                      onClick={() => handleEmbedLinkClick('/recuperar-senha')}
                    >
                      {t('auth.forgotPassword')}
                    </Button>
                  </Space>
                ) : (
                  <Space direction="vertical" size={24} align="center">
                    <Typography.Link
                      href={`${getLegacyWebsiteUrl()}/recuperar-senha`}
                      style={{ marginTop: 24, display: 'block' }}
                    >
                      {t('auth.forgotPassword')}
                    </Typography.Link>
                  </Space>
                ))}
            </Form>
          )
        }}
      </Formik>

      {shouldShowSMSLogin && (
        <Space style={{ marginTop: '2rem' }} direction="vertical" align="center">
          <Typography.Text>{t('auth.forgotPassword')}</Typography.Text>
          <Button type="ghost" onClick={onSmsLoginClick}>
            Receber via SMS
          </Button>
        </Space>
      )}
    </>
  )
}

PasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleEmbedLinkClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  embed: PropTypes.bool,
}

PasswordForm.defaultProps = {
  loading: false,
  embed: false,
}

export default PasswordForm
