import styled from 'styled-components'

import { colors } from 'styles/variables'

export const MessageInput = styled.div`
  display: flex;
  background-color: ${colors.defaultBackground};
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding: 4px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 9;
  position: absolute;
  width: 100%;
`

export const StyledInput = styled.input`
  display: block;
  border: 0;
  padding: 8px;
  padding-left: 16px;
  width: 100%;
  margin-right: 16px;
  transition: color 200ms ease;
  background-color: rgba(0, 0, 0, 0);
  max-height: 200px;
  min-height: 54px;
  overflow-y: auto;
  position: relative;
  color: #fff;

  &:focus {
    border: 0;
    outline: none;
  }
`

export const RightButtonsContainer = styled.div`
  display: flex;
`

export const EmojiPickerWrapper = styled.div`
  position: relative;

  button.emoji-mart-emoji,
  button.emoji-mart-emoji:focus,
  button.emoji-mart-emoji:hover,
  button.emoji-mart-emoji:active {
    outline: none !important;
  }
`

export const EmojiButton = styled.button`
  border: 0;
  height: 48px;
  width: 48px;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 200ms ease;

  &:focus {
    border: 0;
    outline: none;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.9);
  }

  .emoji-icon-button {
    fill: ${({ active }) => (active ? colors.primaryColor : '#fff')};
  }
`

export const SendButton = styled.button`
  border: 0;
  height: 48px;
  width: 48px;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 200ms ease;

  .send-icon-button {
    transition: fill 200ms ease, transform 200ms ease-in-out;
    fill: ${({ disabled }) => (disabled ? 'rgba(255, 255, 255, 0.3)' : colors.primaryColor)};
    transform: ${({ disabled }) => (disabled ? 'scale(0.9)' : 'scale(1.1)')};
  }

  &:active {
    transform: ${({ disabled }) => (disabled ? 'scale(1)' : 'scale(0.9)')};
  }
`
