import React, { useEffect, useState } from 'react'
import { Typography, Card, Spin, Row, Col, message, Space, Button } from 'antd'
import { useParams } from 'react-router-dom'
import useFetch from 'use-http'
import Cookie from 'js-cookie'

import { BaseTemplate, Container } from 'components'
import { createGetUrl } from 'utils'
import { IS_TRUE, IS_FALSE } from 'enums'

const ReferralPage = () => {
  const [referralData, setData] = useState({})
  const [isValid, setValid] = useState(true)
  const [isSelfLink, setIsSelfLink] = useState(false)
  const { tokenAffiliate } = useParams()

  const { get, response, loading, error } = useFetch(
    createGetUrl('AfiliadosGlobaisPreAssinaturaInfo', tokenAffiliate),
    null
  )

  const fetchReferralData = async () => {
    const data = await get()

    if (response.ok) {
      setData(data)

      if (data.valido === IS_FALSE) {
        setValid(false)
      }

      if (data.valido === IS_TRUE) {
        Cookie.set(`${process.env.REACT_APP_APP_ID}_afiliado_global`, tokenAffiliate)
      }

      if (data.valido === '2') {
        setIsSelfLink(true)
        return
      }

      setTimeout(() => {
        window.location.href = data.url_botao
      }, 3000)
    }

    if (error) {
      message.error('Ocorreu um erro ao carrega esta página.', 0)
    }
  }

  useEffect(() => {
    fetchReferralData()
    // eslint-disable-next-line
  }, [])

  return (
    <BaseTemplate cleanHeader>
      <Container>
        <Row style={{ textAlign: 'center', height: '70vh' }}>
          <Col
            xs={{ span: 24, push: 0 }}
            sm={{ span: 24, push: 0 }}
            md={{ span: 14, push: 5 }}
            lg={{ span: 10, push: 7 }}
            xl={{ span: 10, push: 7 }}
          >
            <br />
            <br />
            {isValid && !isSelfLink && (
              <Typography.Title level={2}>{referralData.frase}</Typography.Title>
            )}
            <br />

            <Card loading={loading}>
              <Typography.Title level={5}>{referralData.frase_link_proprio}</Typography.Title>
              <br />
              {isSelfLink ? (
                <Button
                  type="primary"
                  onClick={() => {
                    window.location.href = referralData.url_link_proprio
                  }}
                  block
                >
                  {referralData.texto_botao_link_proprio}
                </Button>
              ) : (
                <Space direction="vertical" size="large">
                  <Spin />
                  <Typography.Text>Você será redirecionado. Aguarde...</Typography.Text>
                </Space>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </BaseTemplate>
  )
}

export default ReferralPage
