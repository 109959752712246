import { Button } from 'antd'
import styled from 'styled-components'

import { colors, typography } from 'styles'

export const ProductTitle = styled.h1`
  color: #fff;
  font-size: ${typography.textSize};
  margin-top: 12px;
  margin-bottom: 8px;
  width: 180px;

  @media (max-width: 720px) {
    margin-top: 0;
  }
`

export const ProductTitleLarge = styled(ProductTitle)`
  font-size: 22px;
  margin-top: 0;
  width: auto;

  @media (max-width: 720px) {
    text-align: center;
  }
`

export const ProductDescription = styled.div`
  max-width: 100%;
  color: rgba(255, 255, 255, 0.7);
  font-size: ${typography.textSize};
  line-height: 22px;
  margin-bottom: 8px;

  @media (max-width: 720px) {
    text-align: center;
  }
`

export const Container = styled.div`
  display: flex;

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
  }
`

export const RightCol = styled.div`
  margin-left: 24px;
  width: 100%;

  @media (max-width: 720px) {
    margin-left: 0;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const LeftCol = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    flex-direction: row;
  }
`

export const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 720px) {
    & button {
      width: 100%;
    }
  }
`

export const ImageContainer = styled.div`
  width: 180px;

  @media (max-width: 720px) {
    width: 100px;
    margin-right: 12px;
  }
`

export const LeftColTexts = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabTitle = styled.h2`
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`

export const TabIcon = styled.img`
  width: 24px;
  margin-right: 8px;
`

export const LinkButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  width: 150px;
`
export const VideoContainer = styled.div`
  width: 400px;

  @media (max-width: 720px) {
    width: 100%;
  }
`
