import React from 'react'
import { Table, Space } from 'antd'
import { useFetch } from 'use-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'

import { createAdminGetUrl } from 'utils'
import { colors } from 'styles/variables'
import TrendInfo from '../../trend-info'

const { Column } = Table

const Renovacoes = () => {
  const { data, loading, error } = useFetch(
    createAdminGetUrl('DashCentroControleRenovacoes'),
    null,
    []
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      rightComponent={<FontAwesomeIcon icon={faHistory} color={colors.primaryColor} />}
      title="Renovações"
    >
      <Table size="small" pagination={false} dataSource={data}>
        <Column title="Quando" dataIndex="label" key="label" />
        <Column
          title="Qtd"
          dataIndex="qdade"
          align="center"
          key="qdade"
          render={value => (
            <MetricNumber value={Number(value)}>
              {({ formatedNumber }) => formatedNumber}
            </MetricNumber>
          )}
        />
        <Column
          title="Autorizadas"
          dataIndex="autorizadas"
          align="center"
          key="autorizadas"
          render={(value, row) => (
            <Space size={0}>
              <MetricNumber value={Number(value)}>
                {({ formatedNumber }) => formatedNumber}
              </MetricNumber>
              <TrendInfo info={row.autorizadasperc} isSmall />
            </Space>
          )}
        />
        <Column
          title="Não Autorizadas"
          dataIndex="naoautorizadas"
          align="center"
          key="naoautorizadas"
          render={(value, row) => (
            <Space size={0}>
              <MetricNumber value={Number(value)}>
                {({ formatedNumber }) => formatedNumber}
              </MetricNumber>
              <TrendInfo info={row.naoautorizadasperc} isSmall />
            </Space>
          )}
        />
        <Column
          title="Indefinidas"
          dataIndex="indefinidas"
          align="center"
          key="indefinidas"
          render={(value, row) => (
            <Space size={0}>
              <MetricNumber value={Number(value)}>
                {({ formatedNumber }) => formatedNumber}
              </MetricNumber>
              <TrendInfo info={row.indefinidasperc} isSmall />
            </Space>
          )}
        />
      </Table>
    </GenericCard>
  )
}

export { Renovacoes }
