import styled from 'styled-components'

import { typography, colors } from 'styles'

export const ButtonCard = styled.button`
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-color: ${({ selected }) =>
    selected ? 'rgba(255, 184, 37, 0.15)' : 'rgba(255, 255, 255, 0.1)'};
  box-shadow: ${({ selected }) => (selected ? `0 0 0 3px ${colors.primaryColor}` : 'none')};
  transition: all 250ms ease;
  cursor: pointer;
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: calc(33% - 12px);

  min-width: 130px;
  justify-content: center;
  align-items: center;
  margin: 6px;

  @media (max-width: 720px) {
    width: calc(50% - 12px);
  }

  &:hover {
    background-color: ${({ selected }) =>
      selected ? 'rgba(255, 184, 37, 0.15)' : 'rgba(255, 255, 255, 0.18)'};
  }
`

export const Container = styled.div`
  display: flex;
  margin: 0 -8px;
  flex-wrap: wrap;
`

export const Text = styled.label`
  font-family: ${typography.fontText};
  color: ${colors.defaultTextColor};
  font-size: ${typography.textSizeSmall};
  height: 16px;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
  pointer-events: none;
`

export const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`
