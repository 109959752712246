import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const ProductPrice = ({ price, large }) => {
  return (
    <S.Container>
      <S.Label>Valor</S.Label>

      <S.Price large={large}>R$ {price}</S.Price>
    </S.Container>
  )
}

ProductPrice.propTypes = {
  large: PropTypes.bool,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

ProductPrice.defaultProps = {
  large: false,
}

export default ProductPrice
