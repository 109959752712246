import React from 'react'
import PropTypes from 'prop-types'

const Container = ({ children, boxed }) => {
  return (
    <div
      style={{
        width: '90%',
        maxWidth: boxed ? '1280px' : '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  boxed: PropTypes.bool,
}

Container.defaultProps = {
  boxed: false,
}

export default Container
