import React from 'react'
import { PostCard } from '../post-card'

import * as S from './styles'
import { Select } from 'antd'

export const PostList = ({
  data,
  courseId,
  classId,
  isComment = false,
  onDeleteComment,
  onRespondPress,
  canLike,
  onSelectOrder,
  order,
}) => {
  return (
    <>
      <S.Order>
        <Select bordered={false} value={order || '0'} size="small" onChange={onSelectOrder}>
          <Select.Option value="0">Relevância</Select.Option>
          <Select.Option value="1">Mais votados</Select.Option>
          <Select.Option value="2">Mais respostas</Select.Option>
          <Select.Option value="3">Mais recentes</Select.Option>
        </Select>
      </S.Order>

      {data.map(post => {
        return (
          <S.CardDivider key={post.id_post}>
            <PostCard
              data={post}
              href={`/comunidade/${courseId}/${classId}/postagem/${post.id_post}`}
              isComment={isComment}
              onDeleteComment={onDeleteComment}
              onRespondPress={onRespondPress}
              canLike={canLike}
            />
          </S.CardDivider>
        )
      })}
    </>
  )
}
