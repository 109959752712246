import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Spin, message } from 'antd'
import { useLocation, Redirect } from 'react-router-dom'
import useFetch from 'use-http'
import queryString from 'query-string'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { BaseTemplate } from 'components'
import { createPostUrl, createAuthUrl, getAuthData, getUserSessionToken } from 'utils'
import { PAYMENT_OPTIONS_SELECT } from 'enums'

import ProductOffer from './components/product-offer'
import CheckoutFormWizard from './components/checkout-form-wizard'
import CheckoutSuccessState from '../../components/checkout-success-state'

const CHECKOUT_VARIATIONS = {
  OFFER: 'OFFER',
  FORMS: 'FORMS',
  SUCCESS: 'SUCCESS',
}

const SUCCESS = '1'

const getUrl = (path, params = '') =>
  `${process.env.REACT_APP_API_URL}${path}/${createAuthUrl()}/${params}`

export const CheckoutText = styled.p`
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 12px;
`

const ProductsCheckout = () => {
  const userToken = getUserSessionToken()
  const { search, pathname } = useLocation()
  const { product, variationId, e: isEmbeded } = queryString.parse(search)

  const [checkoutPayload, setCheckoutPayload] = useState({ chave_produto: product })
  const [checkoutVariations, setCheckoutVariations] = useState(CHECKOUT_VARIATIONS.OFFER)
  const [selectedProduct, setSelectedProduct] = useState()
  const [successState, setSuccessState] = useState({})

  const { loading, error, data: checkoutInfo } = useFetch(getUrl('InfoProduto', product), null, [])

  const { data: creditCards } = useFetch(getUrl('CarregaCartoes'), null, [])

  const {
    response: boletoPurchaseResponse,
    loading: boletoPurchaseLoading,
    post: submitBoletoPurchase,
  } = useFetch(createPostUrl('ComprarBoleto'), null)

  const {
    response: cartaoPurchaseResponse,
    loading: cartaoPurchaseLoading,
    post: submitCartaoPurchase,
  } = useFetch(createPostUrl('ComprarCartao'), null)

  const handleConfirmOffer = selectedProductVariation => {
    setSelectedProduct(selectedProductVariation)
    setCheckoutVariations(CHECKOUT_VARIATIONS.FORMS)
  }

  const handleFinishPurchase = async (paymentMethod, paymentoPayload) => {
    const { nome, ...payload } = checkoutPayload

    const purchasePayload = {
      id_variacao: String(selectedProduct?.id_variacao),
      nome_aluno: nome,
      ...getAuthData(),
      ...payload,
      ...paymentoPayload,
    }

    if (paymentMethod === PAYMENT_OPTIONS_SELECT.BOLETO) {
      await submitBoletoPurchase(purchasePayload)

      if (boletoPurchaseResponse.ok && boletoPurchaseResponse?.data[0]?.resultado === SUCCESS) {
        setSuccessState({
          image: selectedProduct?.imagem,
          title: selectedProduct?.titulo_obrigado_boleto,
          text: selectedProduct?.descricao_obrigado_boleto,
          boletoUrl: boletoPurchaseResponse?.data[0]?.url_boleto,
        })

        setCheckoutVariations(CHECKOUT_VARIATIONS.SUCCESS)
      } else {
        message.error(boletoPurchaseResponse?.data[0]?.frase || 'Erro desconhecido')
      }
    }

    if (paymentMethod === PAYMENT_OPTIONS_SELECT.CREDIT_CARD) {
      await submitCartaoPurchase({ ...purchasePayload })

      if (cartaoPurchaseResponse.ok && cartaoPurchaseResponse?.data[0]?.resultado === SUCCESS) {
        setSuccessState({
          image: selectedProduct?.imagem,
          title: selectedProduct?.titulo_obrigado_cartao,
          text: selectedProduct?.descricao_obrigado_cartao,
          boletoUrl: null,
        })

        setCheckoutVariations(CHECKOUT_VARIATIONS.SUCCESS)
      } else {
        message.error(cartaoPurchaseResponse?.data[0]?.frase || 'Erro desconhecido')
      }
    }
  }

  useEffect(() => {
    if (!loading && checkoutInfo && variationId) {
      const selectVariation = checkoutInfo[0]['variacoes '].find(
        variation => variation.id_variacao === Number(variationId)
      )

      if (selectVariation) {
        handleConfirmOffer(selectVariation)
      }
    }
  }, [checkoutInfo, variationId, loading])

  if (!userToken) {
    return <Redirect to={`/login?next=${pathname}${search}&nextPageTitle=Compra de produto`} />
  }

  if (loading && !error && !checkoutInfo) {
    return (
      <BaseTemplate bgColor="#333" cleanHeader={isEmbeded} removeHeaderAndFooter removeFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Spin />
        </div>
      </BaseTemplate>
    )
  }

  if (!loading && error) {
    return <h2>Occoreu um erro inesperado ao abrir esta página.</h2>
  }

  if (!loading && checkoutInfo && checkoutInfo[0] && checkoutInfo[0].permite_comprar === '0') {
    return (
      <BaseTemplate bgColor="#333" cleanHeader={isEmbeded} removeHeaderAndFooter removeFooter>
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </div>
        <CheckoutText>{checkoutInfo[0].frase_nao_permite}</CheckoutText>
      </BaseTemplate>
    )
  }

  return (
    <BaseTemplate bgColor="#333" cleanHeader={isEmbeded} removeHeaderAndFooter removeFooter>
      <div style={isEmbeded ? {} : { padding: 16 }}>
        <Helmet title="Checkout Produto" />

        {checkoutVariations === CHECKOUT_VARIATIONS.OFFER && (
          <ProductOffer
            onConfirmOffer={handleConfirmOffer}
            variations={checkoutInfo[0] && checkoutInfo[0]['variacoes ']}
          />
        )}

        {checkoutVariations === CHECKOUT_VARIATIONS.FORMS && (
          <CheckoutFormWizard
            onChangeProductClick={() => setCheckoutVariations(CHECKOUT_VARIATIONS.OFFER)}
            setCheckoutPayload={setCheckoutPayload}
            onFinishPurchase={handleFinishPurchase}
            purchaseLoading={boletoPurchaseLoading || cartaoPurchaseLoading}
            checkoutPayload={checkoutPayload}
            creditCards={creditCards}
            selectedProduct={selectedProduct}
            checkout={checkoutInfo[0]}
          />
        )}

        {checkoutVariations === CHECKOUT_VARIATIONS.SUCCESS && (
          <CheckoutSuccessState
            image={successState?.image}
            title={successState?.title}
            text={successState?.text}
            boletoUrl={successState?.boletoUrl}
          />
        )}
      </div>
    </BaseTemplate>
  )
}

ProductsCheckout.propTypes = {
  title: PropTypes.string,
  isRegisterUrl: PropTypes.bool,
}

ProductsCheckout.defaultProps = {
  isRegisterUrl: false,
  title: '',
}

export default ProductsCheckout
