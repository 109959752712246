import React, { useState } from 'react'
import { Space } from 'antd'
import { useFetch } from 'use-http'

import { createAdminGetUrl } from 'utils'
import GenericCard from '../../generic-card'
import TrendInfo from '../../trend-info'
import LineChart from '../../line-chart'
import MetricNumber from '../../metric-number'
import MonthPicker from '../../month-picker'

const getInitialValues = date => {
  if (!date) return { month: 0, year: 0 }

  const splitedDate = date.split('/')

  return {
    month: splitedDate[1],
    year: splitedDate[2],
  }
}

const RoiCard = () => {
  const [dateRange, setDateRange] = useState({
    startDate: '01/01/1900',
    endDate: '01/01/1900',
  })

  const { data, loading, error } = useFetch(
    createAdminGetUrl(
      'DashMKTVendasROI',
      `${encodeURIComponent(dateRange.startDate)}/${encodeURIComponent(dateRange.endDate)}`
    ),
    null,
    [dateRange]
  )

  const handleSetDateRange = selectedMonth => {
    const startDate = `${selectedMonth.start.month}/01/${selectedMonth.start.year}`
    const endDate = `${selectedMonth.end.month}/01/${selectedMonth.end.year}`

    setDateRange({ startDate, endDate })
  }

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="ROI"
      subHeaderComponent={
        <MetricNumber isPrimaryNumber value={data?.atual} label="Atual:">
          {({ formatedNumber }) => (
            <Space>
              {formatedNumber} <TrendInfo info={data?.evolucao} />
            </Space>
          )}
        </MetricNumber>
      }
      rightComponent={
        <MonthPicker
          initialValues={{
            start: getInitialValues(data?.inicio),
            end: getInitialValues(data?.fim),
          }}
          onFinishSelection={handleSetDateRange}
        />
      }
    >
      {data?.roi && <LineChart isMoney data={data?.roi} />}
    </GenericCard>
  )
}

export { RoiCard }
