import React from 'react'
import { Row, Col, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faYoutube, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { Container } from 'components'

import * as S from './styles'

const Footer = () => {
  return (
    <S.Footer>
      <Container>
        <Row gutter={[24, 24]} justify="space-between" align="bottom">
          <Col>
            <Space direction="vertical" size="middle">
              {process.env.REACT_APP_APP_ID === 'adp' ||
                (process.env.REACT_APP_APP_ID === 'adp' && (
                  <a href="/aplicativos" className="featured-link">
                    <FontAwesomeIcon icon={faMobileAlt} /> Baixe nosso aplicativo
                  </a>
                ))}
              <S.FooterLink
                href={process.env.REACT_APP_TERMS_PAGE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Termos e condições de uso
              </S.FooterLink>
              <S.FooterLink
                href={process.env.REACT_APP_PRIVACY_PAGE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Política de privacidade
              </S.FooterLink>
            </Space>
          </Col>

          <Col> {process.env.REACT_APP_APP_NAME} © {(new Date()).getFullYear()} Todos os direitos reservados</Col>

          <Col>
            <Space size="middle">
              <S.FooterLink
                href={process.env.REACT_APP_FACEBOOK_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </S.FooterLink>
              <S.FooterLink
                href={process.env.REACT_APP_INSTAGRAM_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </S.FooterLink>
              <S.FooterLink
                href={process.env.REACT_APP_TWITTER_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </S.FooterLink>
              <S.FooterLink
                href={process.env.REACT_APP_YOUTUBE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} size="lg" />
              </S.FooterLink>
            </Space>
          </Col>
        </Row>
      </Container>
    </S.Footer>
  )
}

export default Footer
