import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Menu } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'

import { Header, Footer } from 'components'

import { GlobalStyles } from 'styles'

const getReactFaIconName = kebabIconName => {
  const splitedText = kebabIconName.split('-')

  const capitalizedWords = splitedText.map((word, index) => {
    if (index > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }

    return word
  })

  return capitalizedWords.join('')
}

const BaseTemplate = ({ children, bgColor, cleanHeader, removeHeaderAndFooter, removeFooter }) => {
  const [sideMenu, setSideMenu] = useState(false)

  const handleOpenSideMenu = () => setSideMenu(true)
  const handleCloseSideMenu = () => setSideMenu(false)

  return (
    <>
      <GlobalStyles bgColor={bgColor} />
      {!removeHeaderAndFooter && (
        <Header onSideMenuButton={handleOpenSideMenu} cleanHeader={cleanHeader} />
      )}

      <Drawer
        placement="left"
        closable
        onClose={handleCloseSideMenu}
        visible={sideMenu}
        key="side-menu"
        bodyStyle={{ paddingTop: '3rem', paddingLeft: 0, paddingRight: 0 }}
      >
        <Menu mode="inline" className="side-menu" style={{ width: 256 }}>
          {JSON.parse(process.env.REACT_APP_MENU).map(item => (
            <Menu.Item key={item.text}>
              <a
                href={item.newTab ? item.href : process.env.REACT_APP_WEBSITE_URL + item.href}
                target={item.newTab ? '_blank' : '_self'}
                style={{ fontWeight: 'bold' }}
              >
                <span style={{ display: 'inline-block', height: 32, width: 32 }}>
                  <FontAwesomeIcon icon={icons[getReactFaIconName(item.icon)]} />
                </span>
                <span> {item.text}</span>
              </a>
            </Menu.Item>
          ))}
        </Menu>
      </Drawer>

      {children}

      {removeHeaderAndFooter ? '' : removeFooter ? '' : <Footer />}
    </>
  )
}

BaseTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  contentPadding: PropTypes.string,
  cleanHeader: PropTypes.bool,
  removeHeaderAndFooter: PropTypes.bool,
  removeFooter: PropTypes.bool,
}

BaseTemplate.defaultProps = {
  contentPadding: '24px',
  cleanHeader: false,
  removeHeaderAndFooter: false,
  removeHeader: false,
}

export default BaseTemplate
