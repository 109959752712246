import React, { useCallback, useState } from 'react'
import { Button } from 'antd'

import ProductImage from 'components/product-image'
import ProductPrice from 'components/product-price'
import StepedBreadcrumb, { STEP_STATUS } from 'components/steped-breadcrumb'

import FormWizardStepOne from './form-wizard-step-one'
import FormWizardStepTwo from './form-wizard-step-two'
import FormWizardStepThree from './form-wizard-step-three'

import {
  RightCol,
  Container,
  LeftCol,
  ProductTitle,
  ImageContainer,
  LeftColTexts,
  LinkButton,
} from './styles'

const STEPS = {
  CONFIRM_INFO: 0,
  ANDRESS: 1,
  PAYMENT: 2,
}

const CheckoutFormWizard = ({
  creditCards,
  checkout,
  selectedProduct,
  onChangeProductClick,
  purchaseLoading,
  setCheckoutPayload,
  onFinishPurchase,
}) => {
  const [currentFormStep, setFormStep] = useState(STEPS.CONFIRM_INFO)

  const breadcrumb = [
    { title: 'Confirmar dados' },
    { title: 'Endereço' },
    { title: 'Pagamento' },
  ].map((step, index) => {
    if (currentFormStep > index) {
      return { ...step, status: STEP_STATUS.finish }
    }

    if (currentFormStep === index) {
      return { ...step, status: STEP_STATUS.initial }
    }

    return { ...step, status: STEP_STATUS.wait }
  })

  const scrollDown = useCallback(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 99999999,
        left: 0,
        behavior: 'smooth',
      })
    }, 150)
  }, [])

  const handleStepOneSubmit = personalInfo => {
    setCheckoutPayload(prevValues => ({ ...prevValues, ...personalInfo }))

    if (checkout.confirmar_endereco) {
      setFormStep(STEPS.ANDRESS)
    } else {
      setFormStep(STEPS.PAYMENT)
    }
    scrollDown()
  }

  const handleStepTwoSubmit = andress => {
    setCheckoutPayload(prevValues => ({ ...prevValues, ...andress }))
    setFormStep(STEPS.PAYMENT)
    scrollDown()
  }

  return (
    <Container>
      <LeftCol>
        <ImageContainer>
          <ProductImage width="100%" src={selectedProduct?.imagem} />
        </ImageContainer>
        <LeftColTexts>
          <ProductTitle>{selectedProduct?.nome_produto}</ProductTitle>
          <ProductPrice price={selectedProduct?.valor_produto} />
          <LinkButton size="small" type="text" onClick={onChangeProductClick}>
            Alterar produto
          </LinkButton>
        </LeftColTexts>
      </LeftCol>

      <RightCol>
        <StepedBreadcrumb current={currentFormStep} steps={breadcrumb} />

        {currentFormStep === STEPS.CONFIRM_INFO && (
          <FormWizardStepOne checkout={checkout} onSubmit={handleStepOneSubmit} />
        )}

        {checkout.confirmar_endereco && currentFormStep === STEPS.ANDRESS && (
          <FormWizardStepTwo
            checkout={checkout}
            helpText={selectedProduct.frase_endereco}
            onBack={() => setFormStep(STEPS.CONFIRM_INFO)}
            onSubmit={handleStepTwoSubmit}
          />
        )}

        {currentFormStep === STEPS.PAYMENT && (
          <FormWizardStepThree
            creditCards={creditCards}
            selectedProduct={selectedProduct}
            onFinishPurchase={onFinishPurchase}
            purchaseLoading={purchaseLoading}
            onBack={() => setFormStep(STEPS.ANDRESS)}
          />
        )}
      </RightCol>
    </Container>
  )
}

CheckoutFormWizard.propTypes = {}

CheckoutFormWizard.defaultProps = {}

export default CheckoutFormWizard
