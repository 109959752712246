import React from 'react'
import { Space } from 'antd'
import { useFetch } from 'use-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet } from '@fortawesome/free-solid-svg-icons'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'
import SteppedProgress from '../../stepped-progress'

import { colors } from 'styles'
import { createAdminGetUrl } from 'utils'

const MetaDeVendas = () => {
  const { data, loading, error } = useFetch(
    createAdminGetUrl('DashCentroControleMetaVendas'),
    null,
    []
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="Meta de vendas"
      subHeaderComponent={
        <Space size="large">
          <MetricNumber isPrimaryNumber sideLabel="Atualmente" value={Number(data?.valoratual)}>
            {({ formatedNumber }) => formatedNumber}
          </MetricNumber>
        </Space>
      }
      rightComponent={<FontAwesomeIcon icon={faWallet} color={colors.primaryColor} />}
    >
      <SteppedProgress steps={data?.steps} />
    </GenericCard>
  )
}

export { MetaDeVendas }
