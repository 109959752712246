import styled, { css } from 'styled-components'

import { colors } from 'styles'

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 48px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`

export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  height: 48px;
  border-radius: 6px;
  overflow: hidden;
`

export const Step = styled.div`
  flex: 1;
  position: relative;
  margin-right: ${({ isLastStep }) => (isLastStep ? 0 : '4px')};
  background-color: #525252;
`

const backgroundColor = ({ reverseColorsRange, stepIndex }) => {
  if (!reverseColorsRange) {
    return css`
      background-color: #59e04f;
    `
  }

  if (stepIndex === 0) {
    return css`
      background-color: ${colors.primaryColor};
    `
  }

  return css`
    background-color: ${colors.red};
  `
}

export const StepFill = styled.div`
  width: ${({ percent }) => percent + '%'};
  ${({ reverseColorsRange, stepIndex }) => backgroundColor({ reverseColorsRange, stepIndex })}
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 9;
`

export const StepNumber = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
