import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  flex-direction: column;
`

export const Title = styled.h1`
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
`

export const Text = styled.p`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  margin-bottom: 16px;
`

export const ImageContainer = styled.div`
  position: relative;
`

export const CheckIcon = styled.img`
  position: absolute;
  width: 80px;
  top: -16px;
  right: -48px;
`
