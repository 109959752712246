import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const ProgressStepBar = ({ step, total }) => {
  const getBarPercentage = () => (step * 100) / total

  return (
    <S.Wrapper>
      <S.Fill style={{ width: `${getBarPercentage()}%` }} />
    </S.Wrapper>
  )
}

ProgressStepBar.propTypes = {
  step: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

export default ProgressStepBar
