import React, { useState } from 'react'
import { Row, Col, Button } from 'antd'
import creditCardType from 'credit-card-type'
import InputMask from 'react-input-mask'
import ReCAPTCHA from 'react-google-recaptcha'
import * as Yup from 'yup'

import { removeUderline } from 'utils'
import { validateFields } from 'utils/validate-fields'
import { useFormik } from 'formik'

import amex from '../../images/bandeira_amex.svg'
import elo from '../../images/bandeira_elo.svg'
import hiper from '../../images/bandeira_hiper.svg'
import visa from '../../images/bandeira_visa.svg'
import mastercard from '../../images/bandeira_master.svg'
import defaultIcon from '../../images/default-card.svg'

import * as S from './styles'
import InstallmentsSelect from '../installment-select'

const cards = {
  mastercard,
  visa,
  hiper,
  elo,
  amex,
  default: defaultIcon,
}

const getCreditCardFlag = number => {
  const found = creditCardType(number)

  if (found && found.length > 0 && found.length <= 2) {
    return found[0].type
  }

  return 'default'
}

const fieldValdiations = {
  numero: Yup.string()
    .test('is-numero-valid', 'Digite um número de cartão válido', value => {
      return removeUderline(value.split(' ').join('')).length === 16
    })
    .required('Campo obrigatório'),
  cvv: Yup.string()
    .test('is-cvv-valid', 'Digite um cód. de segurança válido', value => {
      return removeUderline(value).length === 3
    })
    .required('Campo obrigatório'),
  cpf_cartao: Yup.string()
    .test('is-cpf-valid', 'Digite um CPF válido', value => {
      return removeUderline(value).length === 14
    })
    .required('Campo obrigatório'),
  validade: Yup.string()
    .test('is-validade-valid', 'Digite um cód. de segurança válido', value => {
      return removeUderline(value).length === 5
    })
    .required('Campo obrigatório'),
  nome: Yup.string().required('Campo obrigatório'),
  tokenCap: Yup.string().required('Resolva o Recaptcha para concluir'),
  parcelas: Yup.string().required('Selecione a quantidade de parcelas'),
}

const AddCreditCardForm = ({
  onCancel,
  onSubmit,
  loading,
  submitButtonText = 'Cadastrar Cartão',
  installments,
}) => {
  const { values, errors, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: {
      numero: '',
      nome: '',
      cvv: '',
      validade: '',
      cpf_cartao: '',
      parcelas: '',
      tokenCap: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      const errors = validateFields(values, fieldValdiations)

      if (errors.length) {
        errors.forEach(error => {
          setFieldError(error.field, error.message)
        })
        return
      }

      const { numero, validade, ...restValues } = values

      const [validade_mes, validade_ano] = validade.split('/')

      if (onSubmit)
        onSubmit({
          numero: numero.split(' ').join(''),
          ...restValues,
          validade_mes,
          validade_ano,
          bandeira: '',
          id_cartao: '0',
        })
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={[16, 0]}>
        <Col span={16}>
          <S.Label>
            Número do cartão:
            <div>
              <InputMask
                mask="9999 9999 9999 9999"
                placeholder="____ ____ ____ ____"
                name="numero"
                size="small"
                value={values.numero}
                onChange={handleChange}
              >
                {inputProps => (
                  <S.Input
                    prefix={<img width={24} src={cards[getCreditCardFlag(values.numero)]} />}
                    error={errors.numero}
                    {...inputProps}
                  />
                )}
              </InputMask>
            </div>
          </S.Label>
          {errors.numero && <S.ErrorMessage>{errors.numero}</S.ErrorMessage>}
        </Col>

        <Col span={8}>
          <S.Label>
            Validade:
            <InputMask
              mask="99/99"
              placeholder="__/__"
              name="validade"
              size="small"
              value={values.validade}
              onChange={handleChange}
            >
              {inputProps => <S.Input error={errors.validade} {...inputProps} />}
            </InputMask>
          </S.Label>
          {errors.validade && <S.ErrorMessage>{errors.validade}</S.ErrorMessage>}
        </Col>

        <Col span={16}>
          <S.Label>
            Nome (igual no cartão):
            <S.Input
              placeholder="Nome (igual no cartão)"
              error={errors.nome}
              name="nome"
              size="small"
              value={values.nome}
              onChange={handleChange}
            />
          </S.Label>
          {errors.nome && <S.ErrorMessage>{errors.nome}</S.ErrorMessage>}
        </Col>

        <Col span={8}>
          <S.Label>
            Cód. de segurança:
            <InputMask
              mask="999"
              placeholder="___"
              name="cvv"
              size="small"
              value={values.cvv}
              onChange={handleChange}
            >
              {inputProps => <S.Input error={errors.cvv} {...inputProps} />}
            </InputMask>
          </S.Label>
          {errors.cvv && <S.ErrorMessage>{errors.cvv}</S.ErrorMessage>}
        </Col>

        <Col span={24}>
          <S.Label>
            CPF do titular:
            <InputMask
              mask="999.999.999-99"
              placeholder="___.___.___-__"
              name="cpf_cartao"
              size="small"
              value={values.cpf_cartao}
              onChange={handleChange}
            >
              {inputProps => <S.Input error={errors.cpf_cartao} {...inputProps} />}
            </InputMask>
          </S.Label>
          {errors.cpf_cartao && <S.ErrorMessage>{errors.cpf_cartao}</S.ErrorMessage>}
        </Col>

        <Col span={24}>
          <InstallmentsSelect
            installments={installments}
            onSelect={value => setFieldValue('parcelas', value)}
            error={errors.parcelas}
          />
        </Col>

        <Col span={24}>
          <div style={{ marginTop: 16 }}>
            <ReCAPTCHA
              hl="pt-BR"
              sitekey="6Le0ZOUUAAAAAHB2yBxsMCGuhXYfVjcc_V6cmr6y"
              onChange={tokenRecaptcha => setFieldValue('tokenCap', tokenRecaptcha)}
            />
            {errors.tokenCap && <S.ErrorMessage>{errors.tokenCap}</S.ErrorMessage>}
          </div>
        </Col>

        <Col span={24}>
          <S.Footer>
            {onCancel ? (
              <Button
                onClick={onCancel}
                size="small"
                type="text"
                htmlType="button"
                disabled={loading}
              >
                Alterar forma de pagamento
              </Button>
            ) : (
              <div></div>
            )}
            <Button type="primary" size="small" htmlType="submit" loading={loading}>
              {submitButtonText}
            </Button>
          </S.Footer>
        </Col>
      </Row>
    </form>
  )
}

export default AddCreditCardForm
