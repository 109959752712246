import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useState } from 'react'
import { useLikePost } from '../../../hooks/community/use-like-post'

import * as S from './styles'

const isPlural = length => length !== 1

export const PostLikeButton = ({ likesCount, authorHasLiked, postId }) => {
  const [_likesCount, setLikesCount] = useState(likesCount || 0)
  const [_authorHasLiked, setAuthorHasLiked] = useState(authorHasLiked)
  const likeLabel = isPlural(_likesCount) ? 'gostaram' : 'gostou'

  const [likePost] = useLikePost({ postId })

  const handleLikePost = async () => {
    const newLiked = !_authorHasLiked

    const newLikesCount = _authorHasLiked ? _likesCount - 1 : _likesCount + 1

    try {
      setAuthorHasLiked(newLiked)
      setLikesCount(newLikesCount)
      await likePost(!_authorHasLiked)
    } catch (e) {
      setAuthorHasLiked(_authorHasLiked)
      setLikesCount(_likesCount)
    }
  }

  return (
    <Button type={_authorHasLiked ? 'link' : 'text'} size="small" onClick={handleLikePost}>
      <FontAwesomeIcon icon={faThumbsUp} />
      <S.ButtonLabel>
        {_likesCount} {likeLabel}
      </S.ButtonLabel>
    </Button>
  )
}
