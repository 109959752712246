import React, { useState } from 'react'
import { useFetch } from 'use-http'
import { Select } from 'antd'

import { createAdminGetUrl } from 'utils'
import GenericCard from '../../generic-card'
import DateRangePicker from '../../range-picker'
import PieChart from '../../pie-chart'

const { Option } = Select

const TempoComoLead = () => {
  const [dateRange, setDateRange] = useState({
    startDate: '01%2F01%2F1900',
    endDate: '01%2F01%2F1900',
  })

  const [selectedAfiliates, setSelectedAfiliates] = useState([])

  const { data: afiliates = [], loading: afiliatesLoading } = useFetch(
    `${process.env.REACT_APP_API_URL}CarregaAfiliadosDash/${process.env.REACT_APP_TOKEN_APP}`,
    null,
    []
  )

  const { data = {}, loading, error } = useFetch(
    createAdminGetUrl(
      'DashMKTVendasLeadsXMatricula',
      `${dateRange.startDate}/${dateRange.endDate}/${selectedAfiliates.toString().trim()}`
    ),
    null,
    [dateRange, selectedAfiliates]
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="Tempo como lead"
      rightComponent={
        <DateRangePicker
          initialValues={{ startDate: data?.inicio, endDate: data?.fim }}
          onDateSelection={setDateRange}
        />
      }
    >
      <Select
        placeholder="Filtre por afiliado"
        style={{ width: '100%' }}
        loading={afiliatesLoading}
        onChange={setSelectedAfiliates}
        mode="multiple"
        value={selectedAfiliates}
        size="small"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        notFoundContent={
          <div style={{ marginTop: 16, textAlign: 'center', color: '#fff' }}>
            <p>Não encontramos afiliados para sua busca</p>
          </div>
        }
      >
        {afiliates?.length &&
          afiliates.map(item => (
            <Option key={`dash-afiliados-${item.id_afiliado}`} value={item.id_afiliado}>
              {item.nome_afiliado}
            </Option>
          ))}
      </Select>
      {data.meses && !data.meses.length && (
        <p style={{ marginTop: 24, textAlign: 'center' }}>Não encontramos nenhum resultado</p>
      )}
      <PieChart
        height={window.innerWidth <= 1024 ? 400 : 500}
        data={!!data.meses ? data.meses : []}
      />
    </GenericCard>
  )
}

export { TempoComoLead }
