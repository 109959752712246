import React from 'react'
import { Table, Typography } from 'antd'
import { useFetch } from 'use-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'
import TrendInfo from '../../trend-info'

import { colors } from 'styles'
import { createAdminGetUrl } from 'utils'

const { Column } = Table
const { Text } = Typography

const Cobrancas = () => {
  const { data, loading, error } = useFetch(
    createAdminGetUrl('DashCentroControleCobrancas'),
    null,
    []
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="Cobranças"
      rightComponent={<FontAwesomeIcon icon={faCreditCard} color={colors.primaryColor} />}
    >
      <Table size="small" showHeader={false} pagination={false} dataSource={data}>
        <Column
          dataIndex="valor"
          key="valor"
          align="right"
          render={(value, row) => {
            if (row.label === 'Inadimplentes') {
              return (
                <MetricNumber value={Number(value)}>
                  {({ formatedNumber }) => formatedNumber}
                </MetricNumber>
              )
            }

            return <MetricNumber value={value}>{({ value }) => value}</MetricNumber>
          }}
        />
        <Column
          dataIndex="label"
          key="label"
          render={(label, row) => (
            <>
              <Text type="secondary">
                {label} {row.evolucao ? <TrendInfo info={row.evolucao} /> : ''}
              </Text>
            </>
          )}
        />
      </Table>
    </GenericCard>
  )
}

export { Cobrancas }
