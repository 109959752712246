import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Typography, Card, Row, Col, message, Button, Space } from 'antd'
import useFetch from 'use-http'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import { FormItem, Input, Form } from 'formik-antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'

import { BaseTemplate, Container } from 'components'
import { getTokenApp, createPostUrl } from 'utils'
import { IS_FALSE } from 'enums'
import { useAdminPanel, ACTIONS } from 'context/admin-panel-context'
import { PAGES } from '../template'

const tokenApp = getTokenApp()

const validationSchema = Yup.object({
  email: Yup.string().email('Digite um e-mail válido').required('Digite seu e-mail para continuar'),
  password: Yup.string()
    .min(4, 'Sua senha deve ter pelo menos 4 digitos')
    .required('Digite sua senha para entrar'),
})

const LAST_USED_EMAIL_LS_KEY = 'adp_admin:last_used_email'
const USER_SESSION_SS_KEY = 'adp_admin:user_session'

const AdminPanelLoginPage = ({ title, isRegisterUrl }) => {
  const [_, dispatch] = useAdminPanel()
  const history = useHistory()

  const { post: sendLogin, response, loading } = useFetch(createPostUrl('ValidaLoginDash'), null)

  const handleLoginSubmit = async payload => {
    const authPayload = {
      token_app: tokenApp,
      email: payload.email,
      senha: payload.password,
    }
    try {
      const [data] = await sendLogin(authPayload)

      if (response.ok) {
        if (data.token === IS_FALSE) {
          return message.error('E-mail ou senha incorreta')
        }

        window.localStorage.setItem(LAST_USED_EMAIL_LS_KEY, payload.email)
        window.localStorage.setItem(USER_SESSION_SS_KEY, JSON.stringify(data))
        message.success('Login efetuado com sucesso!')
        dispatch({ type: ACTIONS.SET_USER, payload: data })
        dispatch({ type: ACTIONS.SET_USER_PERMISSIONS, payload: data.permissoes })
        const [firstPage] = data.permissoes

        history.push(!!PAGES[firstPage] ? PAGES[firstPage].url : '/painel/404')
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <BaseTemplate cleanHeader removeFooter>
      <Helmet title="Painel Administrativo" />
      <Container>
        <Row style={{ minHeight: '70vh' }} gutter={[16, 48]}>
          <Col
            xs={{ span: 24, push: 0 }}
            sm={{ span: 24, push: 0 }}
            md={{ span: 14, push: 5 }}
            lg={{ span: 14, push: 5 }}
            xl={{ span: 10, push: 7 }}
          >
            <Typography.Title level={1} align="center" style={{ marginTop: 16 }}>
              Painel Administrativo
            </Typography.Title>

            <Typography.Text
              type="secondary"
              style={{ marginBottom: 24, textAlign: 'center', display: 'block' }}
            >
              Faça login para entrar
            </Typography.Text>

            <Card>
              <Formik
                initialValues={{
                  email: window.localStorage.getItem(LAST_USED_EMAIL_LS_KEY) || '',
                  password: '',
                }}
                validationSchema={validationSchema}
                onSubmit={payload => {
                  handleLoginSubmit(payload)
                }}
              >
                {({ errors, touched }) => {
                  return (
                    <Form layout="vertical">
                      <FormItem
                        label="E-mail de admin"
                        name="email"
                        validateStatus={touched.email && errors.email && 'error'}
                        help={touched.email && errors.email}
                      >
                        <Input placeholder="" name="email" type="email" />
                      </FormItem>
                      <FormItem
                        label="Sua senha"
                        name="password"
                        validateStatus={touched.password && errors.password && 'error'}
                        help={touched.password && errors.password}
                      >
                        <Input.Password
                          placeholder=""
                          name="password"
                          type="password"
                          iconRender={visible =>
                            visible ? (
                              <FontAwesomeIcon icon={faEye} />
                            ) : (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            )
                          }
                        />
                      </FormItem>

                      <Button
                        htmlType="submit"
                        type="primary"
                        disabled={loading}
                        loading={loading}
                        block
                      >
                        Entrar
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </BaseTemplate>
  )
}

export default AdminPanelLoginPage
