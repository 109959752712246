import React, { useContext, useRef, useState } from 'react'
import Cookie from 'js-cookie'
import { Typography, Card, Row, Col, Button, Select, message } from 'antd'
import { BaseTemplate, Container } from '../../components'
import { FieldArray, Formik } from 'formik'
import { FormItem, Input, Form } from 'formik-antd'
import InputMask from 'react-input-mask'
import * as Yup from 'yup'
import useFetch from 'use-http'
import { createPublicGetUrl, getAuthData, createPostUrl } from 'utils'
import { useParams } from 'react-router-dom'
import { IS_FALSE } from 'enums'
import { AppContext } from 'context/app-context'

import us from '../../images/africa.png'
import brazil from '../../images/brazil.png'
import africa from '../../images/africa.png'
import italy from '../../images/italy.png'
import { PHONE_MASKS } from 'enums'

import * as S from './styles'
import { getTokenApp } from '../../utils'
import { useEffect } from 'react'
import { useUserSession } from '../../hooks/auth/use-user-session'

const FLAG_IMAGES = {
  us,
  brazil,
  africa,
  italy,
}
const USER_NOT_FOUND = '0'
const USER_WAS_REGISTRED = '0'

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Campo obrigatório')
    .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Digite nome e sobrenome'),
  email: Yup.string().email('Digite um e-mail válido').required('Digite seu e-mail para continuar'),
  phone: Yup.string()
    .test('phone-check', 'Número de telefone incompleto', value =>
      value ? value.replaceAll('_', '').length >= value.length : true
    )
    .required('Campo obrigatório'),
  ckey: Yup.array().of(Yup.string()),
})

const { Option } = Select

const CertificatePage = () => {
  const { user } = useContext(AppContext)
  const { certificateKey } = useParams()
  const [selectedPhoneMask, setPhoneMask] = useState('brazil')
  const [initialValues, setInitialValues] = useState()
  const phoneRef = useRef()

  const { loading, data: certificatePageData = {} } = useFetch(
    createPublicGetUrl('CarregaCertificadoChave', certificateKey),
    null,
    []
  )

  const { post: registerUser, response: registerResponse, loading: registerLoading } = useFetch(
    createPostUrl('CadastrarRapidoAluno'),
    null
  )

  const {
    loading: submitLoading,
    post: submitCertificate,
    response: submitCertificateResponse,
  } = useFetch(createPostUrl('GravaCertificadoChave'), null)

  const {
    get: getEmailValidation,
    response: emailValidationResponse,
    loading: emailValidationLoading,
  } = useFetch(createPublicGetUrl('PreValidaEmail'), null)

  const { data: userSession } = useUserSession()

  useEffect(() => {
    if (user?.isAuth && userSession) {
      const [sessionData] = userSession

      setInitialValues({
        name: sessionData.nome,
        email: sessionData.email,
        phone: false,
        ckey: [],
      })
    }
  }, [user, userSession])

  const MaskedInput = props => {
    return (
      <InputMask {...props}>{inputProps => <Input ref={phoneRef} {...inputProps} />}</InputMask>
    )
  }

  const handlePhoneBlur = (e, { setFieldValue, validateForm }) => {
    if (selectedPhoneMask === 'brazil') {
      const value = e.target.value
      const phoneWithout9Length = 14

      if (value.replaceAll('_', '').length === phoneWithout9Length) {
        const ddd = value.split(' ')[0]
        const number = value.split(' ')[1].replace('-', '').substr(0, 8)

        setFieldValue('phone', `${ddd} 9${number.substr(0, 4)}-${number.substr(4, 8)}`)
        validateForm()
      }
    }
  }

  const handlePhoneChange = (e, setFieldValue) => {
    if (selectedPhoneMask === 'other') {
      const value = e.target.value || ''
      setFieldValue('phone', value)
    } else {
      const value = e.target.value || ''
      const changedValue = value
        .replace(/\)/g, '')
        .replace(/\(/g, '')
        .replace(/-/g, '')
        .replace(/ /g, '')
      setFieldValue('phone', changedValue)
    }
  }

  const storeAuthCookies = responseData => {
    const { nome, status, token } = responseData

    const now = new Date()
    const expriresAfterAYear = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())

    Cookie.set('token', token, { expires: expriresAfterAYear, sameSite: 'lax' })
    Cookie.set('user', status, { expires: expriresAfterAYear, sameSite: 'lax' })
    Cookie.set('userName', nome, { expires: expriresAfterAYear, sameSite: 'lax' })
  }

  const handleSubmit = async (values, formikBag) => {
    if (values.ckey.filter(Boolean).length !== certificatePageData.palavras) {
      return formikBag.setFieldError('ckey', 'Digite a(s) palavra(s) chave')
    }

    const [emailData] = await getEmailValidation(encodeURIComponent(values.email))

    if (emailValidationResponse.ok && emailData.status === USER_NOT_FOUND) {
      const registerPayload = {
        token_afiliado:
          certificatePageData?.afiliado_cadastro ||
          Cookie.get('signup-token-afiliado') ||
          Cookie.get('checkout-token-afiliate') ||
          '',
        token_afiliado_global: Cookie.get(`${process.env.REACT_APP_APP_ID}_afiliado_global`) || '',
        paiscelular: '',
        token_app: getTokenApp(),
        email: values.email,
        senha: Array.from({ length: 6 })
          .map(() => Math.floor(Math.random() * 16).toString(16))
          .join(''),
        nome: values.name,
        celular: values.phone,
      }

      const registerResult = await registerUser(registerPayload)

      if (registerResponse.ok) {
        const [registerData] = registerResult

        if (registerData.status === USER_WAS_REGISTRED) {
          storeAuthCookies({
            nome: registerData.nome,
            status: registerData.status_usuario,
            token: registerData.token_sessao,
          })

          const [fn, ln] = registerData.nome.split(' ')

          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'generate_lead',
            affiliation: Cookie.get('signup-token-afiliado') || '',
            user_data: {
              em: registerData.email,
              fn,
              ln: ln || '',
              ph: registerData.telefonecomddi,
              user_id: registerData.idaluno,
            },
          })
        }
      }
    }

    const payload = {
      ...getAuthData(),
      palavras: values.ckey.map(key => ({ item: key })),
      nome: values.name,
      email: values.email,
      chave: certificateKey,
    }

    const [certificateResult] = await submitCertificate(payload)

    if (submitCertificateResponse.ok) {
      if (certificateResult.liberado === 'NAO') {
        return message.error(certificateResult.mensagem)
      }

      window.open(certificateResult.url_certificado, '_blank')

      window.location.href = certificateResult.url_destino
    }
  }

  return (
    <BaseTemplate cleanHeader>
      <Container>
        <Row>
          <Col
            xs={{ span: 24, push: 0 }}
            sm={{ span: 24, push: 0 }}
            md={{ span: 14, push: 5 }}
            lg={{ span: 10, push: 7 }}
            xl={{ span: 10, push: 7 }}
          >
            <Typography.Title level={2} style={{ textAlign: 'center' }}>
              {certificatePageData.titulo}
            </Typography.Title>
            <S.Html dangerouslySetInnerHTML={{ __html: certificatePageData.html }}></S.Html>
            <Card loading={loading}>
              <Formik
                initialValues={initialValues || { name: '', email: '', phone: '', ckey: [] }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, errors, touched, setFieldValue, validateForm }) => {
                  return (
                    <Form layout="vertical">
                      <FormItem
                        label="Nome completo"
                        name="name"
                        validateStatus={touched.name && errors.name && 'error'}
                        help={touched.name && errors.name}
                      >
                        <Input placeholder="Nome completo" name="name" type="text" />
                      </FormItem>

                      <FormItem
                        label="E-mail"
                        name="email"
                        validateStatus={touched.email && errors.email && 'error'}
                        help={touched.email && errors.email}
                      >
                        <Input
                          placeholder="E-mail"
                          type="email"
                          name="email"
                          disabled={initialValues?.email}
                        />
                      </FormItem>

                      {values.phone !== false && (
                        <FormItem
                          label="Celular (De preferência WhatsApp)"
                          name="phone"
                          validateStatus={touched.phone && errors.phone && 'error'}
                          help={touched.phone && errors.phone}
                        >
                          <div className="phoneMaskContainer">
                            <Select
                              className="phoneMaskSelect"
                              onChange={setPhoneMask}
                              value={
                                <>
                                  {FLAG_IMAGES[selectedPhoneMask] && (
                                    <img
                                      src={FLAG_IMAGES[selectedPhoneMask]}
                                      className="flag"
                                      alt="bandeira selecionada"
                                    />
                                  )}{' '}
                                  {PHONE_MASKS.find(mask => mask.name === selectedPhoneMask).label}
                                </>
                              }
                            >
                              {PHONE_MASKS.map(mask => (
                                <Option key={mask.label} value={mask.name}>
                                  {FLAG_IMAGES[mask.name] && (
                                    <img
                                      src={FLAG_IMAGES[mask.name]}
                                      className="flag"
                                      alt={mask.label}
                                    />
                                  )}{' '}
                                  {mask.label}
                                </Option>
                              ))}
                            </Select>

                            <MaskedInput
                              ref={phoneRef}
                              className="phoneMaskField"
                              mask={
                                selectedPhoneMask === 'other'
                                  ? null
                                  : PHONE_MASKS.find(mask => mask.name === selectedPhoneMask).value
                              }
                              value={values.phone}
                              placeholder="Celular"
                              type="tel"
                              name="phone"
                              id="phone-masked"
                              alwaysShowMask={selectedPhoneMask !== 'other'}
                              onBlur={e => handlePhoneBlur(e, { setFieldValue, validateForm })}
                              onChange={e => handlePhoneChange(e, setFieldValue)}
                            />
                          </div>
                        </FormItem>
                      )}

                      <Typography.Text style={{ display: 'block' }}>
                        {certificatePageData.palavras.length > 1
                          ? 'Digite as palavras chave abaixo:'
                          : 'Digite a palavra chave abaixo:'}
                      </Typography.Text>

                      <FieldArray
                        render={fieldArray =>
                          Array.from({ length: certificatePageData.palavras }).map((_, index) => (
                            <FormItem
                              name={`ckey.[${index}]`}
                              key={`ckey.[${index}]`}
                              validateStatus={touched.ckey && errors.ckey && 'error'}
                              help={touched.ckey && errors.ckey}
                            >
                              <Input
                                placeholder={`Palavra chave ${index + 1}`}
                                name={`ckey.[${index}]`}
                                type="text"
                              />
                            </FormItem>
                          ))
                        }
                      />

                      <Button
                        htmlType="submit"
                        type="primary"
                        disabled={emailValidationLoading || submitLoading}
                        loading={emailValidationLoading || submitLoading}
                        block
                      >
                        Emitir meu certificado
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </BaseTemplate>
  )
}

export default CertificatePage
