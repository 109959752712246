import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import * as S from './styles'

const ChatHeader = ({ title, chatListIsOpen, handleOpenChatList, unreadMessagesCounter }) => {
  return (
    <S.Header>
      {chatListIsOpen ? (
        <S.DisabledHeaderButton>Chat</S.DisabledHeaderButton>
      ) : (
        <S.BackToConversationsButton size="small" type="text" onClick={handleOpenChatList}>
          Chat {unreadMessagesCounter > 0 && <S.UnreadBadge>{unreadMessagesCounter}</S.UnreadBadge>}
        </S.BackToConversationsButton>
      )}

      {title && <FontAwesomeIcon icon={faChevronRight} style={{ opacity: 0.5 }} />}

      <S.HeaderTitle>{title}</S.HeaderTitle>
    </S.Header>
  )
}

ChatHeader.propTypes = {
  unreadMessagesCounter: PropTypes.number,
  handleOpenChatList: PropTypes.func.isRequired,
  title: PropTypes.string,
  chatListIsOpen: PropTypes.bool,
}

ChatHeader.defaultProps = {
  unreadMessagesCounter: 0,
  title: '',
  chatListIsOpen: false,
}

export default ChatHeader
