import React, { useState, useEffect } from 'react'
import { Button, Input, Form, Spin } from 'antd'

import { ResponsiveYoutube, Brand } from 'components'
import QuestionOption from '../../exam/question-option'
import { IS_TRUE } from 'enums'
import { getShuffledArr } from 'utils'

import checkIcon from '../../../images/check-icon.png'

import * as S from './styles'

const OnboardingTemplate = ({
  data,
  stepIndex,
  answers,
  onNext,
  onPrevious,
  addSingleAnswer,
  removeSingleAnswer,
  addMultipleAnswer,
  removeMultipleAnswer,
  addTextAnswer,
  isFirstStep,
  isLastStep,
  onFinish,
  submitLoading,
}) => {
  const [questionIndex, setQuestionIndex] = useState(0)
  const [activeFinishButton, setFinishButtonActive] = useState(false)

  const type = Number(data.tipo)

  useEffect(() => {
    document.querySelector('html.app-html').scrollTop = 0
  }, [questionIndex])

  useEffect(() => {
    let timeout
    if (type === 3) {
      timeout = setTimeout(() => {
        setFinishButtonActive(true)
        onFinish()
      }, 10000)
    }
    return () => {
      setFinishButtonActive(false)
      if (timeout) timeout.clear()
    }
  }, [type])

  const getActiveOption = answerId => {
    if (!answers) return false

    const activeOption = answers.find(item => {
      return item.resp === answerId
    })

    return !!activeOption
  }

  const getOptionTextValue = answerId => {
    if (!answers) return false

    const activeOption = answers.find(item => {
      return item.quest === answerId
    })

    return activeOption ? activeOption.texto : ''
  }

  const handleAddClick = (data, isMultiple) => {
    if (isMultiple) return addMultipleAnswer(data)

    addSingleAnswer(data)
  }

  const handleRemoveClick = (data, isMultiple) => {
    if (isMultiple) return removeMultipleAnswer(data)

    removeSingleAnswer(data)
  }

  const handleTextChange = ({ questionId, answerId, value }) => {
    addTextAnswer({ quest: questionId, resp: answerId, texto: value })
  }

  const isNextButtonDisable = questionId => {
    if (!answers) return true

    const questionAnswer = answers.find(item => item.quest === questionId)

    return !questionAnswer
  }

  const handleNext = () => {
    if (questionIndex + 1 === data.data.length) {
      onNext()
      setQuestionIndex(0)
    } else {
      setQuestionIndex(questionIndex + 1)
    }
  }

  const handlePrevious = () => {
    if (questionIndex === 0) {
      onPrevious()
    } else {
      setQuestionIndex(questionIndex - 1 < 0 ? 0 : questionIndex - 1)
    }
  }

  const onVideoReady = e => {
    e.target.playVideo()
  }

  const onEndVideo = e => {
    e.target.seekTo(0)
    e.target.pauseVideo()
  }

  const shouldFinishOnboarding = () => {
    if (!isLastStep) return false

    if (type === 0) {
      return true
    }

    return questionIndex === data.data.length
  }

  if (type === 0) {
    // Informações
    return (
      <S.Container>
        <S.Header>
          {data.titulo && <S.Title>{data.titulo}</S.Title>}{' '}
          <S.ProgressBar>
            <S.ProgressBarInside
              style={{
                width: `${(stepIndex / Object.keys(data.data).length) * 100}%`,
              }}
            />
          </S.ProgressBar>
        </S.Header>

        <S.Body>
          {data.data.video && (
            <ResponsiveYoutube
              videoUrl={data.data.video}
              opts={{
                playerVars: { autoplay: 1, playsinline: 1, modestbranding: 1, showinfo: 0, rel: 0 },
              }}
              onReady={onVideoReady}
              onEnd={onEndVideo}
            />
          )}
          {data.data.imagem && <S.Image src={data.data.imagem} />}
          <div dangerouslySetInnerHTML={{ __html: data.data.detalhamento }} />
        </S.Body>

        <S.Footer>
          {isFirstStep ? <div /> : <Button onClick={handlePrevious}>Voltar</Button>}
          <Button
            loading={submitLoading}
            type="primary"
            onClick={shouldFinishOnboarding() ? onFinish : onNext}
          >
            {shouldFinishOnboarding() ? 'Concluir' : 'Avançar'}
          </Button>
        </S.Footer>
      </S.Container>
    )
  }

  if (type === 1) {
    // pesquisa
    return (
      <S.Container>
        <S.Header>
          {data.titulo && <S.Title>{data.titulo}</S.Title>}{' '}
          <S.ProgressBar>
            <S.ProgressBarInside
              style={{
                width: `${(stepIndex / Object.keys(data.data).length) * 100}%`,
              }}
            />
          </S.ProgressBar>
        </S.Header>

        {data.data[questionIndex]?.questao && (
          <S.QuestionTitle>{data.data[questionIndex]?.questao}</S.QuestionTitle>
        )}
        {data.data[questionIndex]?.video && (
          <ResponsiveYoutube
            videoUrl={data.data[questionIndex].video}
            opts={{
              playerVars: { autoplay: 1, playsinline: 1, modestbranding: 1, showinfo: 0, rel: 0 },
            }}
            onReady={onVideoReady}
            onEnd={onEndVideo}
          />
        )}
        {data.data[questionIndex]?.imagem && <S.Image src={data.data[questionIndex]?.imagem} />}
        <div dangerouslySetInnerHTML={{ __html: data.data[questionIndex]?.detalhamento }} />

        <S.OptionsGrid>
          {data.data[questionIndex]?.respostas.map((option, index) => {
            if (option.tipo === '0' || option.tipo === '1') {
              // 0 = multipla escolha | 1 = escolha unica
              // 2 campo de texto grande | 3 input normal
              return (
                <S.Option
                  fullwidth={option.campo_texto !== '0'}
                  key={`pesquisa-escolha-${option.id_resposta}`}
                >
                  <QuestionOption
                    onAdd={() =>
                      handleAddClick(
                        {
                          quest: data.data[questionIndex].id,
                          resp: option.id_resposta,
                          texto: '',
                        },
                        option.tipo === '0' // é multipla escolha
                      )
                    }
                    onRemove={() => {
                      handleRemoveClick(
                        {
                          quest: data.data[questionIndex].id,
                          resp: option.id_resposta,
                          texto: '',
                        },
                        option.tipo === '0' // é multipla escolha
                      )
                    }}
                    active={getActiveOption(option.id_resposta)}
                    letter={index}
                  >
                    {option.resposta}
                  </QuestionOption>

                  {option.campo_texto === '1' && getActiveOption(option.id_resposta) && (
                    <Form layout="vertical">
                      <Form.Item label="Responda aqui">
                        <Input
                          onChange={e => {
                            handleTextChange({
                              answerId: option.id_resposta || '',
                              questionId: data.data[questionIndex].id,
                              value: e.target.value,
                            })
                          }}
                        />
                      </Form.Item>
                    </Form>
                  )}

                  {option.campo_texto === '2' && getActiveOption(option.id_resposta) && (
                    <Form layout="vertical">
                      <Form.Item label="Responda aqui">
                        <Input.TextArea
                          onChange={e => {
                            handleTextChange({
                              answerId: option.id_resposta || '',
                              questionId: data.data[questionIndex].id,
                              value: e.target.value,
                            })
                          }}
                          rows={4}
                        />
                      </Form.Item>
                    </Form>
                  )}
                </S.Option>
              )
            }

            if (option.tipo === '3') {
              return (
                <S.Option fullwidth key={`pesquisa-texto-grande-${option.id_resposta}`}>
                  <Form layout="vertical">
                    <Form.Item label="Responda aqui">
                      <Input
                        value={getOptionTextValue(data.data[questionIndex].id)}
                        onChange={e => {
                          handleTextChange({
                            answerId: option.id_resposta || '',
                            questionId: data.data[questionIndex].id,
                            value: e.target.value,
                          })
                        }}
                      />
                    </Form.Item>
                  </Form>
                </S.Option>
              )
            }

            if (option.tipo === '2') {
              return (
                <S.Option fullwidth key={`pesquisa-texto-pequeno-${option.id_resposta}`}>
                  <Form layout="vertical">
                    <Form.Item label="Responda aqui">
                      <Input.TextArea
                        value={getOptionTextValue(data.data[questionIndex].id)}
                        onChange={e => {
                          handleTextChange({
                            answerId: option.id_resposta || '',
                            questionId: data.data[questionIndex].id,
                            value: e.target.value,
                          })
                        }}
                        rows={4}
                      />
                    </Form.Item>
                  </Form>
                </S.Option>
              )
            }
          })}
        </S.OptionsGrid>
        <S.Footer>
          {isFirstStep ? <div /> : <Button onClick={handlePrevious}>Voltar</Button>}
          <Button
            type="primary"
            onClick={shouldFinishOnboarding() ? onFinish : handleNext}
            disabled={isNextButtonDisable(data.data[questionIndex].id)}
          >
            {shouldFinishOnboarding() ? 'Concluir' : 'Avançar'}
          </Button>
        </S.Footer>
      </S.Container>
    )
  }

  if (type === 2) {
    // questionario
    return (
      <S.Container>
        <S.Header>
          {data.titulo && <S.Title>{data.titulo}</S.Title>}{' '}
          <S.ProgressBar>
            <S.ProgressBarInside
              style={{
                width: `${(stepIndex / Object.keys(data.data).length) * 100}%`,
              }}
            />
          </S.ProgressBar>
        </S.Header>
        {data.data[questionIndex]?.questao && (
          <S.QuestionTitle>{data.data[questionIndex]?.questao}</S.QuestionTitle>
        )}
        {data.data[questionIndex]?.video && (
          <ResponsiveYoutube
            videoUrl={data.data[questionIndex].video}
            opts={{
              playerVars: { autoplay: 1, playsinline: 1, modestbranding: 1, showinfo: 0, rel: 0 },
            }}
            onReady={onVideoReady}
            onEnd={onEndVideo}
          />
        )}

        {data.data[questionIndex]?.imagem && <S.Image src={data.data[questionIndex]?.imagem} />}
        <div dangerouslySetInnerHTML={{ __html: data.data[questionIndex]?.detalhamento }} />

        <S.OptionsGrid>
          {data.data[questionIndex]?.respostas.map((option, index) => {
            return (
              <S.Option key={`questionario-item-${option.id_resposta}`}>
                <QuestionOption
                  onAdd={() =>
                    handleAddClick(
                      { quest: data.data[questionIndex].id, resp: option.id_resposta, texto: '' },
                      data.data[questionIndex].multiplaescolha === IS_TRUE
                    )
                  }
                  onRemove={() => {
                    handleRemoveClick(
                      { quest: data.data[questionIndex].id, resp: option.id_resposta, texto: '' },
                      data.data[questionIndex].multiplaescolha === IS_TRUE
                    )
                  }}
                  active={getActiveOption(option.id_resposta)}
                  letter={index}
                >
                  {option.resposta}
                </QuestionOption>
              </S.Option>
            )
          })}
        </S.OptionsGrid>

        <S.Footer>
          {isFirstStep ? <div /> : <Button onClick={handlePrevious}>Voltar</Button>}
          <Button
            type="primary"
            onClick={shouldFinishOnboarding() ? onFinish : handleNext}
            disabled={isNextButtonDisable(data.data[questionIndex].id)}
          >
            {shouldFinishOnboarding() ? 'Concluir' : 'Avançar'}
          </Button>
        </S.Footer>
      </S.Container>
    )
  }

  if (type === 3) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          textAlign: 'center',
        }}
      >
        <Brand />
        {activeFinishButton ? (
          <>
            <S.FinalTitle>Tudo pronto! Concluindo...</S.FinalTitle>
            <div style={{ marginTop: 24, marginBottom: 24, textAlign: 'center' }}>
              <Spin />
            </div>
          </>
        ) : (
          <>
            {data.titulo && <S.FinalTitle>{data.titulo}</S.FinalTitle>}

            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <S.SlidingVertical delay={50}>
                {getShuffledArr(data.data.slice(0, 11)).map(course => (
                  <span key={course.nome}>
                    <img src={course.imagem} />
                    {course.nome}
                  </span>
                ))}
              </S.SlidingVertical>
              <S.SlidingVertical delay={350}>
                {getShuffledArr(data.data.slice(11, 21)).map(course => (
                  <span key={course.nome}>
                    <img src={course.imagem} />
                    {course.nome}
                  </span>
                ))}
              </S.SlidingVertical>
              <S.SlidingVertical delay={750}>
                {getShuffledArr(data.data.slice(21, 30)).map(course => (
                  <span key={course.nome}>
                    <img src={course.imagem} />
                    {course.nome}
                  </span>
                ))}
              </S.SlidingVertical>
            </div>
          </>
        )}

        <S.Footer>
          <div />
          <Button
            loading={submitLoading}
            type="primary"
            onClick={onFinish}
            disabled={!activeFinishButton}
          >
            Concluir
          </Button>
        </S.Footer>
      </div>
    )
  }

  return <div></div>
}

export default OnboardingTemplate
