import styled from 'styled-components'
import { Input as AntdInput, Select as AntdSelect } from 'antd'

import { typography, colors } from 'styles'

export const Label = styled.label`
  font-size: ${typography.textSizeSmall};
  margin-top: 16px;
  display: block;
`

export const ErrorMessage = styled.div`
  font-size: 12px;
  margin-top: 4px;
  display: block;
  color: ${colors.red};
`

export const Input = styled(AntdInput)`
  background-color: rgba(255, 255, 255, 0);
  border-color: ${({ error }) => (error ? colors.red : '#666')};
  width: 100%;
`

export const Select = styled(AntdSelect)`
  width: 100%;

  & .ant-select-selector {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: ${({ error }) => (error ? colors.red : '#666')} !important;
    width: 100% !important;
  }
`

export const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`
