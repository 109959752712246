import React, { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, message } from 'antd'
import queryString from 'query-string'
import { Transition } from 'react-transition-group'
import useFetch from 'use-http'
import { v4 as uuidv4 } from 'uuid'
import 'react-chat-elements/dist/main.css'

import { GlobalStyles } from 'styles'
import ChatGlobalStyles from './embed-chat-global-styles'

import { ChatIcon } from './chat-icon'
import { ChatTemplate } from 'components'
import { ChatContext } from 'context/chat-context'
import { WEBSOCKET_EVENTS, IS_OK, POST_MESSAGES_ACTIONS, NOT_FRIENDS, IS_FRIENDS } from 'enums'
import { getLegacyWebsiteUrl, createAuthUrl } from 'utils'

import styles from './styles.module.scss'

const createGetURL = path => `${process.env.REACT_APP_API_URL}${path}/${createAuthUrl()}`

const EmbeddedChatPage = () => {
  const { search } = useLocation()
  const WEBSITE_URL = getLegacyWebsiteUrl()

  const {
    state: { activeChannelId, embeddedChatIsOpen, openChannels, contacts },
    chatMethods: {
      joinChannel,
      updateChatOpen,
      getUnreadMessagesLength,
      checkinToChannel,
      checkoutFromChannel,
      startChatWithContact,
      pushMessagesToChannel,
      sendMessageToChannel,
      getChannel,
      setOpenChannels,
      setContacts,
      removeClassChatFromOpenChannels,
      updateContactStatus,
      updateMessageListHeightStatus,
      joinGeneralChat,
    },
  } = useContext(ChatContext)

  const { token: authToken } = queryString.parse(search)

  const unreadMessagesTransiton = {
    entering: { opacity: 1, transform: 'scale(2) translateY(0)' },
    entered: { opacity: 1, transform: ' translateY(0)' },
    exiting: { opacity: 0, transform: 'translateY(50%)' },
    exited: { opacity: 0, transform: 'translateY(50%)' },
  }

  const {
    get: fetchContacts,
    response: contactsResponse,
    loading: contactsLoading,
    cache: contactsCache,
  } = useFetch(createGetURL('ListarContatos'), null)

  const doFetchContacts = async () => {
    const data = await fetchContacts()
    if (contactsResponse.ok) setContacts(data)
  }

  const { get: removeContact, response: removeContactResponse } = useFetch(
    createGetURL('RemoverAmigo'),
    null
  )

  const { get: addContact, response: addContactResponse } = useFetch(
    createGetURL('AdicionarAmigo'),
    null
  )

  const {
    get: fetchConversations,
    response: conversationsResponse,
    loading: conversationsLoading,
  } = useFetch(createGetURL('ListarConversas'), null)

  const { get: fetchSupportChat, response: suportChat } = useFetch(
    createGetURL('CarregaAtendimento'),
    null
  )

  const doFetchConversations = async () => {
    const support = await fetchSupportChat()
    const data = await fetchConversations()

    if (conversationsResponse.ok)
      if (!!support.chat_att_frase) {
        setOpenChannels([
          {
            channelType: 'SUPPORT',
            nome_canal: support.chat_att_frase,
            url_capa: support.chat_att_url_avatar,
            url: support.chat_att_url,
            messages: [],
            pagination: {
              messagesLeft: undefined,
              itemsPerPage: 20,
            },
          },
          ...data.map(item => ({
            ...item,
            channelType: 'CONVERSATION',
            messages: [],
            pagination: {
              messagesLeft: undefined,
              itemsPerPage: 20,
            },
          })),
        ])
      } else {
        setOpenChannels(
          data.map(item => ({
            ...item,
            channelType: 'CONVERSATION',
            messages: [],
            pagination: {
              messagesLeft: undefined,
              itemsPerPage: 20,
            },
          }))
        )
      }
  }

  const {
    get: fetchMessageList,
    loading: messageListLoading,
    response: messageListResponse,
  } = useFetch(`${process.env.REACT_APP_API_URL}ListarMensagens/${createAuthUrl()}`, null)

  const doFetchMessageList = async ({ channelId, lastMessageToken, itemsPerPage }) => {
    const data = await fetchMessageList(`/${channelId}/${lastMessageToken}/${itemsPerPage}`)

    if (messageListResponse.ok) pushMessagesToChannel(channelId, data)
  }

  const handleSendMessage = text => {
    const messagePayload = {
      tipo_chamada: WEBSOCKET_EVENTS.message,
      token_canal: activeChannelId,
      mensagem: text,
      token_mensagem: uuidv4().toUpperCase(),
    }

    sendMessageToChannel(activeChannelId, messagePayload)
  }

  const handleLoadNextMessages = () => {
    const activeChannel = getChannel(activeChannelId)

    const [lastMessageFromChannel] = activeChannel.messages.slice(-1)

    doFetchMessageList({
      channelId: activeChannel.token_canal,
      lastMessageToken: lastMessageFromChannel.token_mensagem,
      itemsPerPage: activeChannel.pagination.itemsPerPage,
    })
  }

  const handleCheckinToChannel = (channelType, channelId) => {
    if (channelType === 'CLASS_CHAT') {
      checkoutFromChannel({ authToken })
      joinChannel({
        channelId,
        authToken,
      })
    } else {
      checkinToChannel({
        channelId,
        authToken,
      })
    }
  }

  const handleCheckoutFromChannel = () => checkoutFromChannel({ authToken })

  const performContactAction = async ({ contact, fetchFunction, response, callback }) => {
    const fetchResponse = await fetchFunction(`/${contact.token_canal}`)

    if (response.ok && fetchResponse.resultado === IS_OK) {
      contactsCache.clear()
      const data = await fetchContacts()

      if (contactsResponse.ok) {
        setContacts(data)
        message.success({ content: fetchResponse.frase, duration: 1, style: { marginTop: 64 } })
        callback && callback()
      }
    } else {
      message.error({ content: fetchResponse.frase, duration: 1, style: { marginTop: 64 } })
    }
  }

  const handleRemoveContact = (contact, callback) => {
    performContactAction({
      contact,
      fetchFunction: removeContact,
      response: removeContactResponse,
      callback: () => {
        callback && callback()
        updateContactStatus(contact.token_canal, NOT_FRIENDS)
      },
    })
  }

  const handleAddContact = (contact, callback) =>
    performContactAction({
      contact,
      fetchFunction: addContact,
      response: addContactResponse,
      callback: () => {
        callback && callback()
        updateContactStatus(contact.token_canal, IS_FRIENDS)
      },
    })

  const handleStartChatWithContact = channelData => {
    startChatWithContact({
      channelId: channelData.token_canal,
      authToken,
    })
  }

  const handleStartClassChat = classChannelData => {
    const formatedChannelId = classChannelData.channelId.slice(1, -1)

    removeClassChatFromOpenChannels()

    if (!activeChannelId) {
      joinChannel({
        channelId: formatedChannelId,
        authToken,
        shouldUpdateActiveChannelId: true,
      })
    } else {
      joinChannel({
        channelId: formatedChannelId,
        authToken,
        shouldUpdateActiveChannelId: false,
      })
    }
  }

  const handlePostMessage = e => {
    if (e.origin === WEBSITE_URL) {
      if (!e.data && !e.data.isFromApp) return

      switch (e.data.action) {
        case POST_MESSAGES_ACTIONS.OPEN_CHAT:
          updateChatOpen(true)
          break

        case POST_MESSAGES_ACTIONS.CLOSE_CHAT:
          updateChatOpen(false)
          break

        case POST_MESSAGES_ACTIONS.START_CLASS_CHAT:
          handleStartClassChat(e.data.data)
          break

        case POST_MESSAGES_ACTIONS.MAXIMIZE:
          updateMessageListHeightStatus('MAXIMIZE')
          break

        case POST_MESSAGES_ACTIONS.MINIMIZE:
          updateMessageListHeightStatus('MINIMIZE')
          break

        default:
          break
      }
    }
  }

  useEffect(() => {
    const activeChannel = getChannel(activeChannelId)
    const lastReceivedMessage = activeChannel?.messages.length
      ? activeChannel.messages[0].token_mensagem
      : 0

    if (activeChannel) {
      doFetchMessageList({
        channelId: activeChannel.token_canal,
        lastMessageToken: lastReceivedMessage,
        itemsPerPage: activeChannel.pagination.itemsPerPage,
        currentPage: 1,
        shouldUpdatePages: true,
      })
    }
    // eslint-disable-next-line
  }, [activeChannelId])

  useEffect(() => {
    doFetchConversations()
    doFetchContacts()
    checkoutFromChannel({ authToken })
    joinGeneralChat({ authToken })

    window.addEventListener('message', handlePostMessage, false)

    return () => window.removeEventListener('message', () => console.info('> PS disconnected!'))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ChatGlobalStyles />
      <GlobalStyles />
      <div
        className={styles.chatIframeWrapper}
        style={embeddedChatIsOpen ? {} : { opacity: 0, pointerEvents: 'none' }}
      >
        <ChatTemplate
          handleSendMessage={handleSendMessage}
          handleLoadNextMessages={handleLoadNextMessages}
          handleCheckinToChannel={handleCheckinToChannel}
          handleStartChatWithContact={handleStartChatWithContact}
          handleRemoveContact={handleRemoveContact}
          handleAddContact={handleAddContact}
          handleCheckoutFromChannel={handleCheckoutFromChannel}
          conversationsLoading={conversationsLoading}
          conversations={openChannels}
          contactsLoading={contactsLoading}
          contacts={contacts}
          messageListLoading={messageListLoading}
        />
      </div>

      {/* {!embeddedChatIsOpen && (
        <Button
          shape="circle"
          size="large"
          type="primary"
          onClick={() => updateChatOpen(true)}
          className={styles.chatButton}
          icon={<ChatIcon />}
        >
          <Transition in={!!getUnreadMessagesLength()} timeout={50}>
            {state => (
              <span
                style={{ transform: 'translateY(50%)', ...unreadMessagesTransiton[state] }}
                className={styles.messageBadge}
              >
                {getUnreadMessagesLength() >= 99 ? 99 : getUnreadMessagesLength()}
              </span>
            )}
          </Transition>
        </Button>
      )} */}
    </>
  )
}

export default EmbeddedChatPage
