import i18next from 'i18next'
import * as React from 'react'

const I18nContext = React.createContext()

function I18nProvider({ tFunc, children }) {
  return <I18nContext.Provider value={{ t: tFunc }}>{children}</I18nContext.Provider>
}

function useI18n() {
  const context = React.useContext(I18nContext)
  if (context === undefined) {
    throw new Error('useI18n must be used within a I18nProvider')
  }
  return context
}

const i18n = i18next

export { I18nProvider, useI18n, i18n }
