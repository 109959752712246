import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import ProductImage from '../product-image'

import * as S from './styles'

const ProductVariations = ({ options, selected, onSelect }) => {
  const [_selected, setSelected] = useState(selected)

  const handleSelect = useCallback(
    selection => {
      setSelected(selection)
      onSelect(selection)
    },
    [onSelect]
  )

  return (
    <S.Container>
      <S.Label>Variações:</S.Label>
      <div>
        {options.map((option, index) => (
          <S.Button
            onClick={() => handleSelect(option.id)}
            selected={_selected === option.id}
            key={`ProductVariations-item-${option.id}-${index}`}
          >
            <ProductImage width={32} src={option.image} alt={option.name} />
          </S.Button>
        ))}
      </div>
    </S.Container>
  )
}

ProductVariations.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
}

ProductVariations.defaultProps = {}

export default ProductVariations
