import React from 'react'
import { w3cwebsocket as W3CWebSocket } from 'websocket'

import { WEBSOCKET_EVENTS } from 'enums'
import { getTokenApp } from 'utils'

const ChatContext = React.createContext()

const serialize = data => JSON.stringify(data)
const connectionErrorMessage = '> WS: Failed to execute send: still in connectiong state.'

const socketStatus = {
  NOT_CONNECTED: 'NOT_CONNECTED',
  CONNECTED: 'CONNECTED',
  RECONNECTING: 'RECONNECTING',
}

class ChatContextProvider extends React.PureComponent {
  state = {
    openChannels: [],
    contacts: [],
    activeChannelId: undefined,
    userSessionToken: undefined,
    socket: undefined,
    socketIsConnected: false,
    embeddedChatIsOpen: false,
    socketStatus: 'NOT_CONNECTED',
    offlineSocketQueue: [],
    messageListHeight: window.innerHeight - 128,
    messageListHeightStatus: 'INITIAL',
  }

  updateMessageListHeight = height => this.setState({ messageListHeight: height })

  updateMessageListHeightStatus = status => this.setState({ messageListHeightStatus: status })

  handleInitSocket = () => {
    if (!this.state.socket) {
      this.setState({ socket: new W3CWebSocket(process.env.REACT_APP_WEBSOCKET_URL) }, () => {
        const { socket } = this.state

        socket.onopen = this.onOpenSocket
        socket.onclose = this.onCloseSocket
        socket.onmessage = this.onSocketMessage
      })
    }
  }

  onOpenSocket = () => {
    const { socket, socketIsConnected, offlineSocketQueue } = this.state

    if (socket.readyState === 1 && socketIsConnected === false) {
      this.setState({ socketIsConnected: true, socketStatus: socketStatus.CONNECTED })

      if (offlineSocketQueue.length) {
        offlineSocketQueue.forEach(payload => {
          socket.send(payload)
        })
      }
    }
  }

  onCloseSocket = () => {
    this.setState({
      socketIsConnected: false,
      socket: null,
      socketStatus: socketStatus.RECONNECTING,
      openChannels: [],
    })
  }

  onSocketMessage = e => {
    const { openChannels } = this.state

    const wsEventData = JSON.parse(e.data)

    switch (wsEventData.tipo_retorno) {
      case WEBSOCKET_EVENTS.join:
        this.setState({
          openChannels: [
            {
              ...wsEventData,
              channelType: 'CLASS_CHAT',
              pagination: {
                messagesLeft: '0',
                itemsPerPage: 20,
                showEndMessage: false,
              },
              messages: [],
            },
            ...openChannels,
          ],
          userAvatar: wsEventData.url_avatar,
          userName: wsEventData.apelido,
        })
        break

      case WEBSOCKET_EVENTS.generalChat:
        this.setState({
          openChannels: [
            {
              ...wsEventData,
              channelType: 'GENERAL_CHAT',
              pagination: {
                messagesLeft: '0',
                itemsPerPage: 20,
                showEndMessage: false,
              },
              messages: [],
            },
            ...openChannels,
          ],
          userAvatar: wsEventData.url_avatar,
          userName: wsEventData.apelido,
        })
        break

      case WEBSOCKET_EVENTS.checkin:
        this.handleCheckinReturn(wsEventData)
        break

      case WEBSOCKET_EVENTS.message:
        this.receiveMessageFromChannel(wsEventData.token_canal, wsEventData)
        break

      case WEBSOCKET_EVENTS.messageConfirmation:
        this.sentMessageConfirmation(wsEventData.token_mensagem, wsEventData.token_canal)
        break

      case WEBSOCKET_EVENTS.ping:
        break

      default:
        console.error('ws: unknown messages type', e)
        break
    }
  }

  componentDidMount() {
    this.handleInitSocket()
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.socketStatus === socketStatus.CONNECTED &&
      this.state.socketStatus === socketStatus.RECONNECTING
    ) {
      this.handleInitSocket()
    }
  }

  // JoinChannel é usado para entrar em um chat de aula
  joinChannel = ({ channelId, authToken }) => {
    const joinPayload = serialize({
      tipo_chamada: WEBSOCKET_EVENTS.join,
      token_app: getTokenApp(),
      token_sessao: authToken,
      token_canal: channelId,
    })

    if (this.isAlreadyOnChannel(channelId)) {
      // Se já existe um canal aberto só abre (seta o activeChannelId)
      this.setState({ activeChannelId: channelId })
    } else {
      // Se existe canal aberto. Se conecta com o websocket e cria o canal
      if (this.state.socketIsConnected) {
        this.state.socket.send(joinPayload)
      } else {
        this.setState({ offlineSocketQueue: [...this.state.offlineSocketQueue, joinPayload] })
      }

      this.setState({
        userSessionToken: authToken,
        activeChannelId: !this.state.activeChannelId ? channelId : this.state.activeChannelId,
      })
    }

    this.readMessagesFromChannelId(channelId)
  }

  handleCheckinReturn = wsData => {
    const { openChannels } = this.state
    const hasChannel = !!this.getChannel(wsData.token_canal)

    if (!hasChannel && wsData.token_canal !== '0') {
      this.setState({
        userAvatar: wsData.url_avatar,
        userName: wsData.apelido,
        openChannels: [
          {
            ...wsData,
            channelType: 'CONVERSATION',
            pagination: {
              messagesLeft: '0',
              itemsPerPage: 20,
              showEndMessage: false,
            },
            messages: [],
          },
          ...openChannels,
        ],
      })
    } else {
      this.setState({
        userAvatar: wsData.url_avatar,
        userName: wsData.apelido,
      })
    }
  }

  // JoinChannel é usado para entrar em um chat com outras pessoas
  checkinToChannel = ({ channelId, authToken }) => {
    const checkinPayload = serialize({
      tipo_chamada: WEBSOCKET_EVENTS.checkin,
      token_app: getTokenApp(),
      token_sessao: authToken,
      token_canal: channelId,
    })

    if (this.state.socketIsConnected) {
      this.setState({ userSessionToken: authToken, activeChannelId: channelId })
      this.state.socket.send(checkinPayload)
    } else {
      console.error(connectionErrorMessage)
    }

    this.readMessagesFromChannelId(channelId)
  }

  // checkoutFromChannel é usado limpar o canal ativo no backend
  checkoutFromChannel = ({ authToken }) => {
    const checkoutPayload = serialize({
      tipo_chamada: WEBSOCKET_EVENTS.checkin,
      token_app: getTokenApp(),
      token_sessao: authToken,
      token_canal: '0',
    })

    if (this.state.socketIsConnected) {
      this.setState({ activeChannelId: null })
      this.state.socket.send(checkoutPayload)
    } else {
      this.setState({ offlineSocketQueue: [...this.state.offlineSocketQueue, checkoutPayload] })
      console.error(connectionErrorMessage)
    }
  }

  // JoinChannel é usado para entrar em um chat com outras pessoas
  joinGeneralChat = ({ authToken }) => {
    const generalChatPayload = serialize({
      tipo_chamada: WEBSOCKET_EVENTS.generalChat,
      token_app: getTokenApp(),
      token_sessao: authToken,
    })

    if (this.state.socketIsConnected) {
      this.state.socket.send(generalChatPayload)
    } else {
      this.setState({ offlineSocketQueue: [...this.state.offlineSocketQueue, generalChatPayload] })
      console.error(connectionErrorMessage)
    }
  }

  startChatWithContact = ({ channelId, authToken }) => {
    this.checkinToChannel({ channelId, authToken })
  }

  getChannel = channelId => {
    const { openChannels } = this.state

    return openChannels.find(channel => channel.token_canal === channelId)
  }

  isAlreadyOnChannel = channelId => {
    return !!this.getChannel(channelId)
  }

  userCanSendMessage = channelId => {
    const currentChannel = this.getChannel(channelId)
    const IS_A_PAID_USER = '2'

    return currentChannel?.autorizacao === IS_A_PAID_USER
  }

  receiveMessageFromChannel = (channelId, messageObject) => {
    const { openChannels, embeddedChatIsOpen, activeChannelId } = this.state
    const hasChannel = !!this.getChannel(channelId)

    if (hasChannel) {
      const updatedChannels = openChannels.map(channel => {
        if (channel.token_canal === channelId) {
          const messageIsRead = () => {
            const NOT_READ = false
            const READ = true

            if (embeddedChatIsOpen === false) {
              return NOT_READ
            }

            if (channelId !== activeChannelId) {
              return NOT_READ
            }

            return READ
          }

          return {
            ...channel,
            // TODO: Check if token_sessao is from my self
            messages: [
              {
                ...messageObject,
                isSelf: false,
                sent: true,
                read: messageIsRead(),
              },
              ...channel.messages,
            ],
          }
        }

        return channel
      })

      this.setState({ openChannels: updatedChannels })
    } else {
      const newChannel = {
        ...messageObject,
        url_capa: messageObject.url_avatar,
        nome_canal: messageObject.apelido,
        channelType: 'CONVERSATION',
        autorizacao: '2', // TODO: quando tiver adicionar contato por e-mail isso precisa vir da API
        pagination: {
          messagesLeft: '0',
          itemsPerPage: 20,
          showEndMessage: false,
        },
        messages: [
          {
            ...messageObject,
            isSelf: false,
            sent: true,
            read: false,
          },
        ],
      }

      this.setState({ openChannels: [newChannel, ...openChannels] })
    }
  }

  sendMessageToChannel = (channelId, messageObject) => {
    const { openChannels, socket, socketIsConnected, userAvatar, userName } = this.state

    if (messageObject.mensagem === '/close-ws') {
      return socket.close()
    }

    const updatedChannels = openChannels.map(channel => {
      if (channel.token_canal === channelId) {
        return {
          ...channel,
          messages: [
            {
              ...messageObject,
              isSelf: true,
              url_avatar: userAvatar,
              apelido: userName,
              data_hora: new Date(),
              sent: false,
            },
            ...channel.messages,
          ],
        }
      }

      return channel
    })

    if (socketIsConnected) {
      this.setState({ openChannels: updatedChannels })
      socket.send(serialize(messageObject))
    } else {
      console.error(connectionErrorMessage)
    }
  }

  sentMessageConfirmation = (messageId, channelId) => {
    const { openChannels } = this.state

    const updatedChannels = openChannels.map(channel => {
      if (channel.token_canal === channelId) {
        return {
          ...channel,
          messages: channel.messages.map(message => {
            if (message.token_mensagem === messageId) {
              return {
                ...message,
                sent: true,
              }
            }
            return message
          }),
        }
      }

      return channel
    })

    this.setState({ openChannels: updatedChannels })
  }

  updateUserAvatar = imageUrl => {
    this.setState({ userAvatar: imageUrl })
  }

  updateChatOpen = state => {
    this.setState({ embeddedChatIsOpen: state })
  }

  getUnreadMessagesLength = () => {
    const { openChannels } = this.state

    const unredMessages = openChannels.reduce((counter, channel) => {
      const unreadFromChannel =
        channel.messages.filter(message => message.read === false)?.length || 0

      if (channel.msgs_nao_lidas)
        return counter + unreadFromChannel + Number(channel.msgs_nao_lidas)

      return counter + unreadFromChannel
    }, 0)

    return unredMessages
  }

  getUnreadMessagesFromChannelId = channelId => {
    const passedChannel = this.getChannel(channelId)

    const unredMessages = passedChannel.messages.reduce((counter, message) => {
      if (message.read === false) return counter + 1

      return counter
    }, 0)

    return unredMessages + Number(passedChannel.msgs_nao_lidas)
  }

  readMessagesFromChannelId = channelId => {
    const { openChannels } = this.state

    const updatedChannels = openChannels.map(channel => {
      if (channel.token_canal === channelId) {
        return {
          ...channel,
          msgs_nao_lidas: '0',
          // TODO: Check if token_sessao is from my self
          messages: channel.messages.map(message => ({ ...message, read: true })),
        }
      }

      return channel
    })

    this.setState({ openChannels: updatedChannels })
  }

  pushMessagesToChannel = (channelId, data) => {
    const { openChannels } = this.state

    const updatedChannels = openChannels.map(channel => {
      if (channel.token_canal === channelId) {
        return {
          ...channel,
          pagination: {
            messagesLeft: data.saldo_mensagens,
            itemsPerPage: 20,
          },
          messages: [
            ...channel.messages,
            ...data.mensagens.map(item => ({ ...item, isSelf: item.origem_mensagem === '1' })),
          ],
        }
      }

      return channel
    })

    this.setState({ openChannels: updatedChannels })
  }

  setActiveChannelId = channelId => {
    this.setState({ activeChannelId: channelId })
  }

  setOpenChannels = channelsArray => {
    this.setState({ openChannels: [...this.state.openChannels, ...channelsArray] })
  }

  setContacts = contacts => {
    this.setState({ contacts })
  }

  removeClassChatFromOpenChannels = () => {
    this.setState({
      openChannels: this.state.openChannels.filter(item => item.channelType !== 'CLASS_CHAT'),
    })
  }

  updateContactStatus = (channelId, status) => {
    const { openChannels } = this.state

    this.setState({
      openChannels: openChannels.map(channel =>
        channel.token_canal === channelId ? { ...channel, situacao_contato: status } : channel
      ),
    })
  }

  render() {
    const { state, props } = this

    const chatMethods = {
      userCanSendMessage: this.userCanSendMessage,
      sendMessageToChannel: this.sendMessageToChannel,
      getChannel: this.getChannel,
      joinChannel: this.joinChannel,
      updateUserAvatar: this.updateUserAvatar,
      updateChatOpen: this.updateChatOpen,
      getUnreadMessagesLength: this.getUnreadMessagesLength,
      getUnreadMessagesFromChannelId: this.getUnreadMessagesFromChannelId,
      setActiveChannelId: this.setActiveChannelId,
      pushMessagesToChannel: this.pushMessagesToChannel,
      checkinToChannel: this.checkinToChannel,
      startChatWithContact: this.startChatWithContact,
      checkoutFromChannel: this.checkoutFromChannel,
      setOpenChannels: this.setOpenChannels,
      setContacts: this.setContacts,
      removeClassChatFromOpenChannels: this.removeClassChatFromOpenChannels,
      updateContactStatus: this.updateContactStatus,
      updateMessageListHeight: this.updateMessageListHeight,
      updateMessageListHeightStatus: this.updateMessageListHeightStatus,
      joinGeneralChat: this.joinGeneralChat,
    }

    return (
      <ChatContext.Provider value={{ state, chatMethods }}>{props.children}</ChatContext.Provider>
    )
  }
}

export { ChatContextProvider, ChatContext, socketStatus }
