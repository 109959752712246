import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Select, Dropdown, Button, Space } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import { MONTHS, YEARS } from 'enums'

import styles from './styles.module.scss'

const SECTIONS = [
  {
    label: 'De',
    key: 'month-picker-from-section',
    selects: [
      {
        key: 'month-picker-from-year-select',
        section: 'start',
        range: 'year',
        placeholder: 'Ano',
        options: YEARS,
      },
      {
        key: 'month-picker-from-month-select',
        section: 'start',
        range: 'month',
        placeholder: 'Mês',
        options: MONTHS,
      },
    ],
  },
  {
    label: 'Até',
    key: 'month-picker-to-section',
    selects: [
      {
        key: 'month-picker-to-year-select',
        section: 'end',
        range: 'year',
        placeholder: 'Ano',
        options: YEARS,
      },
      {
        key: 'month-picker-to-month-select',
        section: 'end',
        range: 'month',
        placeholder: 'Mês',
        options: MONTHS,
      },
    ],
  },
]

const MonthPicker = ({ onFinishSelection, initialValues }) => {
  const [selectedMonth, setSelectedMonth] = useState({
    start: initialValues.start,
    end: initialValues.end,
  })

  const handleChange = (key, value) => {
    const splitedKey = key.split('.')
    const section = splitedKey[0]
    const range = splitedKey[1]

    setSelectedMonth({ ...selectedMonth, [section]: { ...selectedMonth[section], [range]: value } })
  }

  const menu = (
    <div className={styles.menu}>
      <Space size={16}>
        {SECTIONS.map(section => (
          <Space key={section.key} direction="vertical">
            <label>{section.title}</label>

            {section.selects.map(select => (
              <Select
                key={select.key}
                value={selectedMonth[select.section][select.range]}
                className={styles.input}
                onChange={value => handleChange(`${select.section}.${select.range}`, value)}
                size="small"
                placeholder={select.placeholder}
                onClick={e => e.stopPropagation()}
              >
                {select.options.map(option => (
                  <Select.Option value={option.value}>{option.label}</Select.Option>
                ))}
              </Select>
            ))}
          </Space>
        ))}
      </Space>

      <Button
        className={styles.filterBtn}
        onClick={() => onFinishSelection(selectedMonth)}
        type="primary"
        size="small"
      >
        Filtrar
      </Button>
    </div>
  )

  return (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button size="small" type="text">
        <CalendarOutlined /> {selectedMonth.start.month}/{selectedMonth.start.year} ~{' '}
        {selectedMonth.end.month}/{selectedMonth.end.year}
      </Button>
    </Dropdown>
  )
}

MonthPicker.propTypes = {
  onFinishSelection: PropTypes.func.isRequired,
}

export default MonthPicker
