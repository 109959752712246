import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Spin, Button } from 'antd'

import { Typography } from 'antd'

import MessageBox from '../message-box'
import EmptyChatState from '../empty-chat-state'
import InfiniteScroll from '../../react-infinite-scroll'

import * as S from './styles'

const MessageList = ({
  messages,
  onSelfAvatarClick,
  activeChannel,
  messageListLoading,
  onNextMessage,
  handleStartChatWithContact,
  handleAddContact,
  handleRemoveContact,
  messageListHeight,
  updateMessageListHeight,
  messageListHeightStatus,
}) => {
  const NOT_CONTACT = '0'
  const CONTACT = '1'
  const [isContact, setIsContact] = useState(activeChannel.situacao_contato)
  const listConatainerRef = useRef()

  useEffect(() => {
    if (listConatainerRef.current) {
      setTimeout(() => {
        updateMessageListHeight(listConatainerRef.current.clientHeight)
      }, 350)
    }
    // eslint-disable-next-line
  }, [messageListHeightStatus])

  if (!messageListLoading && !messages.length)
    return (
      <EmptyChatState
        title="Nenhuma mensagem ainda."
        description="Diga alguma coisa e inicie a conversa."
      />
    )

  return (
    <S.MessageListContainer ref={listConatainerRef}>
      {isContact === NOT_CONTACT && (
        <S.NotContactBar>
          <Button
            size="small"
            type="secondary"
            block
            onClick={() =>
              handleAddContact(
                {
                  nome: activeChannel.nome_canal,
                  token_canal: activeChannel.token_canal,
                },
                () => setIsContact(CONTACT)
              )
            }
          >
            Adicionar aos contatos
          </Button>
        </S.NotContactBar>
      )}

      <S.MessageListMessagesContainer style={{ height: messageListHeight }} id="parentScroll">
        {messageListLoading && !messages.length ? (
          <S.MessageListLoader>
            <Spin />
          </S.MessageListLoader>
        ) : (
          <InfiniteScroll
            inverse={true}
            height={messageListHeight}
            scrollableTarget="parentScroll"
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            dataLength={messages.length}
            hasMore={
              activeChannel.pagination.messagesLeft && activeChannel.pagination.messagesLeft !== '0'
            }
            endMessage={
              activeChannel?.pagination?.showEndMessage && (
                <Typography.Text>Não existe mais mensagens</Typography.Text>
              )
            }
            loader={
              <S.LoadMoreWrapper>
                <S.LoadMore>
                  <Spin />
                </S.LoadMore>
              </S.LoadMoreWrapper>
            }
            next={onNextMessage}
          >
            {messages.map(message => (
              <MessageBox
                key={message.token_mensagem}
                messageToken={message.token_mensagem}
                text={decodeURIComponent(message.mensagem)}
                isSelfMessage={message.isSelf}
                title={message.isSelf ? '' : message.apelido}
                author={message.apelido}
                showSendMessageButton={!!message.token_remetente}
                senderToken={message.token_remetente || activeChannel.token_canal}
                senderType={message.tipo_remetente}
                contactStatus={message.situacao_contato}
                location={message['cidade-uf']}
                status={
                  message.sent === true ? 'received' : message.sent === false ? 'waiting' : null
                }
                messageTime={message.data_hora}
                avatar={message.url_avatar}
                onSelfAvatarClick={onSelfAvatarClick}
                handleStartChatWithContact={handleStartChatWithContact}
                handleAddContact={handleAddContact}
                handleRemoveContact={handleRemoveContact}
              />
            ))}
          </InfiniteScroll>
        )}
      </S.MessageListMessagesContainer>
    </S.MessageListContainer>
  )
}

MessageList.propTypes = {
  messages: PropTypes.instanceOf(Array).isRequired,
  onSelfAvatarClick: PropTypes.func.isRequired,
  activeChannel: PropTypes.instanceOf(Object),
  onNextMessage: PropTypes.func.isRequired,
  messageListLoading: PropTypes.bool,
}

MessageList.defaultProps = {
  activeChannel: {},
  messageListLoading: true,
}

export default MessageList
