import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'

import * as S from './styles'

const TrendInfo = ({ info, isReverse, isSmall }) => {
  const isPositive = isReverse ? info < 0 : info > 0

  const getIcon = useCallback(() => {
    if (isReverse) {
      return isPositive ? faArrowDown : faArrowUp
    }

    return isPositive ? faArrowUp : faArrowDown
  }, [info, isReverse])

  return (
    <S.Trend>
      {!isSmall && (
        <S.TrendIcon isPositive={isPositive}>
          <FontAwesomeIcon icon={getIcon()} />
        </S.TrendIcon>
      )}

      <S.TrendNumber isSmall={isSmall} isPositive={isPositive}>
        {Number(info).toFixed(2)}⠀%
      </S.TrendNumber>
    </S.Trend>
  )
}

TrendInfo.propTypes = {
  info: PropTypes.number.isRequired,
  isReverse: PropTypes.bool,
  isSmall: PropTypes.bool,
}

TrendInfo.defaultProps = {
  isReverse: false,
  isSmall: false,
}

export default TrendInfo
