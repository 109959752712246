export const initialState = {
  questionIndex: 0,
  isAble: false,
  answers: [],
  finishModal: false,
}

export const ACTIONS = {
  QUESTION_INDEX: 'QUESTION_INDEX',
  IS_ABLE: 'IS_ABLE',
  ADD_SINGLE_ANSWER: 'ADD_SINGLE_ANSWER',
  REMOVE_SINGLE_ANSWER: 'REMOVE_SINGLE_ANSWER',
  ADD_MULTIPLE_ANSWER: 'ADD_MULTIPLE_ANSWER',
  REMOVE_MULTIPLE_ANSWER: 'REMOVE_MULTIPLE_ANSWER',
  SET_FINISH_MODAL: 'SET_FINISH_MODAL',
}

const addAnswer = (state, action, type) => {
  const alreadyAnswered = state.answers.filter(item => item.id_questao === action.state.id_questao)

  if (alreadyAnswered.length === 0) {
    return {
      ...state,
      answers: [...state.answers, action.state],
    }
  }

  if (type === 'SINGLE') {
    return {
      ...state,
      answers: state.answers.map(item => {
        if (item.id_questao === action.state.id_questao) {
          return {
            ...item,
            respostas: action.state.respostas,
          }
        }

        return item
      }),
    }
  } else {
    // Multiple option question
    return {
      ...state,
      answers: state.answers.map(item => {
        if (item.id_questao === action.state.id_questao) {
          return {
            ...item,
            respostas: [...item.respostas, ...action.state.respostas],
          }
        }

        return item
      }),
    }
  }
}

const removeAnswer = (state, action) => {
  return {
    ...state,
    answers: state.answers.map(item => {
      if (item.id_questao === action.state.id_questao) {
        return {
          ...item,
          respostas: item.respostas.filter(
            stateAnswers => !action.state.respostas.includes(stateAnswers)
          ),
        }
      }

      return item
    }),
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.QUESTION_INDEX:
      return { ...state, questionIndex: action.state }

    case ACTIONS.IS_ABLE:
      return { ...state, isAble: action.state }

    case ACTIONS.ADD_SINGLE_ANSWER:
      return addAnswer(state, action, 'SINGLE')

    case ACTIONS.ADD_MULTIPLE_ANSWER:
      return addAnswer(state, action, 'MULTIPLE')

    case ACTIONS.REMOVE_SINGLE_ANSWER:
      return removeAnswer(state, action)

    case ACTIONS.REMOVE_MULTIPLE_ANSWER:
      return removeAnswer(state, action)

    case ACTIONS.SET_FINISH_MODAL:
      return { ...state, finishModal: action.state }

    default:
      return state
  }
}
