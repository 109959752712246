import React, { useState, useCallback } from 'react'

import PaymentOptionsSelect from 'components/payment-options-select'
import AddCreditCardForm from 'components/add-credit-card-form'
import BoletoOptionsSelectForm from 'components/boleto-options-select-form'
import { PAYMENT_OPTIONS_SELECT } from 'enums'

import addCardIcon from '../../../images/add_card.svg'
import boletoIcon from '../../../images/boleto.svg'

import * as S from './styles'

const STEP_THREE_TABS = {
  PAYMENT_OPTIONS: 'PAYMENT_OPTIONS',
  ADD_CREDIT_CARD: 'ADD_CREDIT_CARD',
  BOLETO: 'BOLETO',
}

const FormWizardStepThree = ({
  creditCards,
  purchaseLoading,
  selectedProduct,
  onFinishPurchase,
  onBack,
}) => {
  const [tab, setTab] = useState(STEP_THREE_TABS.PAYMENT_OPTIONS)

  const handleFinishPurchase = (paymentMethod, payload) => {
    if (paymentMethod === PAYMENT_OPTIONS_SELECT.CREDIT_CARD) {
      return onFinishPurchase(paymentMethod, payload)
    }

    if (paymentMethod === PAYMENT_OPTIONS_SELECT.BOLETO) {
      return onFinishPurchase(paymentMethod, payload)
    }
  }

  const handlePaymentOptionsSelect = (selectedOption, payload) => {
    if (selectedOption === PAYMENT_OPTIONS_SELECT.BOLETO) {
      return setTab(STEP_THREE_TABS.BOLETO)
    }

    if (selectedOption === PAYMENT_OPTIONS_SELECT.NEW_CREDIT_CARD) {
      return setTab(STEP_THREE_TABS.ADD_CREDIT_CARD)
    }

    handleFinishPurchase(PAYMENT_OPTIONS_SELECT.CREDIT_CARD, {
      id_cartao: payload.id_cartao,
      parcelas: payload.parcelas,
    })
  }

  const scrollDown = useCallback(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 99999999,
        left: 0,
        behavior: 'smooth',
      })
    }, 150)
  }, [])

  return (
    <div>
      {tab === STEP_THREE_TABS.PAYMENT_OPTIONS && (
        <>
          <S.TabTitle>Selecione uma opção para pagamento</S.TabTitle>

          <PaymentOptionsSelect
            installments={selectedProduct?.parcelas}
            creditCards={creditCards}
            onBack={onBack}
            onSubmit={handlePaymentOptionsSelect}
            loading={purchaseLoading}
            isBoletoEnabled={selectedProduct?.pgto_boleto}
            isCreditCardEnabled={selectedProduct?.pgto_cartao}
          />
        </>
      )}

      {tab === STEP_THREE_TABS.ADD_CREDIT_CARD && (
        <>
          <S.TabTitle>
            <S.TabIcon src={addCardIcon} />
            Cadastrar novo cartão de crédito
          </S.TabTitle>

          <AddCreditCardForm
            loading={purchaseLoading}
            installments={selectedProduct?.parcelas}
            submitButtonText="Finalizar compra"
            onSubmit={newCardPayload =>
              handleFinishPurchase(PAYMENT_OPTIONS_SELECT.CREDIT_CARD, newCardPayload)
            }
            onCancel={() => {
              scrollDown()
              setTab(STEP_THREE_TABS.PAYMENT_OPTIONS)
            }}
          />
        </>
      )}

      {tab === STEP_THREE_TABS.BOLETO && (
        <>
          <S.TabTitle>
            <S.TabIcon src={boletoIcon} />
            Selecione as parcelas e vencimento do boleto
          </S.TabTitle>

          <BoletoOptionsSelectForm
            loading={purchaseLoading}
            onSubmit={boletoPayload =>
              handleFinishPurchase(PAYMENT_OPTIONS_SELECT.BOLETO, boletoPayload)
            }
            onCancel={() => {
              scrollDown()
              setTab(STEP_THREE_TABS.PAYMENT_OPTIONS)
            }}
            installments={selectedProduct?.parcelasboleto}
            dueDates={selectedProduct?.vcto_boleto}
          />
        </>
      )}
    </div>
  )
}

export default FormWizardStepThree
