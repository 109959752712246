import React, { useMemo } from 'react'
import { Table, Space } from 'antd'
import { useFetch } from 'use-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'

import { createAdminGetUrl } from 'utils'
import { colors } from 'styles/variables'

const { Column } = Table

const LtvPlanoCard = () => {
  const { data, loading, error } = useFetch(createAdminGetUrl('DashMKTVendasLTVPlano'), null, [])

  const filters = useMemo(
    () => (!!data ? data.map(item => ({ text: item.plano, value: item.plano })) : []),
    [data]
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      rightComponent={<FontAwesomeIcon icon={faHistory} color={colors.primaryColor} />}
      title="LT e LTV por plano"
    >
      <Table
        size="small"
        pagination={{ defaultPageSize: 10, showSizeChanger: false }}
        locale={{ filterConfirm: 'Aplicar', filterReset: 'Limpar', emptyText: 'Sem dados' }}
        dataSource={data}
        className="fixed-row-height"
      >
        <Column
          title="Plano"
          filters={filters}
          onFilter={(value, record) => record.plano === value}
          dataIndex="plano"
          className="small-font"
          key="plano"
          width={230}
          render={value => `${value.replace(/ASSINATURA/, 'ASS.')}`}
        />

        <Column
          title="Periodicidade"
          dataIndex="periodicidade"
          align="center"
          key="periodicidade"
          sorter={(a, b) => Number(a.periodicidade) - Number(b.periodicidade)}
          render={value => (
            <MetricNumber value={Number(value)}>{({ value }) => value}</MetricNumber>
          )}
        />

        <Column
          title="Usuários Ativos"
          dataIndex="usuarios_ativos"
          align="center"
          key="usuarios_ativos"
          sorter={(a, b) => a.usuarios_ativos - b.usuarios_ativos}
          render={value => (
            <MetricNumber value={Number(value)}>{({ value }) => value}</MetricNumber>
          )}
        />

        <Column
          title="LT"
          dataIndex="lt"
          align="center"
          key="lt"
          sorter={(a, b) => a.lt - b.lt}
          render={value => (
            <MetricNumber value={Number(value)}>
              {({ formatedNumber }) => formatedNumber}
            </MetricNumber>
          )}
        />

        <Column
          title="LTV"
          dataIndex="ltv"
          align="center"
          key="ltv"
          sorter={(a, b) => a.ltv - b.ltv}
          render={value => (
            <MetricNumber prefix="R$⠀" isMoney value={Number(value)}>
              {({ formatedNumber }) => formatedNumber}
            </MetricNumber>
          )}
        />
      </Table>
    </GenericCard>
  )
}

export { LtvPlanoCard }
