import { createGlobalStyle } from 'styled-components'

import { typography, colors } from 'styles'

export const StepedBreadcrumbStyles = createGlobalStyle`
.steped-breadcrumb {
  font-family: ${typography.fontText};

  .ant-steps-item-container {
    display: flex;
    align-items: center;

    @media (max-width: 720px) {
      height: 32px;
    }
  }

  .ant-steps-item.ant-steps-item-finish {
    & .ant-steps-item-title {
      color: ${colors.green};
    }
  }

  .ant-steps-item.ant-steps-item-active .ant-steps-item-icon {
    background-color: #fff;
    border-color: #fff;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${colors.green};
    border-color: ${colors.green};
  }

  .anticon.anticon-check.ant-steps-finish-icon {
    color: ${colors.defaultBackground};

    & > svg {
      width: 14px;
    }
  }

  .ant-steps-item-icon {
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;

    & .ant-steps-icon {
      color: ${colors.defaultBackground};
    }
  }

  .ant-steps-item-title {
    font-size: ${typography.fontSize};
    font-weight: 300;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-title::after {
    background-color: rgb(255, 255, 255, 0.3);

    @media (max-with: 720px) {
      display: none;
    }
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 720px) {
    &.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
      display: flex;
      align-items: center;
    }

    &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      padding-left: 0;
    }
  }
  @media (max-width: 480px) {
    &.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
      display: flex;
      align-items: center;
    }
  }
}
`
