import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const QuestionLetter = ({ children, active }) => {
  return <S.QuestionLetter active={active}>{children}</S.QuestionLetter>
}

QuestionLetter.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
}

QuestionLetter.defaultProps = {
  active: false,
}

export default QuestionLetter
