import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { FormItem, Input, Form, Checkbox } from 'formik-antd'
import { Button, Select, Typography } from 'antd'
import InputMask from 'react-input-mask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import ReCAPTCHA from 'react-google-recaptcha'
import { PhoneInput } from 'react-international-phone'
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js'
import * as Yup from 'yup'
import { useI18n, i18n } from 'context/i18n'

import './styles.scss'

import { GENDER, PHONE_INTL_INPUT_COUNTRIES } from 'enums'

const showRecaptcha = String(process.env.REACT_APP_REGISTER_RECAPTCHA).toLowerCase() === 'true'
const askGender = String(process.env.REACT_APP_REGISTER_ASK_GENDER).toLowerCase() === 'true'

const { Option } = Select

const isPhoneValid = (phone, country) => {
  if (!isPossiblePhoneNumber(phone, country.toUpperCase())) return false

  if (!isValidPhoneNumber(phone, country.toUpperCase())) return false

  return true
}

const getPhoneValidation = (phone, country) => {
  const error = validatePhoneNumberLength(phone, country.toUpperCase())

  if (error === 'TOO_SHORT') return i18n.t('auth.phoneNumberTooShort')

  if (error === 'TOO_LONG') return i18n.t('auth.phoneNumberTooLong')

  return i18n.t('auth.phoneNumberInvalid')
}

const cleanPhoneNumber = (phone, country) => {
  let cleanedPhoneNumber = phone
    .trim()
    .replace(/\)/g, '')
    .replace(/\(/g, '')
    .replace(/-/g, '')
    .replace(/ /g, '')

  if (country === 'br') {
    cleanedPhoneNumber = cleanedPhoneNumber.replace('+55', '+')
  }

  return cleanedPhoneNumber.replace(/\+/, '')
}

const RegisterForm = ({ onSubmit, loading, policies, policiesLoading, onPhoneBlur }) => {
  const { t } = useI18n()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('auth.requireField'))
      .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, t('auth.nameRequied')),
    phone: Yup.string()
      .test('phone-check', t('auth.phoneNumberIncomplete'), value =>
        value ? value.replaceAll('_', '').length >= value.length : true
      )
      .required(t('auth.requireField')),
    password: Yup.string()
      .min(4, t('auth.passwordMinLength4'))
      .required(t('auth.passwordRequiredSignUp')),
    terms: Yup.boolean(),
    privacy: Yup.boolean(),
    tokenCap: Yup.string(),
    gender: askGender ? Yup.number().required(t('auth.requireField')) : Yup.number(),
  })

  if (policiesLoading) return t('loading')

  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
        password: '',
        gender: askGender ? undefined : 0,
        country: '',
        terms: false,
        privacy: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(
        { name, password, phone, terms, gender, privacy, tokenCap, country },
        { setFieldError }
      ) => {
        if (!isPhoneValid(phone, country))
          return setFieldError('phone', getPhoneValidation(phone, country))

        const termsCheckboxIsRequired = policies.aceite_termos === 1
        const privacyCheckboxIsRequired = policies.aceite_privacidade === 1

        if (showRecaptcha && !tokenCap) return setFieldError('tokenCap', t('auth.solveCaptcha'))
        if (termsCheckboxIsRequired && terms === false)
          return setFieldError('terms', t('auth.acceptTerms'))
        if (privacyCheckboxIsRequired && privacy === false)
          return setFieldError('privacy', t('auth.acceptPrivacy'))

        onSubmit({
          name,
          password,
          phone: cleanPhoneNumber(phone, country),
          country,
          gender,
          tokenCap,
        })
      }}
    >
      {({ values, errors, touched, setFieldValue, validateForm, setFieldError }) => {
        return (
          <Form layout="vertical">
            <Typography>{t('auth.signUpSubtitle')}</Typography>

            <FormItem
              label={t('auth.fullName')}
              name="name"
              validateStatus={touched.name && errors.name && 'error'}
              help={touched.name && errors.name}
            >
              <Input placeholder={t('auth.fullName')} name="name" type="text" />
            </FormItem>

            <FormItem
              label={t('auth.cellphone')}
              name="phone"
              validateStatus={touched.phone && errors.phone && 'error'}
              help={touched.phone && errors.phone}
              onBlur={() => onPhoneBlur(values.phone)}
            >
              <div className="phoneMaskContainer">
                <PhoneInput
                  defaultCountry="br"
                  countries={PHONE_INTL_INPUT_COUNTRIES}
                  value={values.phone}
                  onChange={(phone, country) => {
                    setFieldValue('phone', phone)
                    setFieldValue('country', country)
                  }}
                />
              </div>
            </FormItem>

            {askGender && (
              <FormItem
                label={t('auth.gender')}
                name="gender"
                validateStatus={touched.gender && errors.gender && 'error'}
                help={touched.gender && errors.gender}
              >
                <Select onChange={value => setFieldValue('gender', value)} value={values.gender}>
                  <Option value={GENDER.NOT_INFORMED}>{t('auth.notInformed')}</Option>
                  <Option value={GENDER.MALE}>{t('auth.male')}</Option>
                  <Option value={GENDER.FEMALE}>{t('auth.female')}</Option>
                </Select>
              </FormItem>
            )}

            <FormItem
              label={t('auth.setPassword')}
              name="password"
              validateStatus={touched.password && errors.password && 'error'}
              help={touched.password && errors.password}
            >
              <Input.Password
                placeholder={t('auth.setPassword')}
                type="password"
                name="password"
                iconRender={visible =>
                  visible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />
                }
              />
            </FormItem>

            {policies.aceite_termos !== 0 && policies.texto_termos !== '' && (
              <FormItem
                name="terms"
                style={{ margin: 0 }}
                validateStatus={touched.terms && errors.terms && 'error'}
                help={touched.terms && errors.terms}
              >
                <Checkbox
                  checked={values.terms}
                  onChange={e => setFieldValue('terms', e.target.checked)}
                >
                  <span dangerouslySetInnerHTML={{ __html: policies.texto_termos }} />
                </Checkbox>
              </FormItem>
            )}

            {policies.aceite_termos === 0 && policies.texto_termos && (
              <div
                style={{ marginBottom: 10 }}
                dangerouslySetInnerHTML={{ __html: policies.texto_termos }}
              />
            )}

            {policies.aceite_privacidade !== 0 && policies.texto_privacidade !== '' && (
              <FormItem
                name="terms"
                style={{ margin: 0 }}
                validateStatus={touched.privacy && errors.privacy && 'error'}
                help={touched.privacy && errors.privacy}
              >
                <Checkbox
                  checked={values.privacy}
                  onChange={e => setFieldValue('privacy', e.target.checked)}
                >
                  <span
                    style={{ marginBottom: 10 }}
                    dangerouslySetInnerHTML={{ __html: policies.texto_privacidade }}
                  />
                </Checkbox>
              </FormItem>
            )}

            {policies.aceite_privacidade === 0 && policies.texto_privacidade && (
              <div dangerouslySetInnerHTML={{ __html: policies.texto_privacidade }} />
            )}

            {showRecaptcha && (
              <FormItem
                name="terms"
                style={{ margin: 0 }}
                validateStatus={errors.tokenCap && 'error'}
                help={errors.tokenCap}
              >
                <ReCAPTCHA
                  hl="pt-BR"
                  sitekey="6Le0ZOUUAAAAAHB2yBxsMCGuhXYfVjcc_V6cmr6y"
                  onChange={tokenRecaptcha => setFieldValue('tokenCap', tokenRecaptcha)}
                />
              </FormItem>
            )}
            <div style={{ height: 16 }} />

            <Button htmlType="submit" type="primary" disabled={loading} loading={loading} block>
              {t('auth.finishSignUp')}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

RegisterForm.defaultProps = {
  loading: false,
}

export default RegisterForm
