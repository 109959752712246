import styled from 'styled-components'
import Color from 'color'

import { colors, sizes, medias } from 'styles'

const commonStyles = props => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background-color: ${props.active ? Color(colors.primaryColor).fade(0.85) : 'rgba(0, 0, 0, 0)'};
  border: 1px solid ${props.active ? colors.primaryColor : colors.lightBorderColor};
  border-radius: ${sizes.defaultBorderRadius};
`

export const LeftSideDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: ${medias.xs}) {
    align-items: flex-start;
  }
`

export const Text = styled.span`
  display: block;
  margin-left: 16px;
  line-height: 24px;
  text-align: left;
  flex: 3;
`

export const TextSecondary = styled(Text)`
  opacity: 0.75;
`

export const ResultContainer = styled.div`
  position: relative;
`

export const ResultText = styled(Text)`
  text-align: center;
  text-transform: uppercase;
  flex: 1;
  font-size: 11px;
`

export const ResultTextContainer = styled.div`
  padding: 2px;
  width: 90%;
  margin: 0 auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${({ answered, rightAnswer }) => {
    if (answered && rightAnswer) {
      return 'rgba(96, 228, 72, 0.2)'
    }

    if (rightAnswer) {
      return 'rgba(255, 255, 255, 0.12)'
    }

    if (answered) {
      return 'rgba(255, 77, 79, 0.2)'
    }
  }};

  ${ResultText} {
    color: ${({ answered, rightAnswer }) => {
      if (answered && rightAnswer) {
        return '#60E448'
      }

      if (rightAnswer) {
        return '#fff'
      }

      if (answered) {
        return '#ff4d4f'
      }

      return '#fff'
    }};
  }
`

export const AsButton = styled.button`
  ${props => commonStyles(props)}
  outline: none;
  cursor: pointer;
  transition: all ease 200ms;

  @media (min-width: 1024px) {
    &:hover {
      border-color: ${colors.primaryColor};
    }
  }
`

export const AsResult = styled.div`
  ${commonStyles}

  ${TextSecondary} {
    font-size: 14px;
  }

  border: ${({ answered, rightAnswer }) => {
    if (answered && rightAnswer) {
      return '1px solid #60E448'
    }

    if (rightAnswer) {
      return '1px solid #fff'
    }

    if (answered) {
      return '1px solid #ff4d4f'
    }

    return 0
  }};
`
