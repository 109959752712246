export const initialState = {
  isAble: false,
  answers: [],
  finishModal: false,
}

export const ACTIONS = {
  IS_ABLE: 'IS_ABLE',
  ADD_TEXT_ANSWER: 'ADD_TEXT_ANSWER',
  ADD_SINGLE_ANSWER: 'ADD_SINGLE_ANSWER',
  REMOVE_SINGLE_ANSWER: 'REMOVE_SINGLE_ANSWER',
  ADD_MULTIPLE_ANSWER: 'ADD_MULTIPLE_ANSWER',
  REMOVE_MULTIPLE_ANSWER: 'REMOVE_MULTIPLE_ANSWER',
  SET_FINISH_MODAL: 'SET_FINISH_MODAL',
}

const addTextAnswer = (state, action) => {
  const alreadyAnswered = state.answers.filter(item => item.quest === action.state.quest)

  if (alreadyAnswered.length === 0) {
    return {
      ...state,
      answers: [...state.answers, { ...action.state, resp: action.state.resp }],
    }
  }

  return {
    ...state,
    answers: state.answers.map(item => {
      if (item.quest === action.state.quest && item.resp === action.state.resp) {
        return {
          ...item,
          texto: action.state.texto,
          resp: action.state.resp,
        }
      }

      return item
    }),
  }
}

const addAnswer = (state, action, type) => {
  const alreadyAnswered = state.answers.filter(item => item.quest === action.state.quest)
  if (alreadyAnswered.length === 0) {
    return {
      ...state,
      answers: [...state.answers, action.state],
    }
  }

  if (type === 'SINGLE') {
    return {
      ...state,
      answers: state.answers.map(item => {
        if (item.quest === action.state.quest) {
          return {
            ...item,
            resp: action.state.resp,
          }
        }

        return item
      }),
    }
  } else {
    // Multiple option question
    return {
      ...state,
      answers: [...state.answers, action.state],
    }
  }
}

const removeAnswer = (state, action) => {
  return {
    ...state,
    answers: state.answers.map(item => {
      if (item.quest === action.state.quest) {
        return {
          ...item,
          resp: '',
        }
      }

      return item
    }),
  }
}

const removeMultipleAnswer = (state, action) => {
  return {
    ...state,
    answers: state.answers.filter(item => {
      if (item.quest === action.state.quest && item.resp !== action.state.resp) return item

      if (item.quest !== action.state.quest) return item

      return null
    }),
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.IS_ABLE:
      return { ...state, isAble: action.state }

    case ACTIONS.ADD_TEXT_ANSWER:
      return addTextAnswer(state, action)

    case ACTIONS.ADD_SINGLE_ANSWER:
      return addAnswer(state, action, 'SINGLE')

    case ACTIONS.ADD_MULTIPLE_ANSWER:
      return addAnswer(state, action, 'MULTIPLE')

    case ACTIONS.REMOVE_SINGLE_ANSWER:
      return removeAnswer(state, action)

    case ACTIONS.REMOVE_MULTIPLE_ANSWER:
      return removeMultipleAnswer(state, action)

    case ACTIONS.SET_FINISH_MODAL:
      return { ...state, finishModal: action.state }

    default:
      return state
  }
}
