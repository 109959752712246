import React, { useMemo, useState } from 'react'
import { useFetch } from 'use-http'
import { PieChart, Pie, Tooltip, ResponsiveContainer, Sector } from 'recharts'

import { createAdminGetUrl } from 'utils'
import GenericCard from '../../generic-card'
import DateRangePicker from '../../range-picker'
import { colors } from 'styles'

const EfetividadeNoPagamento = () => {
  const [dateRange, setDateRange] = useState({
    startDate: '01%2F01%2F1900',
    endDate: '01%2F01%2F1900',
  })

  const { data, loading, error } = useFetch(
    createAdminGetUrl(
      'DashCentroControleEfetividade',
      `${dateRange.startDate}/${dateRange.endDate}`
    ),
    null,
    [dateRange]
  )

  const pieData = useMemo(() => {
    const _rest = 100 - data?.valor
    const restValue = _rest <= 0 ? 0 : 100 - data?.valor

    return [
      { name: 'Resto', value: restValue, stroke: 0 },
      { name: 'Efetividade', value: data?.valor, fill: colors.primaryColor, stroke: 0 },
    ]
  }, [data])

  const renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, value } = props

    return (
      <g>
        <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
          {value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    )
  }

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="Efetividade no pagamento"
      rightComponent={
        <DateRangePicker
          initialValues={{ startDate: data?.inicio, endDate: data?.fim }}
          onDateSelection={setDateRange}
        />
      }
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            activeIndex={1}
            activeShape={renderActiveShape}
            dataKey="value"
            data={pieData}
            endAngle={180}
            innerRadius={60}
            outerRadius={80}
          />
          <Tooltip
            content={() => {
              return null
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </GenericCard>
  )
}

export { EfetividadeNoPagamento }
