import React from 'react'
import PropTypes from 'prop-types'

import imagePlaceholder from '../../images/images-placeholder.jpeg'

import * as S from './styles'

const ProductImage = ({ src, width, alt }) => {
  return (
    <S.ImageContainer width={width}>
      <S.Image src={src || imagePlaceholder} alt={alt} title={alt} />
    </S.ImageContainer>
  )
}

ProductImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
  alt: PropTypes.string,
}

ProductImage.defaultProps = {
  src: imagePlaceholder,
  width: '180px',
  alt: '',
}

export default ProductImage
