import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { FormItem, Input, Form } from 'formik-antd'
import { Button } from 'antd'
import * as Yup from 'yup'

import styles from './styles.module.scss'
import { useI18n } from 'context/i18n'

const EmailValidationForm = ({ onSubmit, onChangeEmail, correctEmail, loading }) => {
  const { t } = useI18n()

  const validationSchema = Yup.object({
    email: Yup.string().email(t('auth.emailValid')).required(t('auth.emailRequired')),
  })

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={({ email }) => {
        if (correctEmail) return

        onSubmit(email)
      }}
    >
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <Form layout="vertical">
            <FormItem
              label={t('auth.email')}
              name="email"
              validateStatus={touched.email && errors.email && 'error'}
              help={touched.email && errors.email}
            >
              <div className={styles.fieldContainer}>
                <Input
                  placeholder={t('auth.email')}
                  name="email"
                  type="email"
                  disabled={correctEmail}
                  className={styles.emailField}
                  value={correctEmail || values.email}
                />

                {correctEmail && (
                  <Button
                    htmlType="button"
                    type="link"
                    size="small"
                    className={styles.changeEmailButton}
                    onClick={() => {
                      setFieldValue('email', '')
                      onChangeEmail()
                    }}
                  >
                    {t('auth.changeEmail')}
                  </Button>
                )}
              </div>
            </FormItem>

            {!correctEmail && (
              <Button htmlType="submit" type="primary" disabled={loading} loading={loading} block>
                {t('auth.continue')}
              </Button>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

EmailValidationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  correctEmail: PropTypes.string,
  loading: PropTypes.bool,
}

EmailValidationForm.defaultProps = {
  correctEmail: '',
  loading: false,
}

export default EmailValidationForm
