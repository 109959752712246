import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, Select, Button } from 'antd'
import InputMask from 'react-input-mask'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import * as S from './syles'
import { removeUderline } from 'utils'
import { validateFields } from 'utils/validate-fields'

const fieldValdiations = {
  nome: Yup.string().required('Campo obrigatório'),
  celular: Yup.string()
    .test('is-celular-valid', 'Digite um celular válido', value => {
      return removeUderline(value.split(' ').join('')).length === 14
    })
    .required('Campo obrigatório'),
  cpf: Yup.string()
    .test('is-cpf-valid', 'Digite um CPF válido', value => {
      return removeUderline(value).length === 14
    })
    .required('Campo obrigatório'),
  data_nascimento: Yup.string()
    .test('is-nasc-valid', 'Digite uma data válida', value => {
      return removeUderline(value).length === 10
    })
    .required('Campo obrigatório'),
  sexo: Yup.string()
    .test('is-sexo-valid', 'Selecione uma opção', value => {
      return value !== '0'
    })
    .required('Selecione uma opção'),
}

const PersonalInfoForm = ({ onSubmit, fields = {} }) => {
  const { values, errors, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: fields,
    onSubmit: async (values, { setFieldError }) => {
      const errors = validateFields(values, fieldValdiations)

      if (errors.length) {
        errors.forEach(error => {
          setFieldError(error.field, error.message)
        })
        return
      }

      if (onSubmit) onSubmit({ ...values, paiscelular: 'Brasil' })
    },
  })

  const shouldRenderField = useCallback(
    fieldName => {
      return Object.keys(fields).includes(fieldName)
    },
    [fields]
  )

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={[16, 0]}>
        {shouldRenderField('nome') && (
          <Col span={24}>
            <S.Label>
              Nome:
              <S.Input
                size="small"
                name="nome"
                value={values.nome}
                onChange={handleChange}
                placeholder="Nome"
                error={errors.nome}
              />
            </S.Label>
            {errors.nome && <S.ErrorMessage>{errors.nome}</S.ErrorMessage>}
          </Col>
        )}

        {shouldRenderField('celular') && (
          <Col span={12}>
            <S.Label>
              Telefone:
              <InputMask
                mask="(99) 99999-9999"
                placeholder="(__) _____-____"
                name="celular"
                size="small"
                value={values.celular}
                onChange={handleChange}
              >
                {inputProps => <S.Input error={errors.celular} {...inputProps} />}
              </InputMask>
            </S.Label>
            {errors.celular && <S.ErrorMessage>{errors.celular}</S.ErrorMessage>}
          </Col>
        )}

        {shouldRenderField('cpf') && (
          <Col span={12}>
            <S.Label>
              CPF:
              <InputMask
                mask="999.999.999-99"
                placeholder="___.___.___-__"
                name="cpf"
                size="small"
                value={values.cpf}
                onChange={handleChange}
              >
                {inputProps => <S.Input error={errors.cpf} {...inputProps} />}
              </InputMask>
            </S.Label>
            {errors.cpf && <S.ErrorMessage>{errors.cpf}</S.ErrorMessage>}
          </Col>
        )}

        {shouldRenderField('data_nascimento') && (
          <Col span={12}>
            <S.Label>
              Data de nascimento:
              <InputMask
                mask="99/99/9999"
                placeholder="__/__/__"
                name="data_nascimento"
                size="small"
                value={values.data_nascimento}
                onChange={handleChange}
              >
                {inputProps => <S.Input error={errors.data_nascimento} {...inputProps} />}
              </InputMask>
            </S.Label>
            {errors.data_nascimento && <S.ErrorMessage>{errors.data_nascimento}</S.ErrorMessage>}
          </Col>
        )}

        {shouldRenderField('sexo') && (
          <Col span={12}>
            <S.Label>
              Sexo:
              <S.Select
                value={values.sexo}
                onChange={value => setFieldValue('sexo', value)}
                placeholder="Não informado"
                size="small"
                error={errors.sexo}
              >
                <Select.Option value="0">Não informado</Select.Option>
                <Select.Option value="1">Masculino</Select.Option>
                <Select.Option value="2">Feminino</Select.Option>
              </S.Select>
            </S.Label>

            {errors.sexo && <S.ErrorMessage>{errors.sexo}</S.ErrorMessage>}
          </Col>
        )}

        <Col span={24}>
          <S.Footer>
            <div></div>
            <Button type="primary" size="small" htmlType="submit">
              Continuar
            </Button>
          </S.Footer>
        </Col>
      </Row>
    </form>
  )
}

PersonalInfoForm.propTypes = {}

PersonalInfoForm.defaultProps = {}

export default PersonalInfoForm
