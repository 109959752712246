import styled from 'styled-components'

import { colors, typography, medias } from 'styles'

export const SideMenu = styled.div`
  background: rgba(255, 255, 255, 0.1);
  height: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 96px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: ${medias.md}) {
    position: static;
    height: 64px;
    min-height: 64px;
    width: 100%;
    flex-direction: row;
    padding: 12px;
  }
`

export const Logo = styled.img`
  height: 64px;
  width: 64px;

  @media (max-width: ${medias.md}) {
    height: 40px;
    width: 40px;
  }
`

export const DesktopNav = styled.div`
  display: block;

  @media (max-width: ${medias.md}) {
    display: none;
  }
`

export const MobileNav = styled.div`
  display: none;

  @media (max-width: ${medias.md}) {
    display: block;
  }
`

export const NavIcon = styled.div`
  margin-bottom: 4px;
  border-radius: 8px;
  height: 48px;
  width: 48px;
  color: ${({ active }) => (active ? '#fff' : 'rgba(255, 255, 255, 0.7)')};
  background-color: ${({ active }) => (active ? colors.primaryColor : 'rgba(255, 255, 255, 0.05)')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 200ms ease, background-color 200ms ease;
`

export const NavItemLabel = styled.div`
  text-align: center;
  font-size: 10px;
  color: ${({ active }) => (active ? '#fff' : 'rgba(255, 255, 255, 0.7)')};
  transition: color 200ms ease;
`

export const NavItem = styled.button`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  -webkit-appearance: none;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;

  &:hover {
    ${NavIcon} {
      color: #fff;
      background-color: ${({ active }) =>
        active ? colors.primaryColor : 'rgba(255, 255, 255, 0.15)'};
    }
    ${NavItemLabel} {
      color: #fff;
    }
  }
`

export const Nav = styled.nav`
  display: flex;
  margin-top: 100px;
  flex-direction: column;

  @media (max-width: ${medias.md}) {
    padding: 12px;
    margin-top: 0;
  }
`

export const ContentWrapper = styled.div`
  margin-left: 96px;

  @media (max-width: ${medias.md}) {
    margin-top: 16px;
    margin-left: 0;
  }
`

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: ${medias.md}) {
    padding: 1rem;
  }
`

export const Title = styled.h1`
  font-size: 24px;
  color: #fff;
  font-family: ${typography.fontFamily};
`

export const AvatarButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

export const MobileButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div > button {
    margin-right: 16px;
  }
`

export const Username = styled.div`
  display: inline-block;
  font-size: 12px;
  text-align: center;

  @media (max-width: ${medias.md}) {
    display: none;
  }
`
