import styled from 'styled-components'

import { typography, medias } from 'styles'

export const Label = styled.label`
  position: relative;
  display: block;
  color: rgba(255, 255, 255, 0.6);
  font-size: ${typography.textSizeSmall};
  height: ${Number(typography.textSizeSmall.split('px')[0]) * 1.6}px;
  line-height: ${Number(typography.textSizeSmall.split('px')[0]) * 1.6}px;
  margin-bottom: 4px;

  @media (max-width: ${medias.xs}) {
    font-size: 12px;
  }
`
