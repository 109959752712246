import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Row, Col, Button, Space, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import Countdown from 'react-countdown'

import { Container, BaseTemplate, Label, ResponsiveYoutube } from 'components'
import { IS_TRUE } from 'enums'

import QuestionOption from '../question-option'
import ProgressStepBar from '../progress-step-bar'
import timesupSrc from 'images/timesup-icon.png'

import * as S from './styles'

const QuestionTemplate = ({
  examTitle,
  question,
  questionAnswer,
  step,
  total,
  examStartTime,
  minutesToAnswer,
  onPreviousQuestion,
  handleAnswerQuestionButton,
  addSingleAnswer,
  removeSingleAnswer,
  addMultipleAnswer,
  removeMultipleAnswer,
  onFinishExam,
  sendAnswersLoading,
}) => {
  const [timesOverModal, setTimesOverModal] = useState(false)

  const handleAddClick = (data, isMultiple) => {
    if (isMultiple) return addMultipleAnswer(data)
    const isLastQuestion = step === total

    addSingleAnswer({ ...data, isLastQuestion })
  }

  const handleRemoveClick = (data, isMultiple) => {
    if (isMultiple) return removeMultipleAnswer(data)

    removeSingleAnswer(data)
  }

  const getActiveOption = answerId => {
    if (!questionAnswer) return false

    const activeOption = questionAnswer.respostas.find(item => {
      return item === answerId
    })

    return !!activeOption
  }

  return (
    <BaseTemplate removeHeaderAndFooter>
      <S.HeaderWrapper>
        <ProgressStepBar step={+step} total={+total} />
        <S.ExamHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>{examTitle}</div>

          <S.QuestionsLeft>
            <Label>Questões</Label>
            <S.Subtitle>
              {step} / {total}
            </S.Subtitle>
          </S.QuestionsLeft>
        </S.ExamHeader>
      </S.HeaderWrapper>
      <S.Container>
        <Container boxed>
          <Row style={{ minHeight: 450 }}>
            <Col
              xs={{ span: 24, push: 0 }}
              sm={{ span: 24, push: 0 }}
              md={{ span: 20, push: 2 }}
              lg={{ span: 18, push: 3 }}
              xl={{ span: 18, push: 3 }}
            >
              <S.QuestionHeader>
                <S.Title>
                  <span>{step}.</span>
                  {question.questao}
                </S.Title>

                {question.video && <ResponsiveYoutube videoUrl={question.video} />}

                {question.imagem && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={question.imagem}
                      style={{
                        width: '100%',
                        marginBottom: 16,
                        objectFit: 'cover',
                        height: '100%',
                      }}
                      alt="Imagem da questão"
                    />
                  </div>
                )}

                <S.QuestionHtml dangerouslySetInnerHTML={{ __html: question.detalhamento }} />

                <Typography.Text
                  style={{ fontSize: 12, marginTop: 8, display: 'block' }}
                  type="secondary"
                >
                  Peso da questão: {question.peso}
                </Typography.Text>
              </S.QuestionHeader>

              <S.OptionsGrid>
                {question.respostas.map((option, index) => (
                  <S.Option key={`question-option-${option.id_resposta}`}>
                    <QuestionOption
                      onAdd={() =>
                        handleAddClick(
                          { id_questao: question.id, respostas: [option.id_resposta] },
                          question.multiplaescolha === IS_TRUE
                        )
                      }
                      onRemove={() => {
                        handleRemoveClick(
                          { id_questao: question.id, respostas: [option.id_resposta] },
                          question.multiplaescolha === IS_TRUE
                        )
                      }}
                      active={getActiveOption(option.id_resposta)}
                      letter={index}
                    >
                      {option.resposta}
                    </QuestionOption>
                  </S.Option>
                ))}
              </S.OptionsGrid>
            </Col>
          </Row>
        </Container>
      </S.Container>
      <S.ExamFooter>
        <Button onClick={onPreviousQuestion} disabled={step === 1} size="small">
          <S.ArrowIcon>
            <FontAwesomeIcon icon={faChevronLeft} />
          </S.ArrowIcon>
          <S.TopButtonText>Questão anterior</S.TopButtonText>
        </Button>

        {Number(minutesToAnswer) > 0 && (
          <div>
            <Label>Tempo restante</Label>
            <Space>
              <FontAwesomeIcon icon={faStopwatch} />
              <Countdown
                date={examStartTime + Number(minutesToAnswer) * 60 * 1000}
                onComplete={() => setTimesOverModal(true)}
                renderer={time => (
                  <S.Subtitle style={{ width: '72px' }}>
                    {time.formatted.hours}:{time.formatted.minutes}:{time.formatted.seconds}
                  </S.Subtitle>
                )}
              />
            </Space>
          </div>
        )}

        <Button
          type={step === total ? 'primary' : 'default'}
          size="small"
          onClick={
            step === total
              ? () => onFinishExam()
              : () => {
                  window.scrollTo(0, 0)
                  handleAnswerQuestionButton()
                }
          }
          loading={sendAnswersLoading}
        >
          {step === total ? (
            <>
              Concluir<S.TopButtonText>prova</S.TopButtonText>
            </>
          ) : (
            <>
              Próxima<S.TopButtonText>questão</S.TopButtonText>
            </>
          )}
          <S.ArrowIcon>
            <FontAwesomeIcon icon={faChevronRight} />
          </S.ArrowIcon>
        </Button>
      </S.ExamFooter>

      <Modal
        title="Tempo Esgotado!"
        visible={timesOverModal}
        cancelText={null}
        closable={false}
        footer={null}
      >
        <div style={{ textAlign: 'center' }}>
          <Space direction="vertical">
            <img src={timesupSrc} style={{ width: 128 }} alt="Imagem de uma ampulheta" />
            <p>Desculpe pela interrupção. O tempo para fazer esta atividade terminou.</p>
            <Button
              type="primary"
              onClick={() => {
                setTimesOverModal(false)
                onFinishExam(true)
              }}
            >
              Ver resultado
            </Button>
          </Space>
        </div>
      </Modal>
    </BaseTemplate>
  )
}

QuestionTemplate.propTypes = {
  examTitle: PropTypes.string.isRequired,
  question: PropTypes.instanceOf(Object).isRequired,
  questionAnswer: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(undefined),
  ]),
  step: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  minutesToAnswer: PropTypes.string.isRequired,
  examStartTime: PropTypes.number.isRequired,
  onPreviousQuestion: PropTypes.func.isRequired,
  handleAnswerQuestionButton: PropTypes.func.isRequired,
  addSingleAnswer: PropTypes.func.isRequired,
  removeSingleAnswer: PropTypes.func.isRequired,
  addMultipleAnswer: PropTypes.func.isRequired,
  removeMultipleAnswer: PropTypes.func.isRequired,
  onFinishExam: PropTypes.func.isRequired,
  sendAnswersLoading: PropTypes.bool.isRequired,
}

export default QuestionTemplate
