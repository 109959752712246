import styled from 'styled-components'

import { typography } from 'styles'

export const Filters = styled.div`
  margin-bottom: 8px;
`

export const Search = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

export const SelectsRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 16px;
  justify-content: ${({ justify }) => (justify === 'flex-end' ? 'flex-end' : 'flex-start')};
  margin-top: ${({ id }) => (id === 'button' ? '16px' : 0)};

  @media screen and (max-width: 768px) {
    flex-direction: column;

    & .ant-select,
    & .ant-btn-primary {
      width: 100%;
    }
  }
`
