import React from 'react'

import { useDeletePost } from '../../../hooks/community/use-delete-post'
import { useReportPost } from '../../../hooks/community/use-report-post'
import { useCommunityStore } from '../../../stores/community'
import { Card } from '../card'
import { usePosts } from '../../../hooks/community/use-posts'

import * as S from './styles'
import { ResponseCard } from '../response-card'
import { Typography } from 'antd'
import { useSubmitPost } from '../../../hooks/community/use-submit-post'

export const PostCard = ({ data, canLike = true }) => {
  const postId = data.id_post
  const courseId = data.id_curso
  const classId = data.id_aula
  const deletePostFromStore = useCommunityStore(state => state.deletePost)

  const responses = usePosts({ courseId, postId })
  const onDelete = () => {
    deletePostFromStore(postId)
  }

  const [deletePost, { loading: deleteLoading }] = useDeletePost({ postId, onDelete })
  const [reportPost, { loading: reportLoading }] = useReportPost({ postId })
  const [submitPost, { loading: isSubmittig }] = useSubmitPost({ postId, courseId, classId })

  const showResponses = responses?.data?.length > 0
  const responseList = showResponses ? (
    <S.ReponseList>
      <Typography.Text
        style={{ marginBottom: 8, marginTop: 16, display: 'block' }}
        type="secondary"
        bold
      >
        Respostas
      </Typography.Text>
      {responses?.data.map(post => {
        return (
          <S.CardDivider key={post.id_post}>
            <ResponseCard
              data={post}
              canLike={canLike}
              parentPostId={postId}
              forceRefetchResponses={() => {
                responses.cache.clear()
                responses.get()
              }}
            />
          </S.CardDivider>
        )
      })}
    </S.ReponseList>
  ) : null

  const handleSubmitPost = async (content, clearForm) => {
    try {
      await submitPost(content)
      clearForm()
      responses.cache.clear()
      responses.get()
    } catch (e) {}
  }

  return (
    <Card
      postId={postId}
      content={data.texto}
      photo={data.foto}
      className={data.nome_aula}
      authorName={data.nome_pessoa}
      createdDate={data.data}
      likes={data.likes}
      liked={data.liked}
      responsesCount={data.respostas}
      isComment={false}
      isTeacherComment={data.professor}
      hasTeacherResponse={data.respondido_professor}
      isPostAuthor={data.autor}
      canLike={canLike}
      isDeleting={deleteLoading}
      isReporting={reportLoading}
      isSubmittigPost={isSubmittig}
      onDeletePost={deletePost}
      onReportPost={reportPost}
      onSubmitPost={handleSubmitPost}
      footer={responseList}
    />
  )
}
