import useFetch from 'use-http'
import { createPostUrl, getAuthData } from '../../utils'

export const useSubmitPost = ({ postId = '0', courseId = '0', classId = '0', originId = '0' }) => {
  const fetch = useFetch(createPostUrl(''), null)

  const submit = content =>
    fetch.post('/GravaPublicacao', {
      ...getAuthData(),
      id_post: postId,
      id_curso: courseId,
      id_aula: classId,
      origem: originId,
      texto: content,
    })

  return [submit, fetch]
}
