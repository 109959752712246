import styled from 'styled-components'
import { ChatItem as RCEChatItem } from 'react-chat-elements'
import { Button } from 'antd'
import { colors } from 'styles'

export const ChatListContainer = styled.div`
  background-color: ${colors.componentBackground};
  height: 100%;
`

export const ChatItem = styled(RCEChatItem)`
  background-color: ${colors.componentBackground};

  & .rce-citem {
    background-color: ${colors.componentBackground};
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:hover {
      background-color: #404040;
    }
  }

  & .rce-citem-body--bottom-title {
    color: rgba(255, 255, 255, 0.5);
  }

  & .rce-citem-body--bottom-status span {
    font-weight: 500;
  }
`

export const ListLoader = styled.div`
  padding: 1rem;
  opacity: 0.3;
`

export const ChatItemContainer = styled.div`
  position: relative;
`

export const MoreButton = styled(Button)`
  position: absolute;
  right: 8px;
  top: 8px;
  border: 0;
`
