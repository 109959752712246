import useFetch from 'use-http'
import { createGetUrl } from '../../utils'

export const useUserSession = () => {
  const response = useFetch(createGetUrl('ValidaSessao'), null, [])

  return response
}

export const COMMUNITY_PERMISSIONS = {
  0: 'NOT_ALLOWED',
  1: 'VIEW_ONLY',
  2: 'FULL_ACCESS',
}
