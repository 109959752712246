import React, { createContext, useState, useEffect } from 'react'
import Cookie from 'js-cookie'

export const AppContext = createContext()

const AppContextProvider = props => {
  const [user, setUser] = useState()

  useEffect(() => {
    getLocalUser()
  }, [])

  const getLocalUser = () => {
    const userType = Cookie.get('user')
    const userName = Cookie.get('userName')
    const token = Cookie.get('token')

    setUser({ userName, userType, token, isAuth: !!token })
  }

  // const logout = () => {
  //   AUTH.REMOVE_USER()

  //   window.location.reload()
  // }

  return <AppContext.Provider value={{ user }}>{props.children}</AppContext.Provider>
}

export default AppContextProvider
