import styled, { createGlobalStyle } from 'styled-components'
import { colors } from 'styles'

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.defaultBackground};
  margin-top: 48px;
`
export const OnboardingGlobalStyles = createGlobalStyle`
  .app-html {
    background-color: ${colors.defaultBackground} !important;
  }

  .app-body {
    background-color: ${colors.defaultBackground} !important;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  #root {
    height: 100%;
  }
`
