const Cookie = require('js-cookie')

export const getLegacyWebsiteUrl = () =>
  process.env.NODE_ENV !== 'development'
    ? process.env.REACT_APP_WEBSITE_URL
    : 'http://localhost:3000'

export const getUserSessionToken = () => Cookie.get('token')

export const createAuthUrl = (token = Cookie.get('token')) =>
  `${process.env.REACT_APP_TOKEN_APP}/${token || '0'}`

export const getAuthData = (token_sessao = Cookie.get('token') || '0') => ({
  token_app: process.env.REACT_APP_TOKEN_APP,
  token_sessao,
})

export const getTokenApp = () => process.env.REACT_APP_TOKEN_APP

export const createGetUrl = (path, options = '') => {
  return `${process.env.REACT_APP_API_URL}${path}/${createAuthUrl()}${options ? `/${options}` : ''}`
}

export const createPublicGetUrl = (path, options = '') => {
  return `${process.env.REACT_APP_API_URL}${path}/${getTokenApp()}${options ? `/${options}` : ''}`
}

export const createPostUrl = path => {
  return `${process.env.REACT_APP_API_POST_URL}${path}`
}

export const getShuffledArr = arr => {
  const newArr = arr.slice()
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1))
    ;[newArr[i], newArr[rand]] = [newArr[rand], newArr[i]]
  }
  return newArr
}

export const createAdminGetUrl = (path, options = '') => {
  const userSession = JSON.parse(window.localStorage.getItem('adp_admin:user_session'))

  if (!userSession) return

  return `
    ${process.env.REACT_APP_API_URL}${path}/${process.env.REACT_APP_TOKEN_APP}/${
    userSession.token
  }${options ? `/${options}` : ''}
  `
}

export const convertObjectToArray = data => {
  if (!data || !Object.keys(data).length) return []

  const dataKeys = Object.keys(data)

  return dataKeys.reduce((array, key) => [...array, { [key]: data[key] }], [])
}

export const removeUderline = value => (value ? value.replace('_', '') : '')

export function cleanPhoneNumber(phoneNumber) {
  let cleanPhone = phoneNumber.replace(/[+\(\)\s\-]/g, '')

  if (cleanPhone.startsWith('55')) cleanPhone = cleanPhone.substring(2)

  return cleanPhone
}

export function removeBrazilDDD(phoneNumber) {
  let cleanPhone = phoneNumber.replace(/\+/g, '')

  if (cleanPhone.startsWith('55')) cleanPhone = cleanPhone.substring(2)

  return cleanPhone
}
