import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import useFetch from 'use-http'

import UploadAndCrop from '../upload-and-crop'
import { getAuthData } from 'utils'

import './styles.scss'
import styles from './styles.module.scss'

const EditProfileImageModal = ({ visible, onClose, updateUserAvatar, onFinishEditAvatar }) => {
  const [avatarImage, setAvatarImage] = useState()

  const { post: saveImage, loading, response } = useFetch(
    `${process.env.REACT_APP_API_POST_URL}SalvaFotoProfile`
  )

  const handleSaveImage = async () => {
    await saveImage({
      ...getAuthData(),
      foto: avatarImage,
    })

    if (response.ok) {
      updateUserAvatar(avatarImage)
      onClose()
      onFinishEditAvatar()
      setAvatarImage(null)
    }
  }

  const handleClose = () => {
    onClose()
    onFinishEditAvatar()
  }

  return (
    <Modal
      title="Alterar imagem do perfil"
      visible={visible}
      className="profileModal"
      destroyOnClose
      closable={false}
      style={{ top: 0 }}
      footer={
        <div>
          <Button onClick={handleClose}>Fechar</Button>
          <Button
            type="primary"
            disabled={!avatarImage}
            loading={loading}
            onClick={handleSaveImage}
          >
            Salvar Foto
          </Button>
        </div>
      }
      onCancel={handleClose}
    >
      {avatarImage ? (
        <Typography.Text className={styles.cropText} type="secondary">
          Ajuste a imagem abaixo:
        </Typography.Text>
      ) : (
        <FontAwesomeIcon
          icon={faUserCircle}
          size="6x"
          style={{ margin: '0 auto', marginBottom: 16, opacity: 0.5, width: '100%' }}
        />
      )}
      <UploadAndCrop onImageChange={imageUrl => setAvatarImage(imageUrl)} />
    </Modal>
  )
}

EditProfileImageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  updateUserAvatar: PropTypes.func.isRequired,
  visible: PropTypes.bool,
}

EditProfileImageModal.defaultProps = {
  visible: false,
}

export default EditProfileImageModal
