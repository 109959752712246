import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import ProductPrice from 'components/product-price'
import ProductImage from 'components/product-image'
import ProductVariations from 'components/product-variations'
import { ResponsiveYoutube } from 'components'

import {
  ProductDescription,
  ProductTitleLarge,
  RightCol,
  Container,
  Footer,
  VideoContainer,
} from './styles'
import { Button } from 'antd'

export const ProductOffer = ({ variations, onConfirmOffer }) => {
  const [firstVariation] = variations

  const [selectedVariationId, setVariationId] = useState(firstVariation.id_variacao)

  const parsedVariations = useMemo(() => {
    return variations.map(variation => ({
      id: variation.id_variacao,
      image: variation.imagem,
      name: variation.nome_produto,
      video: variation.video,
    }))
  }, [variations])

  const selectedVariation = useMemo(() => {
    return variations.find(variation => selectedVariationId === variation.id_variacao)
  }, [variations, selectedVariationId])

  return (
    <Container>
      {selectedVariation.video ? (
        <VideoContainer>
          <ResponsiveYoutube
            videoUrl={selectedVariation.video}
            opts={{
              playerVars: { autoplay: 0, playsinline: 1, modestbranding: 1, showinfo: 0, rel: 0 },
            }}
          />
        </VideoContainer>
      ) : (
        <ProductImage src={selectedVariation?.imagem} width="400px" />
      )}

      <RightCol>
        <ProductTitleLarge>{selectedVariation?.nome_produto}</ProductTitleLarge>
        <ProductDescription
        // dangerouslySetInnerHTML={{ __html:  }}
        >
          {selectedVariation?.descricao}
        </ProductDescription>
        <ProductVariations
          onSelect={setVariationId}
          selected={selectedVariationId}
          options={parsedVariations}
        />
        <ProductPrice price={selectedVariation?.valor_produto} large />

        <Footer>
          <div></div>
          <Button size="small" onClick={() => onConfirmOffer(selectedVariation)} type="primary">
            {selectedVariation?.texto_botao}
          </Button>
        </Footer>
      </RightCol>
    </Container>
  )
}

ProductOffer.propTypes = {
  image: PropTypes.instanceOf(Array).isRequired,
  onConfirmOffer: PropTypes.func,
}

ProductOffer.defaultProps = {
  onConfirmOffer: () => {},
}

export default ProductOffer
