import styled from 'styled-components'

export const CardDivider = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
`

export const Order = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;

  & .ant-select-selector {
    min-width: 140px;
  }
`
