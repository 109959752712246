export const validateFields = (values, fieldValdiations) => {
  const errors = []

  Object.keys(values).forEach(field => {
    const schema = fieldValdiations[field]
    try {
      schema.validateSync(values[field])
    } catch (validationError) {
      errors.push({ field, message: validationError.message })
    }
  })

  return errors
}
