import styled from 'styled-components'

export const Image = styled.img`
  border-radius: 6px;
  object-fit: cover;
  width: 100%;
`

export const ImageContainer = styled.div`
  width: ${({ width }) => `${width}`};
  height: ${({ width }) => `${width}`};
`
