import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Space } from 'antd'
import { useFetch } from 'use-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye } from '@fortawesome/free-solid-svg-icons'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'
import SteppedProgress from '../../stepped-progress'

import { colors } from 'styles'
import { createAdminGetUrl } from 'utils'

const MetaDeAlunosAtivos = () => {
  const { data, loading, error } = useFetch(
    createAdminGetUrl('DashCentroControleMetaAlunosAtivos'),
    null,
    []
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="Meta de alunos ativos"
      subHeaderComponent={
        <Space size="large">
          <MetricNumber isPrimaryNumber sideLabel="Atualmente" value={data?.valoratual}>
            {({ formatedNumber }) => formatedNumber}
          </MetricNumber>
        </Space>
      }
      rightComponent={<FontAwesomeIcon icon={faBullseye} color={colors.primaryColor} />}
    >
      <SteppedProgress steps={data?.steps} />
    </GenericCard>
  )
}

export { MetaDeAlunosAtivos }
