import React from 'react'
import { Table, Typography } from 'antd'
import { useFetch } from 'use-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'
import TrendInfo from '../../trend-info'

import { colors } from 'styles'
import { createAdminGetUrl } from 'utils'

const { Column } = Table
const { Text } = Typography

const VendasLTVCard = () => {
  const { data, loading, error } = useFetch(createAdminGetUrl('DashMKTVendasLTV'), null, [])

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="LT e LTV"
      rightComponent={<FontAwesomeIcon icon={faCreditCard} color={colors.primaryColor} />}
    >
      <Table size="small" showHeader={false} pagination={false} dataSource={data}>
        <Column
          dataIndex="valor"
          key="valor"
          align="right"
          render={(value, row) => {
            const label = row.label.split(' ')[0]
            const isMoney = label === 'LTV'

            return (
              <MetricNumber isMoney={isMoney} prefix={isMoney ? 'R$ ' : ''} value={Number(value)}>
                {({ formatedNumber }) => formatedNumber}
              </MetricNumber>
            )
          }}
        />
        <Column
          dataIndex="label"
          key="label"
          render={(label, row) => {
            const isMonths = row.label.split(' ')[0] === 'LT'

            return (
              <Text type="secondary">
                {label} {isMonths ? '(meses)' : ''}{' '}
                {row.evolucao ? <TrendInfo info={row.evolucao} /> : ''}
              </Text>
            )
          }}
        />
      </Table>
    </GenericCard>
  )
}

export { VendasLTVCard }
