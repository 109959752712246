import React, { useState } from 'react'
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  format,
} from 'date-fns'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import { Dropdown, Button, Menu } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import { ptBR } from 'date-fns/locale'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker as DateRangeLibComponent, createStaticRanges } from 'react-date-range'
import { colors, medias } from 'styles'

const DesktopText = styled.span`
  display: inline-block !important;

  @media (max-width: ${medias.xs}) {
    display: none !important;
  }
`

const MobileText = styled.span`
  display: none !important;

  @media (max-width: ${medias.xs}) {
    margin-left: 8px;
    display: inline-block !important;
  }
`

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
}

const staticRanges = createStaticRanges([
  {
    label: 'Hoje',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Ontem',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },
  {
    label: 'Esta semana',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: 'Semana passada',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: 'Este mês',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: 'Mês passado',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: 'Este ano',
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfYear,
    }),
  },
  {
    label: 'Ano passado',
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    }),
  },
])

const convertToDate = dateString => {
  const [month, day, year] = decodeURIComponent(dateString).split('/')

  return new Date(`${day}/${month}/${year}`)
}

const formatToApiDate = date => {
  return encodeURIComponent(format(date, 'MM/dd/yyyy'))
}

const DateRangePicker = ({ initialValues, onDateSelection }) => {
  const [state, setState] = useState({
    startDate: convertToDate(initialValues.startDate),
    endDate: convertToDate(initialValues.endDate),
    key: 'selection',
  })

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <>
          <DateRangeLibComponent
            locale={ptBR}
            onChange={item => {
              setState(item.selection)
            }}
            moveRangeOnFirstSelection={true}
            showPreview
            staticRanges={staticRanges}
            ranges={[state]}
            direction="horizontal"
            rangeColors={[colors.primaryColor]}
            color={colors.primaryColor}
          />
          <div
            style={{
              padding: 12,
              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
              display: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: '#323232',
            }}
          >
            <Button
              size="small"
              type="primary"
              onClick={() =>
                onDateSelection({
                  startDate: formatToApiDate(state.startDate),
                  endDate: formatToApiDate(state.endDate),
                })
              }
            >
              Aplicar data
            </Button>
          </div>
        </>
      }
      placement="bottomRight"
      arrow
    >
      <Button type="text" icon={<CalendarOutlined />} size="small">
        <DesktopText>
          {format(state.startDate, 'dd/MM/yyyy')} - {format(state.endDate, 'dd/MM/yyyy')}
        </DesktopText>
        <MobileText>Período</MobileText>
      </Button>
    </Dropdown>
  )
}

DateRangePicker.propTypes = {
  initialValues: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  onDateSelection: PropTypes.func,
}

DateRangePicker.defaultProps = {
  initialValues: {
    startDate: '01%2F01%2F1900',
    endDate: '01%2F01%2F1900',
  },
  onDateSelection: () => {},
}

export default DateRangePicker
