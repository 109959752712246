import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import relativeTime from 'dayjs/plugin/relativeTime'

import * as S from './styles'

dayjs.locale('pt-br')
dayjs.extend(relativeTime)

const ChatList = ({
  conversations,
  conversationsLoading,
  onItemClick,
  getUnreadMessagesFromChannelId,
}) => {
  return (
    <S.ChatListContainer>
      {conversationsLoading ? (
        <S.ListLoader>
          <Skeleton avatar paragraph={{ rows: 1 }} active />
          <Skeleton avatar paragraph={{ rows: 1 }} active />
        </S.ListLoader>
      ) : (
        conversations.map(item => {
          if (item.channelType === 'SUPPORT') {
            return (
              <S.ChatItem
                key="support-channel-item"
                avatar={item.url_capa}
                title={item.nome_canal}
                subtitle=""
                dateString={null}
                date={null}
                unread={0}
                onClick={() => window.open(item.url, '_blank')}
              />
            )
          }

          return (
            <S.ChatItem
              key={item.token_canal}
              avatar={item.url_capa || 'https://i.imgur.com/YpSTDpo.png'}
              title={item.nome_canal}
              subtitle={
                item.messages.length
                  ? decodeURIComponent(item.messages[0].mensagem || '')
                  : decodeURIComponent(item.ult_mensagem || '')
              }
              dateString={dayjs().from(dayjs(item.data_hora), true)}
              unread={getUnreadMessagesFromChannelId(item.token_canal)}
              onClick={() => onItemClick(item.channelType, item.token_canal)}
            />
          )
        })
      )}
    </S.ChatListContainer>
  )
}

ChatList.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  getUnreadMessagesFromChannelId: PropTypes.func.isRequired,
  conversations: PropTypes.instanceOf(Array),
  conversationsLoading: PropTypes.bool,
  unreadCounter: PropTypes.number,
}

ChatList.defaultProps = {
  conversations: [],
  conversationsLoading: false,
  unreadCounter: 0,
}

export default ChatList
