import styled from 'styled-components'
import { colors } from 'styles'

export const EmptyMessageList = styled.div`
  height: ${({ height }) => `calc(100% - 64px - 64px)`};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.componentBackground};
`
