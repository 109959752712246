import useFetch from 'use-http'
import { createGetUrl } from '../../utils'

const paramFallback = '0'

export const usePosts = ({
  courseId = paramFallback,
  classId = paramFallback,
  postId = paramFallback,
  orderBy = paramFallback,
  options = null,
}) => {
  const path = [courseId, classId, postId, orderBy].join('/')

  const response = useFetch(createGetUrl('ComunidadesCarregaPosts', path), options, [
    courseId,
    classId,
    postId,
    orderBy,
  ])

  return response
}
