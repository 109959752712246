import React from 'react'

import PropTypes from 'prop-types'

import { getLegacyWebsiteUrl } from 'utils'

import StyledBrand from './styles'

const Brand = ({ cleanHeader }) => {
  if (cleanHeader) return <StyledBrand src={process.env.REACT_APP_APP_BRAND_URL} />

  return (
    <a href={getLegacyWebsiteUrl()}>
      <StyledBrand src={process.env.REACT_APP_APP_BRAND_URL} />
    </a>
  )
}

Brand.propTypes = {
  cleanHeader: PropTypes.bool,
}

Brand.defaultProps = {
  cleanHeader: false,
}

export default Brand
