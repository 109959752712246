import React from 'react'
import PropTypes from 'prop-types'
import { Steps } from 'antd'

import { StepedBreadcrumbStyles } from './styles'

const { Step } = Steps

export const STEP_STATUS = {
  initial: 'initial',
  finish: 'finish',
  wait: 'wait',
}

const StepedBreadcrumb = ({ steps, current }) => {
  return (
    <>
      <StepedBreadcrumbStyles />
      <Steps current={current} className="steped-breadcrumb">
        {steps.map((step, index) => (
          <Step
            key={`StepedBreadcrumb-item-${step.title}-${index}`}
            title={step.title}
            status={step.status !== 'initial' ? step.status : null}
          />
        ))}
      </Steps>
    </>
  )
}

StepedBreadcrumb.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      status: PropTypes.oneOf(Object.keys(STEP_STATUS)).isRequired,
    })
  ).isRequired,
  current: PropTypes.number,
}

StepedBreadcrumb.defaultProps = {
  current: 0,
}

export default StepedBreadcrumb
