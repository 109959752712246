import React from 'react'
import PropTypes from 'prop-types'

import { Label as StyledLabel } from './styles'

const Label = ({ children }) => {
  return <StyledLabel>{children}</StyledLabel>
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Label
