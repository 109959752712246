import React from 'react'
import PropTypes from 'prop-types'

const AlignedTableColumn = ({ align, width, children }) => {
  return (
    <table>
      <tbody>
        <tr>
          {React.Children.map(children, (c, index) => {
            return (
              <td width={width[index] || 100} align={align[index] || 'left'}>
                <div style={{ margin: '0 2px' }}>{c}</div>
              </td>
            )
          })}
        </tr>
      </tbody>
    </table>
  )
}

AlignedTableColumn.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.arrayOf(PropTypes.number),
}

AlignedTableColumn.defaultProps = {
  align: ['right', 'center', 'left'],
  width: [80, 80, 120],
}

export default AlignedTableColumn
