import useFetch from 'use-http'
import { getTokenApp } from '../../utils'

export const useLabels = () => {
  const response = useFetch(
    `${process.env.REACT_APP_API_URL}ComunidadesCFG/${getTokenApp()}`,
    null,
    []
  )

  return response
}
