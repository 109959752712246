import React, { useMemo, useState } from 'react'
import { Button } from 'antd'

import { PAYMENT_OPTIONS_SELECT } from 'enums'

import amex from '../../images/bandeira_amex.svg'
import elo from '../../images/bandeira_elo.svg'
import hiper from '../../images/bandeira_hiper.svg'
import visa from '../../images/bandeira_visa.svg'
import master from '../../images/bandeira_master.svg'
// import pixIcon from '../../images/pix.svg'
import addCardIcon from '../../images/add_card.svg'
import boletoIcon from '../../images/boleto.svg'
import InstallmentsSelect from '../installment-select'

import * as S from './styles'

const cards = {
  master,
  visa,
  hiper,
  elo,
  amex,
}

const IS_PRINCIPAL_CARD = '1'

const PaymentOptionsSelect = ({
  creditCards = [],
  installments = [],
  loading,
  onSubmit,
  onBack,
  isBoletoEnabled = true,
  isCreditCardEnabled = true,
}) => {
  const selectedCard = useMemo(() => {
    if (!isCreditCardEnabled) return null

    return creditCards.find(card => card.principal === IS_PRINCIPAL_CARD)
  }, [creditCards])

  const [selected, setSelected] = useState({
    id: selectedCard?.id_cartao || '',
    type: selectedCard?.id_cartao ? PAYMENT_OPTIONS_SELECT.CREDIT_CARD : '',
  })

  const [selectedInstallments, setSelectedInstallments] = useState()
  const [installmentsError, setInstallmentsError] = useState('')

  const isRegistredCreditCard = selected.type === PAYMENT_OPTIONS_SELECT.CREDIT_CARD

  const handleSelect = selected => {
    setSelected(selected)
  }

  const handleSelectInstallments = value => {
    setInstallmentsError('')
    setSelectedInstallments(value)
  }

  const handleSubmit = () => {
    if (isRegistredCreditCard && !selectedInstallments) {
      return setInstallmentsError('Selecione a quantidade de parcelas')
    }

    if (onSubmit)
      onSubmit(selected.type, { id_cartao: selected.id, parcelas: selectedInstallments })
  }

  const renderSubmitButtonText = () => {
    if (isRegistredCreditCard) {
      return 'Finalizar compra'
    }

    return 'Continuar'
  }

  const options = useMemo(() => {
    const _options = []

    if (isBoletoEnabled) {
      _options.push({
        type: PAYMENT_OPTIONS_SELECT.BOLETO,
        id: PAYMENT_OPTIONS_SELECT.BOLETO,
        icon: boletoIcon,
        label: 'Boleto',
        onClick: handleSelect,
      })
    }

    if (isCreditCardEnabled) {
      _options.push({
        type: PAYMENT_OPTIONS_SELECT.NEW_CREDIT_CARD,
        id: PAYMENT_OPTIONS_SELECT.NEW_CREDIT_CARD,
        icon: addCardIcon,
        label: 'Cadastrar cartão',
        onClick: handleSelect,
      })

      creditCards.forEach(card => {
        _options.push({
          type: PAYMENT_OPTIONS_SELECT.CREDIT_CARD,
          id: card.id_cartao,
          icon: cards[card.bandeira.toLowerCase()],
          label: `**** **** **** ${card.descricao.split(' ')[3]}`,
          onClick: handleSelect,
        })
      })
    }

    // { id: 'pix', icon: pixIcon, label: 'Pix', onClick: handleSelect },
    return _options
  }, [creditCards])

  return (
    <>
      <S.Container>
        {options.map(opt => (
          <S.ButtonCard
            selected={selected.id === opt.id}
            onClick={() => opt.onClick({ type: opt.type, id: opt.id })}
            key={`PaymentOptionsSelect-${opt.id}`}
          >
            <img src={opt.icon} />
            <S.Text>{opt.label}</S.Text>
          </S.ButtonCard>
        ))}
      </S.Container>

      {isRegistredCreditCard && (
        <div>
          <InstallmentsSelect
            onSelect={handleSelectInstallments}
            installments={installments}
            error={installmentsError}
          />
        </div>
      )}

      <S.Footer>
        {onBack ? (
          <Button onClick={onBack} size="small" disabled={loading} type="text" htmlType="button">
            Voltar
          </Button>
        ) : (
          <div></div>
        )}
        <Button type="primary" size="small" loading={loading} onClick={handleSubmit}>
          {renderSubmitButtonText()}
        </Button>
      </S.Footer>
    </>
  )
}

export default PaymentOptionsSelect
