import { create } from 'zustand'

export const useCommunityStore = create(set => ({
  posts: [],
  filteredPosts: null,
  setPosts: posts => set(() => ({ posts })),
  setFiltedPosts: filteredPosts => set(() => ({ filteredPosts })),
  deletePost: postId =>
    set(({ posts }) => ({
      posts: posts.filter(post => post.id_post !== postId),
    })),
}))
