import React from 'react'

import { Card } from '../card'
import { useDeletePost } from '../../../hooks/community/use-delete-post'
import { useReportPost } from '../../../hooks/community/use-report-post'
import { useSubmitPost } from '../../../hooks/community/use-submit-post'
import { Typography } from 'antd'

export const ResponseCard = ({ data, parentPostId, forceRefetchResponses, canLike = true }) => {
  const postId = data.id_post
  const courseId = data.id_curso
  const classId = data.id_aula
  const originId = data.origem

  const onDelete = () => {
    forceRefetchResponses()
  }

  const [deletePost, { loading: deleteLoading }] = useDeletePost({ postId, onDelete })
  const [reportPost, { loading: reportLoading }] = useReportPost({ postId })
  const [submitPost, { loading: isSubmittig }] = useSubmitPost({
    courseId,
    postId: parentPostId,
    classId,
    originId: postId,
  })

  const handleSubmitPost = async (content, clearForm) => {
    try {
      await submitPost(content)
      clearForm()
      forceRefetchResponses()
    } catch (e) {}
  }

  return (
    <Card
      postId={postId}
      content={data.texto}
      photo={data.foto}
      className={data.nome_aula}
      authorName={data.nome_pessoa}
      createdDate={data.data}
      likes={data.likes}
      liked={data.liked}
      responsesCount={data.respostas}
      isComment
      isTeacherComment={data.professor}
      hasTeacherResponse={data.respondido_professor}
      isPostAuthor={data.autor}
      canLike={canLike}
      isDeleting={deleteLoading}
      isReporting={reportLoading}
      isSubmittigPost={isSubmittig}
      onDeletePost={deletePost}
      onReportPost={reportPost}
      onSubmitPost={handleSubmitPost}
      submitInputPlaceholder={`Responda ${data.nome_pessoa}`}
      avatarSize="sm"
      mention={
        originId ? (
          <Typography.Link href={`#${originId}`}>{data.respondendo_nome} </Typography.Link>
        ) : null
      }
    />
  )
}
