import React from 'react'
import { useFetch } from 'use-http'
import { Select } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'

import { colors } from 'styles/variables'

import { createAdminGetUrl } from 'utils'
import GenericCard from '../../generic-card'
import PieChart from '../../pie-chart'

const { Option } = Select

const AlunosAtivosPorAfiliado = () => {
  const { data = {}, loading, error } = useFetch(
    createAdminGetUrl('DashMKTVendasAlunosXAfiliados'),
    null,
    []
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="Alunos ativos por afiliado"
      rightComponent={<FontAwesomeIcon icon={faChartPie} color={colors.primaryColor} />}
    >
      <PieChart
        data={!!data.afiliados ? data.afiliados : []}
        height={window.innerWidth <= 1024 ? 700 : 900}
        legendHeight={window.innerWidth <= 1024 ? 400 : 250}
        legendColWidth={300}
      />
    </GenericCard>
  )
}

export { AlunosAtivosPorAfiliado }
