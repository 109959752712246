import React from 'react'

import AndressForm from 'components/andress-form'

const FormWizardStepTwo = ({ checkout = {}, onSubmit, onBack, helpText }) => {
  return (
    <AndressForm
      initialValues={{
        endereco: checkout?.endereco,
        cep: checkout?.cep,
        cidade: checkout?.cidade,
        estado: checkout?.estado,
        end_numero: checkout?.end_numero,
        complemento: checkout?.complemento,
        bairro: checkout?.bairro,
      }}
      helpText={helpText}
      onSubmit={onSubmit}
      onBack={onBack}
    />
  )
}

export default FormWizardStepTwo
