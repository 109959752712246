import React from 'react'
import { Formik } from 'formik'
import { FormItem, Input, Form } from 'formik-antd'
import { Button, message, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import ReCAPTCHA from 'react-google-recaptcha'
import { PhoneInput } from 'react-international-phone'

import * as Yup from 'yup'
import { useI18n } from 'context/i18n'

import '../register-form/styles.scss'

import { PHONE_INTL_INPUT_COUNTRIES } from 'enums'
import useFetch from 'use-http'
import { createGetUrl } from 'utils'

const showRecaptcha = String(process.env.REACT_APP_REGISTER_RECAPTCHA).toLowerCase() === 'true'

const UpdateProfile = ({ onSuccess }) => {
  const { t } = useI18n()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('auth.requireField'))
      .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, t('auth.nameRequied')),
    email: Yup.string().email(t('auth.emailValid')).required(t('auth.emailRequired')),
    phone: Yup.string()
      .test('phone-check', t('auth.phoneNumberIncomplete'), value =>
        value ? value.replaceAll('_', '').length >= value.length : true
      )
      .required(t('auth.requireField')),
    password: Yup.string().min(4, t('auth.passwordMinLength4')),
  })

  const { loading: isLoading, data } = useFetch(createGetUrl('CarregaCadastroAluno'), [])
  const profile = data ? data[0] : {}

  const { get: updateProfile, loading: isSubmitting } = useFetch(
    createGetUrl('AtualizaCadastroSimples'),
    null
  )

  return (
    <Formik
      initialValues={{
        name: profile?.nome?.trim(),
        phone: !profile?.paiscelular
          ? `+55${profile?.celular}`
          : `+${profile?.paiscelular}${profile?.celular}`,
        email: profile?.email?.trim(),
        password: '',
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={({ name, password, email, tokenCap }, { setFieldError }) => {
        if (showRecaptcha && !tokenCap) return setFieldError('tokenCap', t('auth.solveCaptcha'))

        let payload = {
          name: '0',
          email: '0',
          password: '0',
        }

        if (name !== profile?.nome) payload.name = name
        if (email !== profile?.email) payload.email = email
        if (password) payload.password = password

        updateProfile(`/${payload.name.trim()}/${payload.email.trim()}/${payload.password.trim()}`)
          .then(() => {
            message.success(t('auth.messages.updateProfileSuccess'))
            setTimeout(() => {
              onSuccess()
            }, 1000)
          })
          .catch(e => message.error(e.message))
      }}
    >
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <Form layout="vertical">
            <FormItem
              label={t('auth.currentPhoneNumber')}
              name="phone"
              validateStatus={touched.phone && errors.phone && 'error'}
              help={touched.phone && errors.phone}
              disabled={isLoading}
            >
              <div className="phoneMaskContainer">
                <PhoneInput
                  defaultCountry="br"
                  countries={PHONE_INTL_INPUT_COUNTRIES}
                  value={values.phone}
                  disabled
                />
              </div>
            </FormItem>

            <Typography>{t('auth.updateProfileDescription')}</Typography>

            <FormItem
              label={t('auth.updateEmail')}
              name="email"
              validateStatus={touched.email && errors.email && 'error'}
              help={touched.email && errors.email}
              disabled={isLoading}
            >
              <Input placeholder={t('auth.updateEmail')} name="email" type="email" />
            </FormItem>

            <FormItem
              label={t('auth.changeName')}
              name="name"
              validateStatus={touched.name && errors.name && 'error'}
              help={touched.name && errors.name}
              disabled={isLoading}
            >
              <Input placeholder={t('auth.fullName')} name="name" type="text" />
            </FormItem>

            <FormItem
              label={t('auth.updateYourPassword')}
              name="password"
              validateStatus={touched.password && errors.password && 'error'}
              help={touched.password && errors.password}
            >
              <Input.Password
                placeholder={t('auth.updateYourPassword')}
                type="password"
                name="password"
                iconRender={visible =>
                  visible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />
                }
              />
            </FormItem>

            {showRecaptcha && (
              <FormItem
                name="terms"
                style={{ margin: 0 }}
                validateStatus={errors.tokenCap && 'error'}
                help={errors.tokenCap}
              >
                <ReCAPTCHA
                  hl="pt-BR"
                  sitekey="6Le0ZOUUAAAAAHB2yBxsMCGuhXYfVjcc_V6cmr6y"
                  onChange={tokenRecaptcha => setFieldValue('tokenCap', tokenRecaptcha)}
                />
              </FormItem>
            )}
            <div style={{ height: 16 }} />

            <Button
              htmlType="submit"
              disabled={isLoading || isSubmitting}
              loading={isLoading || isSubmitting}
              type="primary"
              block
            >
              {t('auth.updateProfile')}
            </Button>

            <Button
              style={{ marginTop: '1rem' }}
              htmlType="button"
              disabled={isLoading || isSubmitting}
              loading={isLoading || isSubmitting}
              onClick={onSuccess}
              type="ghost"
              block
            >
              Continuar sem atualizar
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UpdateProfile
