import React from 'react'
import YouTube from 'react-youtube'

import { VideoWrapper } from './styles'

const ResponsiveYoutube = ({ videoUrl, ...props }) => {
  return (
    <VideoWrapper>
      <YouTube videoId={videoUrl.split('/')[3]} {...props} />
    </VideoWrapper>
  )
}

export default ResponsiveYoutube
