import React from 'react'
import { Button } from 'antd'
import ProductImage from '../product-image'

import * as S from './styles'

import checkIcon from '../../images/check-icon.png'

const CheckoutSuccessState = ({ image, title, text, boletoUrl }) => {
  return (
    <S.Container>
      <S.ImageContainer>
        <ProductImage src={image} />
        <S.CheckIcon src={checkIcon} />
      </S.ImageContainer>
      <S.Title>{title}</S.Title>
      <S.Text dangerouslySetInnerHTML={{ __html: text }} />
      {boletoUrl && (
        <Button type="primary" onClick={() => window.open(boletoUrl)}>
          Imprimir boleto
        </Button>
      )}
    </S.Container>
  )
}

export default CheckoutSuccessState
