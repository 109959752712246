import styled from 'styled-components'

import { typography, colors } from 'styles'

export const Label = styled.label`
  color: rgba(255, 255, 255, 0.7);
  font-size: ${typography.textSizeSmall};
  height: 16px;
  line-height: 16px;
  margin-bottom: 4px;
`

export const Price = styled.span`
  font-size: ${({ large }) => (large ? '28px' : '20px')};
  color: ${colors.green};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
