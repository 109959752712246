import React, { useEffect, useReducer, useState } from 'react'
import { Spin, message } from 'antd'
import { useParams } from 'react-router-dom'
import useFetch from 'use-http'

import { OnboardingTemplate } from 'components'
import * as S from './styles'

import { initialState, reducer, ACTIONS } from './reducer'
import { POST_MESSAGES_ACTIONS } from 'enums'
import { getLegacyWebsiteUrl, createGetUrl, createPostUrl, getAuthData } from 'utils'

const OnboardingEmbed = () => {
  const params = useParams()
  const [index, setIndex] = useState(0)
  const [state, dispatchAction] = useReducer(reducer, initialState)
  const dispatch = (type, state) => dispatchAction({ type, state })

  useEffect(() => {
    document.querySelector('html.app-html').scrollTop = 0
  }, [index])

  const { loading, data = [] } = useFetch(createGetUrl('CarregaOnboarding', params.key), null, [])
  const { post, response, loading: submitLoading } = useFetch(createPostUrl('ConcluiOnboard'))

  const onNext = () => {
    setIndex(index + 1)
  }

  const onPrevious = () => {
    const futureIndex = index - 1
    setIndex(futureIndex <= 0 ? 0 : futureIndex)
  }

  const handleFinish = async () => {
    await post({
      ...getAuthData(),
      respostas: state.answers,
    })

    try {
      const url = new URL(window.location.href)
      const fbItem = url.searchParams.get('fb_item')
      const googleItem = url.searchParams.get('google_item')

      if (window.fbq && fbItem) {
        window.fbq('track', fbItem)
      }

      if (window.gtag && googleItem) {
        window.gtag('event', googleItem)
      }
    } catch (e) {
      console.error(e)
    }

    if (response.ok) {
      message.success('Concluido com sucesso!')

      setTimeout(() => {
        window.parent.postMessage(
          { action: POST_MESSAGES_ACTIONS.FINISH_ONBOARDING, isFromApp: true },
          getLegacyWebsiteUrl()
        )
      }, 1000)
    }
  }

  if (loading)
    return (
      <>
        <S.OnboardingGlobalStyles />
        <S.Center>
          <Spin />
        </S.Center>
      </>
    )

  return (
    <>
      <S.OnboardingGlobalStyles />
      <OnboardingTemplate
        answers={state.answers}
        stepIndex={index}
        data={data[index]}
        isFirstStep={index === 0}
        isLastStep={index + 1 === data.length}
        onFinish={handleFinish}
        onNext={onNext}
        onPrevious={onPrevious}
        addSingleAnswer={data => dispatch(ACTIONS.ADD_SINGLE_ANSWER, data)}
        removeSingleAnswer={data => dispatch(ACTIONS.REMOVE_SINGLE_ANSWER, data)}
        addMultipleAnswer={data => dispatch(ACTIONS.ADD_MULTIPLE_ANSWER, data)}
        removeMultipleAnswer={data => dispatch(ACTIONS.REMOVE_MULTIPLE_ANSWER, data)}
        addTextAnswer={data => dispatch(ACTIONS.ADD_TEXT_ANSWER, data)}
        submitLoading={submitLoading}
      />
    </>
  )
}

OnboardingEmbed.propTypes = {}

OnboardingEmbed.defaultProps = {}

export default OnboardingEmbed
