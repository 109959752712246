import styled from 'styled-components'

export const Html = styled.div`
  p {
    margin-bottom: 16px;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
  }
`
