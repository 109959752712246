import styled from 'styled-components'
import { MessageBox as RCEMessageBox } from 'react-chat-elements'

import { colors } from 'styles/variables'

export const StyledMessageBox = styled(RCEMessageBox)`
  margin-bottom: 13px;
  overflow: unset !important;

  .rce-mbox:not(.rce-mbox-right) {
    background: rgba(0, 0, 0, 0.35);
  }

  .rce-mbox-left-notch {
    fill: rgba(0, 0, 0, 0.35);
  }

  .rce-mbox-time {
    color: rgba(255, 255, 255, 0.6);
    bottom: -16px;
  }

  .rce-mbox-right {
    color: ${colors.defaultBackground};
  }

  .rce-mbox-right .rce-mbox-time {
    color: rgba(0, 0, 0, 0.45);
  }

  .rce-mbox-text {
    font-size: 14px;
    margin-bottom: 16px;
  }
`

export const LeftMessageContainer = styled.div`
  display: block;
  margin-left: 8px;

  & .rce-container-mbox {
    float: right;
    width: calc(100% - 32px);
  }
`

export const RightMessageContainer = styled.div`
  display: block;
  margin-right: 8px;

  & .rce-container-mbox {
    float: left;
    width: calc(100% - 32px);
  }
`
export const AvatarButton = styled.button`
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  cursor: pointer;
  height: 32px;
  width: 32px;
  padding: 0;
  border-radius: 32px;
  transition: all 200ms ease;
  margin-top: 2px;
  float: right;

  &:hover {
    box-shadow: 0 0 0 2px ${colors.primaryColor};
  }
`

export const UserProfile = styled.div`
  display: flex;
  align-items: center;
  textalign: center;
  flex-direction: column;
  margin-bottom: 8px;
  margin-bottom: 1rem;

  & h5 {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  & small {
    font-size: 11px;
  }

  & .ant-avatar {
    margin-bottom: 12px;
  }
`
