import React from 'react'
import { Table, Typography, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons'
import { useFetch } from 'use-http'

import { colors } from 'styles'
import { createAdminGetUrl } from 'utils'
import MetricNumber from '../../metric-number'
import GenericCard from '../../generic-card'
import TrendInfo from '../../trend-info'

const { Column } = Table
const { Text } = Typography

const Alunos = () => {
  const { data, loading, error } = useFetch(createAdminGetUrl('DashCentroControleAlunos'), null, [])

  return (
    <GenericCard
      error={error}
      loading={loading}
      title="Alunos"
      rightComponent={<FontAwesomeIcon icon={faUserGraduate} color={colors.primaryColor} />}
    >
      <Table size="small" showHeader={false} pagination={false} dataSource={data}>
        <Column
          dataIndex="valor"
          key="valor"
          align="right"
          render={value => (
            <MetricNumber value={value}>{({ formatedNumber }) => formatedNumber}</MetricNumber>
          )}
        />
        <Column
          dataIndex="label"
          key="label"
          render={(label, row) => (
            <>
              <Text type="secondary">
                {label} {row.evolucao ? <TrendInfo info={row.evolucao} /> : ''}
              </Text>
            </>
          )}
        />
      </Table>
    </GenericCard>
  )
}

export { Alunos }
