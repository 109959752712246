import styled from 'styled-components'

export const CardDivider = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

export const ReponseList = styled.div`
  margin-left: 32px;
`
