import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { PageView, PrivateRoute } from 'components'
import { AdminPanelContextProvider } from 'context/admin-panel-context'

import {
  AffiliatesPage,
  ReferralPage,
  NotFoundPage,
  StartExamPage,
  ExamPage,
  ExamResultPage,
  EmbeddedChatPage,
  AuthPage,
  OnboardingEmbed,
  AdminRouter,
  ProductsCheckout,
  CommunityPage,
  CommunityPostPage,
  CommunitySubmitPostPage,
  CertificatePage,
  // eslint-disable-next-line import/no-unresolved
} from 'pages'

const AppRouter = () => {
  return (
    <Router>
      <PageView />
      <Switch>
        <Route path="/embedded-chat">
          <EmbeddedChatPage />
        </Route>

        <Route path="/checkout-produto">
          <ProductsCheckout />
        </Route>

        <Route path="/onboarding-embed/:key">
          <OnboardingEmbed />
        </Route>

        <Route path="/cadastro">
          <AuthPage title="Informe seu e-mail para continuar" isRegisterUrl />
        </Route>

        <Route path="/login">
          <AuthPage title="Faça login para continuar" isRegisterUrl />
        </Route>

        <PrivateRoute path="/afiliados">
          <AffiliatesPage />
        </PrivateRoute>

        <Route path="/a/:tokenAffiliate">
          <ReferralPage />
        </Route>

        <PrivateRoute path="/atividade/:examKey" exact>
          <StartExamPage />
        </PrivateRoute>

        <PrivateRoute path="/atividade/:examKey/responder">
          <ExamPage />
        </PrivateRoute>

        <PrivateRoute path="/atividade/:examKey/resultado">
          <ExamResultPage />
        </PrivateRoute>

        <PrivateRoute path="/comunidade/:courseId/:classId" exact>
          <CommunityPage />
        </PrivateRoute>

        <PrivateRoute path="/comunidade/:courseId/:classId/enviar">
          <CommunitySubmitPostPage />
        </PrivateRoute>

        <PrivateRoute path="/comunidade/:courseId/:classId/postagem/:postId">
          <CommunityPostPage />
        </PrivateRoute>

        <Route path="/cert/:certificateKey">
          <CertificatePage />
        </Route>

        <Route path="/painel">
          <AdminPanelContextProvider>
            <AdminRouter />
          </AdminPanelContextProvider>
        </Route>

        <Route path="/404">
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  )
}

export default AppRouter
