import React, { useEffect, useState } from 'react'
import { Avatar, Popover, Typography, Space, Button } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import relativeTime from 'dayjs/plugin/relativeTime'

import * as S from './styles'
import { NOT_FRIENDS, IS_FRIENDS } from 'enums'

dayjs.locale('pt-br')
dayjs.extend(relativeTime)

const MessageBox = ({
  text,
  isSelfMessage,
  onSelfAvatarClick,
  messageTime,
  author,
  avatar,
  senderToken,
  senderType,
  contactStatus,
  location,
  handleStartChatWithContact,
  handleAddContact,
  handleRemoveContact,
  showSendMessageButton,
  ...props
}) => {
  const [isFriends, setIsFriends] = useState(contactStatus)
  const [messageUpdatedTime, setMessageTime] = useState(dayjs().from(dayjs(messageTime), true))

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageTime(dayjs().from(dayjs(messageTime), true))
    }, 1000 * 60)

    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  const sender = {
    foto: avatar,
    nome: author,
    tipo: senderType,
    token_canal: senderToken,
  }

  const PopUpContent = (
    <div>
      <S.UserProfile>
        <Avatar size={96} src={avatar}>
          {author && author[0]?.toUpperCase()}
        </Avatar>
        <Typography.Title level={5}>{author}</Typography.Title>
        <Typography>
          <small>{location}</small>
        </Typography>
      </S.UserProfile>

      <Space direction="vertical">
        {showSendMessageButton && (
          <Button type="text" size="small" onClick={() => handleStartChatWithContact(sender)}>
            Enviar mensagem
          </Button>
        )}

        {isFriends === NOT_FRIENDS ? (
          <Button
            type="secondary"
            size="small"
            onClick={() => handleAddContact(sender, () => setIsFriends(IS_FRIENDS))}
          >
            Adicionar contato
          </Button>
        ) : (
          <Button
            type="secondary"
            danger
            size="small"
            onClick={() => handleRemoveContact(sender, () => setIsFriends(NOT_FRIENDS))}
          >
            Remover contato
          </Button>
        )}
      </Space>
    </div>
  )

  const MessageBoxComponent = (
    <S.StyledMessageBox
      position={isSelfMessage ? 'right' : 'left'}
      text={decodeURIComponent(text)}
      dateString={messageUpdatedTime}
      {...props}
    />
  )

  return isSelfMessage ? (
    <S.RightMessageContainer>
      {MessageBoxComponent}
      <S.AvatarButton onClick={onSelfAvatarClick}>
        <Avatar src={avatar}>{author && author[0]?.toUpperCase()}</Avatar>
      </S.AvatarButton>
    </S.RightMessageContainer>
  ) : (
    <S.LeftMessageContainer>
      <Avatar src={avatar}>{author && author[0]?.toUpperCase()}</Avatar>
      <Popover content={PopUpContent} title={null} trigger="click">
        {MessageBoxComponent}
      </Popover>
    </S.LeftMessageContainer>
  )
}

const areEqual = (prevProps, nextProps) => {
  return prevProps.messageToken === nextProps.messageToken && prevProps.status === nextProps.status
}

export default React.memo(MessageBox, areEqual)
