import styled from 'styled-components'

import { colors, medias } from 'styles'

export const Title = styled.h2`
  font-size: 32px;
  line-height: 48px;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 700;

  & > span {
    display: inline-block;
    margin-right: 8px;
    color: ${colors.textColorSecondary};
  }

  @media (max-width: ${medias.xs}) {
    font-size: 22px;
    line-height: 32px;
  }
`

export const QuestionHeader = styled.div`
  margin-bottom: 2rem;
`

export const OptionsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
  margin-bottom: 3rem;
`

export const Option = styled.div`
  margin: 8px;
`

export const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
`
export const ExamHeader = styled.div`
  padding: 1rem;
  margin-bottom: 3rem;
  background-color: ${colors.lightBackground};
  display: flex;
  justify-content: space-between;

  .ant-btn {
    border: 0;
  }

  svg {
    color: ${colors.textColorSecondary};
  }
`

export const QuestionsLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const Subtitle = styled.div`
  font-weight: 700;
  font-size: 18px;

  @media (max-width: ${medias.xs}) {
    font-size: 16px;
  }
`

export const ExamFooter = styled.div`
  padding: 1rem;
  background-color: ${colors.lightBackground};
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99;
`

export const Container = styled.div`
  padding-top: 132px;
  padding-bottom: 132px;
`

export const TopButtonText = styled.div`
  display: inline-block;
  margin-left: 8px;

  @media (max-width: ${medias.xs}) {
    display: none;
  }
`

export const QuestionHtml = styled.div`
  b {
    font-weight: 700;
  }
`

export const ArrowIcon = styled.div`
  width: 20px;
  display: inline-block;
`
