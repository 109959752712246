import React, { useState } from 'react'

import { useAdminPanel } from 'context/admin-panel-context'

import AdminPagesTemplate from '../template'
import GridLayout from '../components/grid-layout'

const MarketingAndSellsPage = () => {
  const [context] = useAdminPanel()

  const { gridLayouts } = context

  return (
    <AdminPagesTemplate title="Marketing e vendas">
      <GridLayout id="marketingAndSells" settings={gridLayouts.marketingAndSells} />
    </AdminPagesTemplate>
  )
}

export default MarketingAndSellsPage
