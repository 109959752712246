import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Typography, Space, Button, message } from 'antd'
import { useParams } from 'react-router-dom'
import useFetch from 'use-http'
import Confetti from 'react-confetti'
import { Circle } from 'rc-progress'
import Cookie from 'js-cookie'

import RestartExamCard from './restart-exam-card'
import { Container, BaseTemplate, Label, ResponsiveYoutube } from 'components'
import { createGetUrl, getLegacyWebsiteUrl } from 'utils'
import QuestionOption from 'components/exam/question-option'
import { IS_FALSE } from 'enums'

import * as S from './styles'
import './styles.css'

const ExamResultPage = () => {
  const [animate, setAnimate] = useState()
  const [canRestartExam, setCanRestartExam] = useState()
  const [nowTimestamp, setNowTimestap] = useState()

  const { examKey } = useParams()

  const nextIndex = Cookie.get('adp:nextClassRedirect')

  useEffect(() => {
    setNowTimestap(Date.now())

    const timeout = setTimeout(() => {
      setAnimate(true)
    }, 1500)

    return () => clearTimeout(timeout)
  }, [])

  const { loading, data: result = {} } = useFetch(createGetUrl('ResultadoProva', examKey), null, [])

  const { loading: courseEndLoading, data: courseEnd } = useFetch(
    createGetUrl('ValidaFimdeCurso', result.id_curso),
    null,
    [result.id_curso]
  )

  const { loading: certificateLoading, get: getCertificate, data: certificate } = useFetch(
    createGetUrl('EmiteCertificado', result.id_curso),
    null
  )

  const getQuestionOptionState = answer => {
    if (answer.resposta_aluno && answer.resposta_correta) {
      return { answered: true, rightAnswer: true, text: 'Você acertou!' }
    }

    if (answer.resposta_aluno && answer.resposta_correta === false) {
      return { answered: true, rightAnswer: false, text: 'Você errou' }
    }

    if (answer.resposta_aluno === false && answer.resposta_correta === true) {
      return { answered: false, rightAnswer: true, text: 'Alternativa correta' }
    }

    return { answered: false, rightAnswer: false, text: '' }
  }

  const handleRedirectToNextClass = ({ slug, nextIndex }) => {
    Cookie.remove('adp:nextClassRedirect')

    if (!nextIndex) {
      return (window.location.href = `${getLegacyWebsiteUrl()}/curso/${slug}`)
    }

    window.location.href = `${getLegacyWebsiteUrl()}/curso/${slug}${
      nextIndex ? `?next=${nextIndex}` : ''
    }`
  }

  const handleCertificateClick = async () => {
    const data = await getCertificate()
    if (data && data[0]) {
      const certificate = data[0]

      if (certificate.liberado === 'SIM') {
        return window.open(certificate.url_certificado, '_blank')
      }
    }

    return message.error('Não foi possível emitir o certificado, tente novamente')
  }

  const examResultActions = () =>
    result?.resultado === '1' ? (
      <>
        {!nextIndex && !courseEndLoading && courseEnd.certificado === 1 && (
          <Button type="primary" loading={certificateLoading} onClick={handleCertificateClick}>
            Emitir certificado do curso
          </Button>
        )}
        {nextIndex && (
          <Button
            type="secondary"
            onClick={() => handleRedirectToNextClass({ slug: result?.slug_curso, nextIndex })}
          >
            {nextIndex ? 'Ir para próxima aula' : 'Voltar para o curso'}
          </Button>
        )}
      </>
    ) : (
      <RestartExamCard
        restartTime={result.tempo_refazer}
        canRestartExam={canRestartExam}
        setCanRestartExam={setCanRestartExam}
        nowTimestamp={nowTimestamp}
        examKey={result.chave_prova}
      />
    )

  return (
    <BaseTemplate>
      {result?.resultado === '1' && (
        <Confetti recycle={false} width={window.innerWidth} height={window.innerHeight} />
      )}

      <Container>
        <br />
        <br />
        <Typography.Title>Resultado da atividade</Typography.Title>
        <br />
        <br />

        <Row gutter={32}>
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <S.LeftCol>
              {loading ? (
                <Card loading></Card>
              ) : (
                <>
                  <Typography.Text>{result?.titulo}</Typography.Text>

                  <Typography.Title level={4} style={{ marginTop: 8 }}>
                    {result?.tituloresultado}
                  </Typography.Title>

                  <br />
                  <Space direction="vertical" size="large">
                    <S.CircleContainer>
                      <S.YourGrade>
                        <Label>Sua nota</Label>
                        <Typography.Text style={{ fontSize: 40 }}>{result?.nota}</Typography.Text>
                      </S.YourGrade>

                      <Circle
                        percent={animate ? (result?.nota / 10) * 100 : 0}
                        gapDegree="115"
                        gapPosition="bottom"
                        trailWidth="8"
                        strokeWidth="8"
                        strokeColor={result?.resultado === '1' ? '#60E448' : '#ff4d4f'}
                      />
                    </S.CircleContainer>

                    <div>
                      {result?.resultado !== IS_FALSE && <Label>Resultado</Label>}

                      {result?.resultado === '2' && (
                        <Typography.Text type="danger">{result.frase}</Typography.Text>
                      )}
                      {result?.resultado === '1' && (
                        <Typography.Text style={{ color: '#60E448' }}>
                          {result.frase}
                        </Typography.Text>
                      )}
                    </div>

                    {examResultActions()}

                    <Button
                      type="link"
                      onClick={() =>
                        handleRedirectToNextClass({ slug: result?.slug_curso, nextIndex })
                      }
                    >
                      Voltar para o curso
                    </Button>
                  </Space>
                </>
              )}
            </S.LeftCol>
          </Col>
          <Col xs={24} sm={24} md={24} lg={18} xl={18}>
            {loading ? (
              <Card loading></Card>
            ) : (
              <>
                <div style={{ minHeight: '60vh' }}>
                  {result?.resultado === IS_FALSE ? (
                    <Card style={{ textAlign: 'center' }}>
                      <Label>Resultado:</Label>
                      <Typography.Text>{result.frase}</Typography.Text>
                    </Card>
                  ) : (
                    <>
                      <Space direction="vertical" size="large">
                        {result?.questoes?.length &&
                          result.questoes.map((question, index) => (
                            <Card key={`question-result-${question.idquestao}`}>
                              <S.Title>
                                <span>{index + 1}.</span>
                                {question.questao}
                              </S.Title>

                              {question.video && <ResponsiveYoutube videoUrl={question.video} />}

                              {question.imagem && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <img
                                    src={question.imagem}
                                    style={{
                                      width: '100%',
                                      marginBottom: 16,
                                      objectFit: 'cover',
                                      height: '100%',
                                    }}
                                    alt="Imagem da questão"
                                  />
                                </div>
                              )}
                              <S.QuestionHtml
                                dangerouslySetInnerHTML={{ __html: question.detalhamento }}
                              />
                              <br />
                              <br />
                              <br />
                              <Space direction="vertical" size="middle">
                                {question.respostas.map((answer, index) => {
                                  const questionState = getQuestionOptionState(answer)

                                  return (
                                    <QuestionOption
                                      type="result"
                                      letter={index}
                                      rightAnswer={questionState.rightAnswer}
                                      answered={questionState.answered}
                                      text={questionState.text}
                                    >
                                      {answer.resposta}
                                    </QuestionOption>
                                  )
                                })}
                              </Space>
                              <br />
                              <br />
                              {question.video_explicacao && (
                                <>
                                  <Typography.Text strong>Explicação:</Typography.Text>

                                  <ResponsiveYoutube videoUrl={question.video_explicacao} />
                                </>
                              )}

                              <S.QuestionHtml
                                style={{ opacity: 0.75 }}
                                dangerouslySetInnerHTML={{ __html: question.explicacao }}
                              />
                            </Card>
                          ))}
                      </Space>
                    </>
                  )}
                </div>
                <br />
                <br />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </BaseTemplate>
  )
}

export default ExamResultPage
