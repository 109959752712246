import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyCheckAlt, faThLarge } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useHistory } from 'react-router-dom'
import { Menu, Dropdown, Button, Avatar } from 'antd'
import { AppstoreFilled } from '@ant-design/icons'
import useFetch from 'use-http'

import { useAdminPanel, ACTIONS, DEFAULT_GRID_LAYOUTS } from 'context/admin-panel-context'

import logoSrc from '../../../images/logo-painel-square.png'
import * as S from './styles'

const postUrl = `
  ${process.env.REACT_APP_API_POST_URL}DashGravaLayout
`

export const PAGES = {
  dash1: { url: '/painel', icon: faThLarge, title: 'Centro de controle' },
  dash2: { url: '/painel/marketing-e-vendas', icon: faMoneyCheckAlt, title: 'Marketing e vendas' },
}

const AdminPagesTemplate = ({ title, children }) => {
  const [{ user, permissions }, dispatch] = useAdminPanel()
  const { pathname } = useLocation()
  const history = useHistory()
  const { post } = useFetch(postUrl, null, [])

  const userSession = JSON.parse(window.localStorage.getItem('adp_admin:user_session'))

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          if (userSession) {
            post({
              token_app: process.env.REACT_APP_TOKEN_APP,
              token_sessao: userSession.token,
              layout: '',
            })
          }

          dispatch({ type: ACTIONS.SAVE_LAYOUT, payload: JSON.stringify(DEFAULT_GRID_LAYOUTS) })
        }}
      >
        Redefinir layout
      </Menu.Item>
      <Menu.Item onClick={() => dispatch({ type: ACTIONS.REMOVE_USER })}>Deslogar</Menu.Item>
    </Menu>
  )

  const nav = (
    <S.Nav>
      {!!permissions &&
        permissions.map(allowedPage => {
          const page = PAGES[allowedPage]

          if (!page) return ''

          return (
            <S.NavItem
              key={`nav-item-${page.url}`}
              active={pathname === page.url}
              onClick={() => history.push(page.url)}
            >
              <S.NavIcon active={pathname === page.url}>
                <FontAwesomeIcon icon={page.icon} size="lg" />
              </S.NavIcon>
              <S.NavItemLabel active={pathname === page.url}>{page.title}</S.NavItemLabel>
            </S.NavItem>
          )
        })}
    </S.Nav>
  )

  return (
    <>
      <S.SideMenu>
        <div>
          <S.Logo src={logoSrc} />

          <S.DesktopNav>{nav}</S.DesktopNav>
        </div>

        <S.MobileButtons>
          <S.MobileNav>
            <Dropdown trigger={['click']} overlay={<Menu>{nav}</Menu>} placement="topLeft" arrow>
              <Button type="primary" size="small" icon={<AppstoreFilled />}>
                Módulos
              </Button>
            </Dropdown>
          </S.MobileNav>

          <Dropdown trigger={['click']} overlay={menu} placement="topLeft" arrow>
            <S.AvatarButton>
              <Avatar src={user?.foto}>{user && user.nome && user.nome[0]}</Avatar>
              <S.Username>{user?.nome}</S.Username>
            </S.AvatarButton>
          </Dropdown>
        </S.MobileButtons>
      </S.SideMenu>

      <S.ContentWrapper>
        <S.Content>
          <S.Title>{title}</S.Title>
          {children}
        </S.Content>
      </S.ContentWrapper>
    </>
  )
}

AdminPagesTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

AdminPagesTemplate.defaultProps = {}

export default AdminPagesTemplate
