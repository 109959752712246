import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import MetricNumber from '../metric-number'

import * as S from './styles'

const SteppedProgress = ({ steps, reverseColorsRange, ghostProgress }) => {
  return (
    <S.Container>
      <S.StepsContainer>
        {steps.map((step, index) => {
          return (
            <React.Fragment key={`steps-${step}-${index}`}>
              <S.Step isLastStep={steps.length === index + 1}>
                <S.StepNumber>
                  <MetricNumber value={step.valor}>
                    {({ formatedNumber, value }) =>
                      typeof step.valor === 'number' ? formatedNumber : value
                    }
                  </MetricNumber>
                </S.StepNumber>
                <S.StepFill
                  reverseColorsRange={reverseColorsRange}
                  percent={step.perc_completado}
                  stepIndex={index}
                />
              </S.Step>
              {index === 0 && ghostProgress && ghostProgress < 100 && (
                <S.StepFill
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    position: 'absolute',
                    left: 0,
                    zIndex: 1,
                  }}
                  percent={ghostProgress}
                />
              )}
            </React.Fragment>
          )
        })}
      </S.StepsContainer>
    </S.Container>
  )
}

SteppedProgress.propTypes = {
  steps: PropTypes.instanceOf(Array),
  reverseColorsRange: PropTypes.bool,
}

SteppedProgress.defaultProps = {
  steps: [],
  reverseColorsRange: false,
}

export default SteppedProgress
