import React from 'react'
import PropTypes from 'prop-types'

import QuestionLetter from '../question-letter'
import { ABC_ARRAY } from 'enums'

import * as S from './styles'

const BOXES = ['button', 'result']

const QuestionOption = ({
  type,
  children,
  text,
  letter,
  onAdd,
  onRemove,
  active,
  onClick,
  answered,
  rightAnswer,
  ...props
}) => {
  const handleClick = () => {
    if (active === true) {
      onRemove()
    }

    if (active === false) {
      onAdd()
    }
  }

  return type === 'button' ? (
    <S.AsButton onClick={handleClick} active={active} {...props}>
      <S.LeftSideDiv>
        <QuestionLetter active={active}>{ABC_ARRAY[letter]}</QuestionLetter>
        <S.Text>{children}</S.Text>
        {text && <S.Text>{text}</S.Text>}
      </S.LeftSideDiv>
    </S.AsButton>
  ) : (
    <S.ResultContainer>
      <S.AsResult active={active} answered={answered} rightAnswer={rightAnswer}>
        <S.LeftSideDiv>
          <QuestionLetter active={active}>{ABC_ARRAY[letter]}</QuestionLetter>
          <S.TextSecondary>{children}</S.TextSecondary>
        </S.LeftSideDiv>
      </S.AsResult>
      {text && (
        <S.ResultTextContainer answered={answered} rightAnswer={rightAnswer}>
          <S.ResultText>{text}</S.ResultText>
        </S.ResultTextContainer>
      )}
    </S.ResultContainer>
  )
}

QuestionOption.propTypes = {
  letter: PropTypes.number.isRequired,
  text: PropTypes.string,
  type: PropTypes.oneOf(BOXES),
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

QuestionOption.defaultProps = {
  type: 'button',
  text: '',
  active: false,
  onClick: () => {},
}

export default QuestionOption
