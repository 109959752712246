import React, { useState } from 'react'

import { useAdminPanel } from 'context/admin-panel-context'

import AdminPagesTemplate from '../template'
import GridLayout from '../components/grid-layout'

const ControlCenterPage = () => {
  const [context] = useAdminPanel()

  const { gridLayouts } = context

  return (
    <AdminPagesTemplate title="Centro de controle">
      <GridLayout id="controlCenter" settings={gridLayouts.controlCenter} />
    </AdminPagesTemplate>
  )
}

export default ControlCenterPage
