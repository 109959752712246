import { message } from 'antd'
import useFetch from 'use-http'
import { createPostUrl, getAuthData } from '../../utils'

export const useDeletePost = ({ postId, onDelete }) => {
  const fetch = useFetch(createPostUrl(''), null)

  const submit = async () => {
    try {
      await fetch.post('/ExcluiPublicacao', {
        ...getAuthData(),
        id_post: postId,
      })

      message.success('Postagem deletada com sucesso!')
      if (typeof onDelete === 'function') {
        onDelete()
      }
    } catch (e) {
      message.error('Ocorreu um erro ao tentar deletar a postagem')
    }
  }

  return [submit, fetch]
}
