import styled from 'styled-components'

import { medias } from 'styles'

const StyledBrand = styled.img`
  height: 50px;
  max-width: 100%;

  @media (max-width: ${medias.xs}) {
    height: 32px;
  }
`

export default StyledBrand
