import styled from 'styled-components'
import { colors } from 'styles'

export const MessageListContainer = styled.div`
  position: relative;
  display: flex;
  background-color: ${colors.componentBackground};
  height: calc(100% - 64px - 64px);
`

export const MessageListMessagesContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  background-color: ${colors.componentBackground};
  width: 100%;
`

export const MessageListLoader = styled.div`
  display: block;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.componentBackground};
`

export const LoadMoreWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  z-index: 99;
`

export const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  box-shadow: 0 0 8px 8px rgba(0, 0, 0, 0.2);
  padding-top: 4px;
`

export const NotContactBar = styled.div`
  padding: 8px;
  background-color: #464646;
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
`
