import React from 'react'
import { Modal, Typography, Space, Button } from 'antd'

import { IS_TRUE } from 'enums'

const ACCOUNT_TYPES = {
  1: 'Conta poupança',
  0: 'Conta corrente',
}

const AfiliateWithdrawModal = ({ title, visible, data, onClose, openBankTab, onOk }) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      footer={
        data.tem_conta === IS_TRUE
          ? [
              <Button onClick={openBankTab}>Alterar dados</Button>,
              <Button type="primary" onClick={onOk}>
                Confirmar e solicitar
              </Button>,
            ]
          : null
      }
    >
      {data.tem_conta === IS_TRUE ? (
        <div>
          <Space direction="vertical">
            <label>
              <Typography.Text type="secondary">
                <small>Nome do titular da conta</small>
              </Typography.Text>
              <p>{data.titular_conta}</p>
            </label>
            <label>
              <Typography.Text type="secondary">
                <small>CPF/CNPJ</small>
              </Typography.Text>
              <p>{data.cpf_cnpj}</p>
            </label>
            <label>
              <Typography.Text type="secondary">
                <small>Banco</small>
              </Typography.Text>
              <p>{data.banco}</p>
            </label>
          </Space>

          <Space size="large">
            <label>
              <Typography.Text type="secondary">
                <small>Tipo de conta</small>
              </Typography.Text>
              <p>{ACCOUNT_TYPES[data.tipo_conta_cc_cp] || '-'}</p>
            </label>
            <label>
              <Typography.Text type="secondary">
                <small>Agência</small>
              </Typography.Text>
              <p>{data.agencia}</p>
            </label>
            <label>
              <Typography.Text type="secondary">
                <small>Conta</small>
              </Typography.Text>
              <p>{data.conta}</p>
            </label>
            <label>
              <Typography.Text type="secondary">
                <small>Dígito da conta</small>
              </Typography.Text>
              <p>{data.digito_conta}</p>
            </label>
          </Space>

          <Space size="large">
            <label>
              <Typography.Text type="secondary">
                <small>Valor a ser sacado</small>
              </Typography.Text>
              <p>{data.valor_saque}</p>
            </label>
          </Space>
        </div>
      ) : (
        <Space direction="vertical" align="center" size="large">
          <p>Você não tem conta bancária cadastrada. Cadastre uma antes de solicitar o saque</p>
          <Button onClick={openBankTab} size="small">
            Cadastrar conta bancária
          </Button>
        </Space>
      )}
    </Modal>
  )
}

AfiliateWithdrawModal.propTypes = {}

AfiliateWithdrawModal.defaultProps = {}

export default AfiliateWithdrawModal
