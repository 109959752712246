import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton, Dropdown, Menu, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faUserTimes } from '@fortawesome/free-solid-svg-icons'

import * as S from './styles'

const ChatContactList = ({
  contacts,
  contactsLoading,
  handleStartChatWithContact,
  handleRemoveContact,
}) => {
  return (
    <S.ChatListContainer>
      {contactsLoading ? (
        <S.ListLoader>
          <Skeleton avatar paragraph={{ rows: 1 }} active />
          <Skeleton avatar paragraph={{ rows: 1 }} active />
        </S.ListLoader>
      ) : (
        contacts.map(item => (
          <S.ChatItemContainer key={item.token_canal}>
            <S.ChatItem
              avatar={item.foto}
              title={item.nome}
              date={null}
              onClick={() =>
                handleStartChatWithContact({
                  token_canal: item.token_canal,
                  nome_canal: item.nome_canal,
                })
              }
            />
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item onClick={() => handleRemoveContact(item)}>
                    <Space size={8}>
                      <FontAwesomeIcon icon={faUserTimes} />
                      <span>Remover contato</span>
                    </Space>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <S.MoreButton
                type="secondary"
                shape="circle"
                size="small"
                icon={<FontAwesomeIcon icon={faEllipsisV} />}
              />
            </Dropdown>
          </S.ChatItemContainer>
        ))
      )}
    </S.ChatListContainer>
  )
}

ChatContactList.propTypes = {
  handleStartChatWithContact: PropTypes.func.isRequired,
  handleRemoveContact: PropTypes.func.isRequired,
  contacts: PropTypes.instanceOf(Array),
  contactsLoading: PropTypes.bool,
}

ChatContactList.defaultProps = {
  contacts: [],
  contactsLoading: false,
}

export default ChatContactList
