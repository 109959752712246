import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'

const PageView = () => {
  const location = useLocation()

  if (process.env.REACT_APP_FACEBOOK_TRACK_ID !== '0') {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_TRACK_ID)
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_FACEBOOK_TRACK_ID === '0')
      return

    ReactPixel.track(process.env.REACT_APP_FACEBOOK_EVENT)
  }, [location])

  return null
}

export default PageView
