import styled from 'styled-components'
import { Button } from 'antd'
import { colors } from 'styles'

export const Header = styled.div`
  background-color: ${colors.componentBackground};
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 16px;
`

export const HeaderTitle = styled.span`
  font-weight: 500;
  margin-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 164px);
`

export const DisabledHeaderButton = styled.div`
  color: ${colors.defaultTextColor};
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
`

export const BackToConversationsButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

export const UnreadBadge = styled.div`
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100%;
  background: red;
  margin-left: 8px;
`
