import { Empty, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PostList, PostListFilters } from '../../components/community'
import { COMMUNITY_PERMISSIONS, useUserSession } from '../../hooks/auth/use-user-session'
import { useLabels } from '../../hooks/community/use-labels'
import { usePosts } from '../../hooks/community/use-posts'
import { useCommunityStore } from '../../stores/community'
import { PostSubmitForm } from '../../components/community/post-submit-form'
import { useSubmitPost } from '../../hooks/community/use-submit-post'

import GlobalStyles from './styles'

export const CommunityPage = () => {
  const { courseId, classId } = useParams()
  const [orderBy, setOrderBy] = useState()

  const { data: session = [{}] } = useUserSession()
  const [submitPost, { loading: isSubmittig }] = useSubmitPost({ courseId, classId })
  const { loading, error, data = [], get, cache } = usePosts({
    classId,
    courseId,
    orderBy,
    options: {
      cachePolicy: 'no-cache',
    },
  })

  const { data: labels } = useLabels()

  const posts = useCommunityStore(state => state.posts)
  const filteredPosts = useCommunityStore(state => state.filteredPosts)
  const setPosts = useCommunityStore(state => state.setPosts)

  const isSuccess = !loading && !error
  const hasPosts = posts.length > 0
  const hasFilter = filteredPosts !== null
  const notFoundFiltered = Array.isArray(filteredPosts) && filteredPosts.length === 0
  const isAllowed = COMMUNITY_PERMISSIONS[session[0].comunidades] === 'FULL_ACCESS'

  const handleSubmitPost = async (content, clearForm) => {
    try {
      await submitPost(content)
      clearForm()
      cache.clear()
      get()
    } catch (e) {}
  }

  useEffect(() => {
    setPosts(data)

    return () => {
      setPosts([])
    }
  }, [data])

  return (
    <>
      <GlobalStyles />
      <PostListFilters
        placeholder={labels?.frase_buscar}
        buttonText={labels?.botao_pergunta}
        isAllowed={isAllowed}
        onCreatePost={() => {
          cache.clear()
          get()
        }}
      />

      {labels?.disclaimer && (
        <Typography.Text
          style={{ fontSize: 12, marginBottom: 16, display: 'block' }}
          type="secondary"
        >
          {labels?.disclaimer}
        </Typography.Text>
      )}

      <PostSubmitForm
        placeholder={labels?.botao_pergunta}
        isSubmittig={isSubmittig}
        onSubmit={handleSubmitPost}
      />
      <div style={{ height: 24 }} />

      {loading && (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      )}

      {isSuccess && (hasFilter || hasPosts) ? (
        <PostList
          data={hasFilter ? filteredPosts : posts}
          courseId={courseId}
          classId={classId}
          canLike={isAllowed}
          order={orderBy}
          onSelectOrder={order => setOrderBy(order)}
        />
      ) : null}

      {isSuccess && (notFoundFiltered || !hasPosts) && (
        <Empty
          imageStyle={{ height: 50, marginTop: 24 }}
          image={
            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                <ellipse cx="32" cy="33" rx="32" ry="7" fill="rgba(255, 255, 255, 0.1)"></ellipse>
                <g fillRule="nonzero">
                  <path
                    stroke="#666"
                    fill="#141414"
                    d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                  ></path>
                  <path
                    stroke="#666"
                    fill="#141414"
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  ></path>
                </g>
              </g>
            </svg>
          }
          description={
            <Typography.Text type="secondary">
              {notFoundFiltered && 'Nada encontrado para esta busca'}
              {!hasPosts && 'Ainda não existem postagens aqui'}
            </Typography.Text>
          }
        />
      )}
    </>
  )
}
