import React, { useEffect, useReducer, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Row, Col, Spin, Space, Card, Button, Typography, Modal } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import useFetch from 'use-http'
import Countdown from 'react-countdown'

import { BaseTemplate } from 'components'
import { createGetUrl, getLegacyWebsiteUrl, getAuthData, createPostUrl } from 'utils'
import { IS_TRUE } from 'enums'

import QuestionTemplate from 'components/exam/question-template'

import { initialState, reducer, ACTIONS } from './reducer'

const ExamPage = () => {
  const params = useParams()
  const history = useHistory()
  const [state, dispatchAction] = useReducer(reducer, initialState)
  const [startTime, setStartTime] = useState(Date.now())

  const { questionIndex, isAble, answers, finishModal } = state

  const { get: loadExam, cache, loading, error, data = {} } = useFetch(
    createGetUrl('CarregaProva', params.examKey),
    null,
    []
  )

  const { post: sendAnswers, loading: sendAnswersLoading, response: examResultResponse } = useFetch(
    createPostUrl('RespondeProva')
  )

  const dispatch = (type, state) => dispatchAction({ type, state })

  useEffect(() => {
    if (cache.clear) {
      cache.clear()
    }

    if (process.env.NODE_ENV !== 'development') {
      window.onbeforeunload = function () {
        return 'Você irá perder todas as respostas realizadas se sair a página'
      }
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      dispatch(ACTIONS.IS_ABLE, data.resultado === IS_TRUE)
    }
  }, [data])

  useEffect(() => {
    if (!loading && !error && isAble) {
      setStartTime(Date.now())
    }
  }, [loading, error, isAble])

  const onPreviousQuestion = () => {
    window.scrollTo(0, 0)
    dispatch(ACTIONS.QUESTION_INDEX, questionIndex - 1)
  }

  const handleAnswerQuestionButton = () => {
    window.scrollTo(0, 0)
    dispatch(ACTIONS.QUESTION_INDEX, questionIndex + 1)
  }

  const getQuestionAnswer = question => {
    if (!question) return

    return answers.find(item => item.id_questao === question.id)
  }

  const handleFinishExam = async (ignoreQuestionLength = false) => {
    const totalOfQuestions = data.questoes.length

    if (ignoreQuestionLength === false && totalOfQuestions !== answers.length) {
      return dispatch(ACTIONS.SET_FINISH_MODAL, true)
    }

    const normalizedAnswers = answers.reduce((acc, answer) => {
      const flatAnswers = answer.respostas.map(resp => ({
        quest: Number(answer.id_questao),
        resp: Number(resp),
      }))

      return [...acc, ...flatAnswers]
    }, [])

    const examToken = [...data.token_prova].slice(1)
    examToken.pop()
    examToken.join('')

    await sendAnswers({
      ...getAuthData(),
      token_prova: examToken.join(''),
      respostas: normalizedAnswers,
    })

    if (examResultResponse.ok) {
      history.push({ pathname: `/atividade/${examToken.join('')}/resultado` })
    }
  }

  if (loading)
    return (
      <BaseTemplate removeHeaderAndFooter>
        <Row align="center" justify="center" style={{ height: '50vh' }}>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <Space direction="vertical" size="large" align="center">
              <Spin />
              <div>Carregando prova...</div>
            </Space>
          </Col>
        </Row>
      </BaseTemplate>
    )

  if (!loading && !isAble)
    return (
      <BaseTemplate cleanHeader>
        <Row style={{ textAlign: 'center', height: '70vh' }}>
          <Col
            xs={{ span: 24, push: 0 }}
            sm={{ span: 24, push: 0 }}
            md={{ span: 14, push: 5 }}
            lg={{ span: 10, push: 7 }}
            xl={{ span: 10, push: 7 }}
          >
            <br />
            <br />
            <Card loading={loading}>
              <Space direction="vertical" size="large">
                <img
                  src={data.imagem_prova}
                  style={{ width: 208, borderRadius: 8 }}
                  alt={data.titulo}
                />
                <Countdown
                  date={Date.now() + data.tempo_aguardar * 60 * 1000}
                  onComplete={() => {
                    cache.clear()
                    loadExam()
                  }}
                  renderer={time => (
                    <Typography.Title level={2}>
                      {time.formatted.hours}:{time.formatted.minutes}:{time.formatted.seconds}
                    </Typography.Title>
                  )}
                />

                <Typography.Text>{data.frase}</Typography.Text>

                <Button type="link" block>
                  <a href={`${getLegacyWebsiteUrl()}/curso/${data.slug_curso}`}>
                    Voltar para o curso
                  </a>
                </Button>
              </Space>
            </Card>
          </Col>
        </Row>
      </BaseTemplate>
    )

  if (!loading && !error && isAble) {
    return (
      <>
        <Helmet>
          <title>
            {`Questão ${questionIndex + 1} - ${data.titulo} - ${process.env.REACT_APP_APP_NAME}`}
          </title>
        </Helmet>

        <QuestionTemplate
          examTitle={data.titulo}
          question={data.questoes[questionIndex]}
          questionAnswer={getQuestionAnswer(data.questoes[questionIndex])}
          step={questionIndex + 1}
          total={data.questoes.length}
          minutesToAnswer={data.tempo}
          examStartTime={startTime}
          onPreviousQuestion={onPreviousQuestion}
          handleAnswerQuestionButton={handleAnswerQuestionButton}
          onFinishExam={handleFinishExam}
          addSingleAnswer={data => dispatch(ACTIONS.ADD_SINGLE_ANSWER, data)}
          removeSingleAnswer={data => dispatch(ACTIONS.REMOVE_SINGLE_ANSWER, data)}
          addMultipleAnswer={data => dispatch(ACTIONS.ADD_MULTIPLE_ANSWER, data)}
          removeMultipleAnswer={data => dispatch(ACTIONS.REMOVE_MULTIPLE_ANSWER, data)}
          sendAnswersLoading={sendAnswersLoading}
        />

        <Modal
          title="Atividade incompleta"
          visible={finishModal}
          cancelText="Cancelar"
          okText="Sim, Concluir"
          onOk={() => handleFinishExam(true)}
          onCancel={() => dispatch(ACTIONS.SET_FINISH_MODAL, false)}
        >
          Você respondeu {answers.length} de {data?.questoes.length}. Deseja realmente concluir ?
        </Modal>
      </>
    )
  }
}

export default ExamPage
