import React, { useMemo } from 'react'
import { Table, Progress } from 'antd'
import { useFetch } from 'use-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'

import { createAdminGetUrl } from 'utils'
import { colors } from 'styles/variables'

const { Column } = Table

const AlunosAtivosPorPlano = () => {
  const { data, loading, error } = useFetch(createAdminGetUrl('DashMKTVendasAtivosPlano'), null, [])

  const filters = useMemo(
    () => (!!data ? data.map(item => ({ text: item.plano, value: item.plano })) : []),
    [data]
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      rightComponent={<FontAwesomeIcon icon={faHistory} color={colors.primaryColor} />}
      title="Alunos ativos por plano"
    >
      <Table
        size="small"
        pagination={{ defaultPageSize: 8, showSizeChanger: false }}
        locale={{ filterConfirm: 'Aplicar', filterReset: 'Limpar', emptyText: 'Sem dados' }}
        dataSource={data}
      >
        <Column
          title="Plano"
          filters={filters}
          onFilter={(value, record) => record.plano === value}
          dataIndex="plano"
          className="small-font"
          key="plano"
        />

        <Column
          title="Periodicidade"
          dataIndex="periodicidade"
          align="center"
          key="periodicidade"
        />

        <Column
          title="Usuários Ativos"
          dataIndex="usuarios_ativos"
          align="center"
          key="usuarios_ativos"
          sorter={(a, b) => a.usuarios_ativos - b.usuarios_ativos}
          render={value => (
            <MetricNumber value={Number(value)}>{({ value }) => value}</MetricNumber>
          )}
        />

        <Column
          title="Percentual"
          dataIndex="percentual"
          align="center"
          key="percentual"
          sorter={(a, b) => a.percentual - b.percentual}
          render={value => (
            <>
              <MetricNumber value={Number(value)} suffix=" %">
                {({ formatedNumber }) => formatedNumber}
              </MetricNumber>
              <div style={{ width: 170 }}>
                <Progress percent={value} status="success" size="small" showInfo={false} />
              </div>
            </>
          )}
        />
      </Table>
    </GenericCard>
  )
}

export { AlunosAtivosPorPlano }
