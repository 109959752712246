export const IS_TRUE = '1'
export const IS_FALSE = '0'
export const IS_ZERO = '0'
export const IS_OK = '0'

export const NOT_FRIENDS = '0'
export const IS_FRIENDS = '1'

export const ABC_ARRAY = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode('a'.charCodeAt(0) + i)
)

export const WEBSOCKET_EVENTS = {
  message: 'msg',
  join: 'entrar',
  leave: 'sair',
  messageConfirmation: 'conf',
  checkin: 'checkin',
  ping: 'ping',
  generalChat: 'geral',
}

export const POST_MESSAGES_ACTIONS = {
  EDIT_PHOTO_ON: 'EDIT_PHOTO_ON',
  EDIT_PHOTO_OFF: 'EDIT_PHOTO_OFF',
  OPEN_CHAT: 'OPEN_CHAT',
  CLOSE_CHAT: 'CLOSE_CHAT',
  START_CLASS_CHAT: 'START_CLASS_CHAT',
  MAXIMIZE: 'MAXIMIZE',
  MINIMIZE: 'MINIMIZE',
  AUTH_DONE: 'AUTH_DONE',
  CHANGE_URL: 'CHANGE_URL',
  FINISH_ONBOARDING: 'FINISH_ONBOARDING',
}

export const PHONE_MASKS = [
  { label: 'Brasil', value: '(99) 99999-9999', name: 'brazil' },
  { label: 'Estados unidos', value: '(999) 999-9999', name: 'us' },
  { label: 'Itália ', value: '99 9999 9999', name: 'italy' },
  { label: 'África', value: '999 999 9999', name: 'africa' },
  { label: 'Outros', value: '***********', name: 'other' },
]

export const YEARS = [
  {
    label: '2020',
    value: 2020,
  },
  {
    label: '2021',
    value: 2021,
  },
  {
    label: '2022',
    value: 2022,
  },
]

export const MONTHS = [
  { label: 'Jan', value: 1 },
  { label: 'Fev', value: 2 },
  { label: 'Mar', value: 3 },
  { label: 'Abr', value: 4 },
  { label: 'Mai', value: 5 },
  { label: 'Jun', value: 6 },
  { label: 'Jul', value: 7 },
  { label: 'Ago', value: 8 },
  { label: 'Set', value: 9 },
  { label: 'Out', value: 10 },
  { label: 'Nov', value: 11 },
  { label: 'Dez', value: 12 },
]

export const PAYMENT_OPTIONS_SELECT = {
  BOLETO: 'boleto',
  NEW_CREDIT_CARD: 'new-credit-card',
  CREDIT_CARD: 'card',
}

export const GENDER = {
  NOT_INFORMED: 0,
  MALE: 1,
  FEMALE: 2,
}

export const PHONE_INTL_INPUT_COUNTRIES = [
  ['Afghanistan', 'af', '93'],
  ['Albania', 'al', '355'],
  ['Algeria', 'dz', '213'],
  ['Andorra', 'ad', '376'],
  ['Angola', 'ao', '244'],
  ['Antígua e Barbuda', 'ag', '1268'],
  ['Argentina', 'ar', '54', '(..) ........', 0],
  ['Armênia', 'am', '374', '.. ......'],
  ['Aruba', 'aw', '297'],
  [
    'Austrália',
    'au',
    '61',
    {
      default: '. .... ....',
      '/^4/': '... ... ...',
      '/^5(?!50)/': '... ... ...',
      '/^1(3|8)00/': '.... ... ...',
      '/^13/': '.. .. ..',
      '/^180/': '... ....',
    },
    0,
    [],
  ],
  ['Áustria', 'at', '43'],
  ['Azerbaijão', 'az', '994', '(..) ... .. ..'],
  ['Bahamas', 'bs', '1242'],
  ['Bahrain', 'bh', '973'],
  ['Bangladesh', 'bd', '880'],
  ['Barbados', 'bb', '1246'],
  ['Belarus', 'by', '375', '(..) ... .. ..'],
  ['Bélgica', 'be', '32', '... .. .. ..'],
  ['Belize', 'bz', '501'],
  ['Benin', 'bj', '229'],
  ['Butão', 'bt', '975'],
  ['Bolívia', 'bo', '591'],
  ['Bósnia e Herzegovina', 'ba', '387'],
  ['Botswana', 'bw', '267'],
  ['Brasil', 'br', '55', '(..) .....-....'],
  ['Território Britânico do Oceano Índico', 'io', '246'],
  ['Brunei', 'bn', '673'],
  ['Bulgária', 'bg', '359'],
  ['Burkina Faso', 'bf', '226'],
  ['Burundi', 'bi', '257'],
  ['Camboja', 'kh', '855'],
  ['Camarões', 'cm', '237'],
  [
    'Canadá',
    'ca',
    '1',
    '(...) ...-....',
    1,
    [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Cabo Verde', 'cv', '238'],
  ['Caribe Países Baixos', 'bq', '599', '', 1],
  ['Ilhas Cayman', 'ky', '1', '... ... ....', 4, ['345']],
  ['República Centro-Africana', 'cf', '236'],
  ['Chad', 'td', '235'],
  ['Chile', 'cl', '56'],
  ['China', 'cn', '86', '... .... ....'],
  ['Colômbia', 'co', '57', '... ... ....'],
  ['Comores', 'km', '269'],
  ['Congo', 'cd', '243'],
  ['Congo', 'cg', '242'],
  ['Costa Rica', 'cr', '506', '....-....'],
  ['Costa do Marfim', 'ci', '225', '.. .. .. .. ..'],
  ['Croácia', 'hr', '385'],
  ['Cuba', 'cu', '53'],
  ['Curaçao', 'cw', '599', '', 0],
  ['Chipre', 'cy', '357', '.. ......'],
  ['República Tcheca', 'cz', '420', '... ... ...'],
  ['Dinamarca', 'dk', '45', '.. .. .. ..'],
  ['Djibuti', 'dj', '253'],
  ['Dominica', 'dm', '1767'],
  ['República Dominicana', 'do', '1', '', 2],
  ['Equador', 'ec', '593'],
  ['Egito', 'eg', '20'],
  ['El Salvador', 'sv', '503', '....-....'],
  ['Guiné Equatorial', 'gq', '240'],
  ['Eritreia', 'er', '291'],
  ['Estônia', 'ee', '372', '.... ......'],
  ['Etiópia', 'et', '251'],
  ['Fiji', 'fj', '679'],
  ['Finlândia', 'fi', '358', '.. ... .. ..'],
  ['França', 'fr', '33', '. .. .. .. ..'],
  ['Guiana Francesa', 'gf', '594'],
  ['Polinésia Francesa', 'pf', '689'],
  ['Gabão', 'ga', '241'],
  ['Gâmbia', 'gm', '220'],
  ['Geórgia', 'ge', '995'],
  ['Alemanha', 'de', '49', '... .........'],
  ['Gana', 'gh', '233'],
  ['Grécia', 'gr', '30'],
  ['Groenlândia', 'gl', '299', '.. .. ..'],
  ['Granada', 'gd', '1473'],
  ['Guadalupe', 'gp', '590', '', 0],
  ['Guam', 'gu', '1671'],
  ['Guatemala', 'gt', '502', '....-....'],
  ['Guiné', 'gn', '224'],
  ['Guiné-Bissau', 'gw', '245'],
  ['Guiana', 'gy', '592'],
  ['Haiti', 'ht', '509', '....-....'],
  ['Honduras', 'hn', '504'],
  ['Hong Kong', 'hk', '852', '.... ....'],
  ['Hungria', 'hu', '36'],
  ['Islândia', 'is', '354', '... ....'],
  ['Índia', 'in ', '91', '.....-.....'],
  ['Indonésia', 'id', '62'],
  ['Irã', 'ir', '98', '... ... ....'],
  ['Iraque', 'iq', '964'],
  ['Irlanda', 'ie', '353', '.. .......'],
  ['Israel', 'il', '972', '... ... ....'],
  ['Itália', 'it', '39', '... .......', 0],
  ['Jamaica', 'jm', '1876'],
  ['Japão', 'jp', '81', '.. .... ....'],
  ['Jordânia', 'jo', '962'],
  ['Cazaquistão', 'kz', '7', '... ...-..-..', 0],
  ['Quênia', 'ke', '254'],
  ['Kiribati', 'ki', '686'],
  ['Kosovo', 'xk', '383'],
  ['Kuwait', 'kw', '965'],
  ['Quirguistão', 'kg', '996', '... ... ...'],
  ['Laos', 'la', '856'],
  ['Letônia', 'lv', '371', '.. ... ...'],
  ['Líbano', 'lb', '961'],
  ['Lesoto', 'ls', '266'],
  ['Libéria', 'lr', '231'],
  ['Líbia', 'ly', '218'],
  ['Liechtenstein', 'li', '423'],
  ['Lituânia', 'lt', '370'],
  ['Luxemburgo', 'lu', '352'],
  ['Macau', 'mo', '853'],
  ['Macedônia', 'mk', '389'],
  ['Madagascar', 'mg', '261'],
  ['Malawi', 'mw', '265'],
  ['Malásia', 'my', '60', '..-....-....'],
  ['Maldivas', 'mv', '960'],
  ['Mali', 'ml', '223'],
  ['Malta', 'mt', '356'],
  ['Ilhas Marshall', 'mh', '692'],
  ['Martinica', 'mq', '596'],
  ['Mauritânia', 'mr', '222'],
  ['Maurício', 'mu', '230'],
  ['México', 'mx', '52', '... ... ....', 0],
  ['Micronésia', 'fm', '691'],
  ['Moldávia', 'md', '373', '(..) ..-..-..'],
  ['Mônaco', 'mc', '377'],
  ['Mongólia', 'mn', '976'],
  ['Montenegro', 'me', '382'],
  ['Marrocos', 'ma', '212'],
  ['Moçambique', 'mz', '258'],
  ['Myanmar', 'mm', '95'],
  ['Namíbia', 'na', '264'],
  ['Nauru', 'nr', '674'],
  ['Nepal', 'np', '977'],
  ['Holanda', 'nl', '31', '.. ........'],
  ['Nova Caledônia', 'nc', '687'],
  ['Nova Zelândia', 'nz', '64', '...-...-....'],
  ['Nicarágua', 'ni', '505'],
  ['Níger', 'ne', '227'],
  ['Nigéria', 'ng', '234'],
  ['Coreia do Norte', 'kp', '850'],
  ['Noruega', 'no', '47', '... .. ...'],
  ['Omã', 'om', '968'],
  ['Paquistão', 'pk', '92', '...-.......'],
  ['Palau', 'pw', '680'],
  ['Palestina', 'ps', '970'],
  ['Panamá', 'pa', '507'],
  ['Papua Nova Guiné', 'pg', '675'],
  ['Paraguai', 'py', '595'],
  ['Peru', 'pe', '51'],
  ['Filipinas', 'ph', '63', '.... .......'],
  ['Polônia', 'pl', '48', '...-...-...'],
  ['Portugal', 'pt', '351', '...-...-...'],
  ['Porto Rico', 'pr', '1', '', 3, ['787', '939']],
  ['Catar', 'qa', '974'],
  ['Reunião', 're', '262'],
  ['Romênia', 'ro', '40'],
  ['Rússia', 'ru', '7', '(...) ...-..-..', 1],
  ['Ruanda', 'rw', '250'],
  ['São Cristóvão e Nevis', 'kn', '1869'],
  ['Santa Lúcia', 'lc', '1758'],
  ['São Vicente e Granadinas', 'vc', '1784'],
  ['Samoa', 'ws', '685'],
  ['San Marino', 'sm', '378'],
  ['São Tomé e Príncipe', 'st', '239'],
  ['Arábia Saudita', 'sa', '966'],
  ['Senegal', 'sn', '221'],
  ['Sérvia', 'rs', '381'],
  ['Seychelles', 'sc', '248'],
  ['Serra Leoa', 'sl', '232'],
  ['Singapura', 'sg', '65', '....-....'],
  ['Eslováquia', 'sk', '421'],
  ['Eslovênia', 'si', '386'],
  ['Ilhas Salomão', 'sb', '677'],
  ['Somália', 'so', '252'],
  ['África do Sul', 'za', '27'],
  ['Coreia do Sul', 'kr', '82', '... .... ....'],
  ['Sudão do Sul', 'ss', '211'],
  ['Espanha', 'es', '34', '... ... ...'],
  ['Sri Lanka', 'lk', '94'],
  ['Sudão', 'sd', '249'],
  ['Suriname', 'sr', '597'],
  ['Suazilândia', 'sz', '268'],
  ['Suécia', 'se', '46', '... ... ...'],
  ['Suíça', 'ch', '41', '.. ... .. ..'],
  ['Síria', 'sy', '963'],
  ['Taiwan', 'tw', '886'],
  ['Tajiquistão', 'tj', '992'],
  ['Tanzânia', 'tz', '255'],
  ['Tailândia', 'th', '66'],
  ['Timor-Leste', 'tl', '670'],
  ['Togo', 'tg', '228'],
  ['Tonga', 'to', '676'],
  ['Trinidad e Tobago', 'tt', '1868'],
  ['Tunísia', 'tn', '216'],
  ['Turquia', 'tr', '90', '... ... .. ..'],
  ['Turcomenistão', 'tm', '993'],
  ['Tuvalu', 'tv', '688'],
  ['Uganda', 'ug', '256'],
  ['Ucrânia', 'ua', '380', '(..) ... .. ..'],
  ['Emirados Árabes Unidos', 'ae', '971'],
  ['Reino Unido', 'gb', '44', '.... ......'],
  ['Estados Unidos', 'us', '1', '(...) ...-....', 0],
  ['Uruguai', 'uy', '598'],
  ['Uzbequistão', 'uz', '998', '.. ... .. ..'],
  ['Vanuatu', 'vu', '678'],
  ['Vaticano', 'va', '39', '.. .... ....', 1],
  ['Venezuela', 've', '58'],
  ['Vietnã', 'vn', '84'],
  ['Iémen', 'ye', '967'],
  ['Zâmbia', 'zm', '260'],
  ['Zimbabwe', 'zw', '263'],
]
