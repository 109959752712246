import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useParams } from 'react-router-dom'

import { Button, message, Space, Typography } from 'antd'
import React from 'react'
import { PostSubmitForm } from '../../components/community/post-submit-form'
import { useSubmitPost } from '../../hooks/community/use-submit-post'
import { useLabels } from '../../hooks/community/use-labels'

export const CommunitySubmitPostPage = () => {
  const { courseId, classId } = useParams()
  const { goBack } = useHistory()

  const { data: labels } = useLabels()
  const [submitPost, { loading }] = useSubmitPost({ courseId, classId })

  const handleSubmitPost = async content => {
    try {
      await submitPost(content)
      message.success('Postagem públicada com sucesso!')
      goBack()
    } catch (e) {
      message.error('Ocorreu um erro ao tentar publicar')
    }
  }

  return (
    <>
      <Button onClick={goBack}>
        <Space size={8}>
          <FontAwesomeIcon icon={faArrowLeft} />
          <div>Voltar</div>
        </Space>
      </Button>
      <div style={{ height: 24 }} />
      <Typography.Title level={4}>{labels?.texto_pergunta}</Typography.Title>
      <PostSubmitForm
        placeholder={labels?.frase_pergunta}
        submitLabel={labels?.botao_pergunta}
        isSubmittig={loading}
        onSubmit={handleSubmitPost}
      />
    </>
  )
}
