import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import Countdown from 'react-countdown'

import * as S from './styles'

const CAN_START_EXAM = '0'

const RestartExamCard = ({
  restartTime,
  examKey,
  nowTimestamp,
  canRestartExam,
  setCanRestartExam,
}) => {
  const history = useHistory()

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {canRestartExam ? (
        <>
          <S.Title>Você pode refazer</S.Title>

          <Button type="primary" onClick={() => history.push(`/atividade/${examKey}`)}>
            Refazer prova
          </Button>
        </>
      ) : (
        <>
          <S.Title>
            {restartTime === CAN_START_EXAM ? 'Você pode refazer' : 'Aguarde para refazer'}
          </S.Title>
          {restartTime === CAN_START_EXAM ? (
            <Button type="primary" onClick={() => history.push(`/atividade/${examKey}`)}>
              Refazer prova
            </Button>
          ) : (
            nowTimestamp && (
              <Countdown
                date={nowTimestamp + Number(restartTime) * 60 * 1000}
                onComplete={() => setCanRestartExam(true)}
                renderer={time => (
                  <S.Subtitle style={{ width: '72px' }}>
                    {time.formatted.hours}:{time.formatted.minutes}:{time.formatted.seconds}
                  </S.Subtitle>
                )}
              />
            )
          )}
        </>
      )}
    </div>
  )
}

RestartExamCard.propTypes = {
  examKey: PropTypes.string,
  restartTime: PropTypes.string,
  nowTimestamp: PropTypes.number.isRequired,
  canRestartExam: PropTypes.bool.isRequired,
  setCanRestartExam: PropTypes.func.isRequired,
}

RestartExamCard.defaultProps = {
  restartTime: '0',
  examKey: '',
}

export default RestartExamCard
