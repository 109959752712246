import styled from 'styled-components'
import Color from 'color'

import { colors, medias, typography } from 'styles'

export const Trend = styled.span`
  display: inline-flex;
`

export const TrendIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isPositive }) => (isPositive ? colors.green : colors.red)};
  background-color: ${({ isPositive }) =>
    isPositive
      ? Color(colors.green).fade(0.75).toString()
      : Color(colors.red).fade(0.75).toString()};
`

export const TrendNumber = styled.div`
  font-size: ${({ isSmall }) => (isSmall ? '11px' : '14px')};
  line-height: 16px;
  height: 16px;
  margin-left: 8px;
  font-weight: ${({ isSmall }) => (isSmall ? 300 : 600)};
  font-family: ${typography.fontFamily};
  color: ${({ isPositive }) => (isPositive ? colors.green : colors.red)};
`
