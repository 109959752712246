import React, { useRef, useState } from 'react'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Empty, Space, Spin, Typography } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { PostCard, PostList } from '../../components/community'
import { PostSubmitForm } from '../../components/community/post-submit-form'
import { useUserSession, COMMUNITY_PERMISSIONS } from '../../hooks/auth/use-user-session'
import { usePost } from '../../hooks/community/use-post'
import { usePosts } from '../../hooks/community/use-posts'
import { useSubmitPost } from '../../hooks/community/use-submit-post'
import { useLabels } from '../../hooks/community/use-labels'

export const CommunityPostPage = () => {
  const responseRef = useRef()
  const { goBack } = useHistory()
  const { postId, courseId, classId } = useParams()
  const [originId, setOriginId] = useState()

  const { data: labels } = useLabels()
  const responses = usePosts({ courseId, postId })
  const { loading, error, data = {} } = usePost({ postId })
  const { data: session = [{}] } = useUserSession()

  const isAllowed = COMMUNITY_PERMISSIONS[session[0].comunidades] === 'FULL_ACCESS'

  const [submitPost, { loading: isSubmittig }] = useSubmitPost({ courseId, postId, originId })

  const refetchResposes = () => {
    responses.cache.clear()
    responses.get()
  }

  const handleSubmitPost = async (content, clearForm) => {
    try {
      await submitPost(content)
      setOriginId(null)
      clearForm()
      refetchResposes()
    } catch (e) {}
  }

  const handleRespondClick = origin => {
    setOriginId(origin)

    if (responseRef.current) {
      responseRef.current.focus()
    }
  }

  const getOriginPost = id => (responses.data || []).find(response => response.id_post === id)

  return (
    <>
      <Button onClick={goBack}>
        <Space size={8}>
          <FontAwesomeIcon icon={faArrowLeft} />
          <div>Voltar</div>
        </Space>
      </Button>
      <div style={{ height: 16 }} />

      {loading && (
        <Card style={{ backgroundColor: '#1f1f1f', textAlign: 'center' }} bordered={false}>
          <Spin />
        </Card>
      )}
      {!loading && !error && <PostCard removeLink data={data} />}
      <div style={{ height: 16 }} />

      <Typography.Title level={5}>
        {originId ? `Responder ${getOriginPost(originId).nome_pessoa}` : 'Respostas'}
      </Typography.Title>

      {isAllowed && (
        <>
          <PostSubmitForm
            placeholder="Escreva sua resposta"
            submitLabel="Enviar resposta"
            isSubmittig={isSubmittig}
            onSubmit={handleSubmitPost}
            ref={responseRef}
          />
          <div style={{ height: 24 }} />
        </>
      )}

      {responses.loading && (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      )}

      {labels?.disclaimer && (
        <Typography.Text
          tyle={{ fontSize: 12, marginBottom: 8, display: 'block' }}
          type="secondary"
        >
          {labels?.disclaimer}
        </Typography.Text>
      )}

      {!responses.loading && !responses.error && (
        <PostList
          data={responses.data || []}
          courseId={courseId}
          classId={classId}
          onDeleteComment={refetchResposes}
          onRespondPress={handleRespondClick}
          canLike={isAllowed}
          isComment
        />
      )}

      {!responses.loading && !responses.error && responses.data.length === 0 && (
        <Empty
          imageStyle={{ height: 50, marginTop: 24 }}
          image={
            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                <ellipse cx="32" cy="33" rx="32" ry="7" fill="rgba(255, 255, 255, 0.1)"></ellipse>
                <g fillRule="nonzero">
                  <path
                    stroke="#666"
                    fill="#141414"
                    d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                  ></path>
                  <path
                    stroke="#666"
                    fill="#141414"
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  ></path>
                </g>
              </g>
            </svg>
          }
          description={
            <Typography.Text type="secondary">
              Esta postagem não tem respostas ainda
            </Typography.Text>
          }
        />
      )}
    </>
  )
}
