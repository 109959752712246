import React, { useState, useRef, useEffect } from 'react'
import OtpInput from 'react-otp-input'
import { Button, Input, message, Space, Typography } from 'antd'
import Countdown from 'react-countdown'
import useFetch from 'use-http'

import { useI18n } from 'context/i18n'
import { cleanPhoneNumber, getTokenApp } from 'utils'

// STYLES
import * as S from './styles'

// CONSTANTS
const LAST_OTP_STORAGE_NAME = '@adp:last_otp'
const WAIT_TIME = 90 * 1000

// FUNCTIONS
const OtpStorage = {
  save: time => {
    localStorage.setItem(LAST_OTP_STORAGE_NAME, String(time))
  },
  get: () => {
    if (!localStorage.getItem(LAST_OTP_STORAGE_NAME)) return Date.now() + WAIT_TIME

    return Number(localStorage.getItem(LAST_OTP_STORAGE_NAME)) > Date.now()
      ? Number(localStorage.getItem(LAST_OTP_STORAGE_NAME))
      : Date.now() + WAIT_TIME // If the time is expired, return a new time
  },
  shouldResend: () => {
    if (!localStorage.getItem(LAST_OTP_STORAGE_NAME)) return true
    return Number(localStorage.getItem(LAST_OTP_STORAGE_NAME)) < Date.now()
  },
  delete: () => {
    localStorage.removeItem(LAST_OTP_STORAGE_NAME)
  },
}

const tokenApp = getTokenApp()

const SmsOtp = ({ phoneNumber, email, onSuccessOtpSubmit }) => {
  const { t } = useI18n()
  const [otp, setOtp] = useState('')
  const [canResend, setResendStatus] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [countdown, setCountdown] = useState(OtpStorage.get())
  const submitted = useRef(false)

  const REQUEST_SMS_URL = `${
    process.env.REACT_APP_API_URL
  }SolicitaSMSValidacao/${tokenApp}/${encodeURIComponent(email)}/${cleanPhoneNumber(phoneNumber)}`

  const OTP_VALIDATION_URL = `${
    process.env.REACT_APP_API_URL
  }ValidaSMSValidacao/${tokenApp}/${encodeURIComponent(email)}/${cleanPhoneNumber(
    phoneNumber
  )}/${otp}`

  const { get: resendSms, loading: isRequestingSms, data: sendSmsState } = useFetch(
    REQUEST_SMS_URL,
    null
  )
  const { get: validateOtp, loading: isValidatingOtp } = useFetch(OTP_VALIDATION_URL, null)

  useEffect(() => {
    if (OtpStorage.shouldResend()) {
      resendSms()
    }

    OtpStorage.save(countdown)
  }, [])

  useEffect(() => {
    if (submitted.current) return

    if (otp.length === 4) {
      setLoading(true)
      submitted.current = true

      validateOtp()
        .then(response => {
          if (!response.sucesso) {
            setOtp('')
            submitted.current = false
            setLoading(false)
            message.error(response.frase, 5000)
            return
          }

          setOtp('')
          setLoading(false)
          onSuccessOtpSubmit(response)
        })
        .catch(() => {
          setOtp('')
          submitted.current = false
          setLoading(false)
        })
    }
  }, [otp])

  return (
    <>
      <Space direction="vertical" align="center">
        {sendSmsState ? (
          sendSmsState?.sucesso ? (
            <Typography.Title style={{ textAlign: 'center' }} level={5}>
              {sendSmsState?.frase}
            </Typography.Title>
          ) : (
            sendSmsState?.frase && <S.Alert message={sendSmsState?.frase}></S.Alert>
          )
        ) : (
          t('auth.smsOtpDescription')
        )}

        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          shouldAutoFocus
          renderSeparator={<span style={{ display: 'block', width: '1rem' }}></span>}
          renderInput={props => {
            const { type, inputMode, ...rest } = props
            return (
              <Input
                {...rest}
                disabled={isLoading}
                style={{ ...props.style, width: '3rem' }}
                type="tel"
              />
            )
          }}
        />
      </Space>

      <Space align="center" direction="vertical" style={{ marginTop: '2rem' }}>
        <Typography.Text>{t('auth.smsOtpDidntReceive')}</Typography.Text>

        <Button
          disabled={!canResend || isRequestingSms || isValidatingOtp}
          loading={isRequestingSms}
          onClick={() => {
            resendSms()
              .then(response => {
                setResendStatus(false)
                OtpStorage.save(Date.now() + WAIT_TIME)
                setCountdown(OtpStorage.get())
              })
              .catch(e => {
                message.error(e.message)
              })
              .finally(() => {
                setLoading(false)
                setOtp('')
                submitted.current = false
              })
          }}
        >
          {canResend ? (
            t('auth.smsOtpResend')
          ) : (
            <Countdown
              date={countdown}
              onComplete={() => {
                setResendStatus(true)
                OtpStorage.delete()
              }}
              renderer={time => (
                <Typography.Text>
                  {t('auth.smsOtpResendIn')} {time.formatted.minutes}:{time.formatted.seconds}
                </Typography.Text>
              )}
            />
          )}
        </Button>
      </Space>
    </>
  )
}

export default SmsOtp
