import { createGlobalStyle } from 'styled-components'

const ChatGlobalStyles = createGlobalStyle`
  .app-html {
    overflow: hidden;
  }

  .app-body {
    background-color: rgba(255, 255, 255, 0) !important;
  }

  #root {
    height: 100%;
  }
`

export default ChatGlobalStyles
