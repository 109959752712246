import React from 'react'
import { Row, Col, Select, Button } from 'antd'
import InputMask from 'react-input-mask'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import * as S from './syles'
import { validateFields } from 'utils/validate-fields'

const fieldValdiations = {
  parcelas: Yup.string().required('Selecione uma opção'),
  vencimento: Yup.string().required('Selecione uma opção'),
}

const BoletoOptionsSelectForm = ({ onSubmit, onCancel, installments, dueDates, loading }) => {
  const { values, errors, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: {
      parcelas: '',
      vencimento: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      const errors = validateFields(values, fieldValdiations)

      if (errors.length) {
        errors.forEach(error => {
          setFieldError(error.field, error.message)
        })
        return
      }

      if (onSubmit) onSubmit({ ...values })
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={[16, 0]}>
        <Col span={12} sm={24} xs={24}>
          <S.Label>
            Parcelas:
            <S.Select
              value={values.parcelas}
              onChange={value => setFieldValue('parcelas', value)}
              placeholder="Não informado"
              size="small"
              error={errors.parcelas}
            >
              {installments.map(installment => (
                <Select.Option
                  key={`installmentBoleto-${installment.parcelasboleto}`}
                  value={installment.parcelasboleto}
                >
                  {installment.parcelasboleto}x de {installment.valor}
                </Select.Option>
              ))}
            </S.Select>
          </S.Label>

          {errors.parcelas && <S.ErrorMessage>{errors.parcelas}</S.ErrorMessage>}
        </Col>

        <Col span={12} sm={24} xs={24}>
          <S.Label>
            Vencimento:
            <S.Select
              value={values.vencimento}
              onChange={value => setFieldValue('vencimento', value)}
              placeholder="Não informado"
              size="small"
              error={errors.vencimento}
            >
              {dueDates.map(dueDate => (
                <Select.Option key={`dueDatesBoleto-${dueDate.data}`} value={dueDate.data}>
                  {dueDate.data}
                </Select.Option>
              ))}
            </S.Select>
          </S.Label>

          {errors.vencimento && <S.ErrorMessage>{errors.vencimento}</S.ErrorMessage>}
        </Col>

        <Col span={24}>
          <S.Footer>
            {onCancel ? (
              <Button
                onClick={onCancel}
                disabled={loading}
                size="small"
                type="text"
                htmlType="button"
              >
                Alterar forma de pagamento
              </Button>
            ) : (
              <div></div>
            )}
            <Button type="primary" size="small" htmlType="submit" loading={loading}>
              Finalizar compra
            </Button>
          </S.Footer>
        </Col>
      </Row>
    </form>
  )
}

BoletoOptionsSelectForm.propTypes = {}

BoletoOptionsSelectForm.defaultProps = {}

export default BoletoOptionsSelectForm
