import React, { useMemo } from 'react'
import { Table, Space } from 'antd'
import { useFetch } from 'use-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'

import { createAdminGetUrl } from 'utils'
import { colors } from 'styles/variables'

const { Column } = Table

const LtvFormaPagamentoCard = () => {
  const { data, loading, error } = useFetch(
    createAdminGetUrl('DashMKTVendasLTVFormaPGTO'),
    null,
    []
  )

  return (
    <GenericCard
      error={error}
      loading={loading}
      rightComponent={<FontAwesomeIcon icon={faHistory} color={colors.primaryColor} />}
      title="LTV por Forma de pagamento"
    >
      <Table
        size="small"
        pagination={false}
        locale={{ filterConfirm: 'Aplicar', filterReset: 'Limpar', emptyText: 'Sem dados' }}
        dataSource={data}
      >
        <Column
          title="Forma de Pagamento"
          dataIndex="forma_pgto"
          className="small-font"
          key="forma_pgto"
        />

        <Column
          title="Usuários Ativos"
          dataIndex="usuarios_ativos"
          align="center"
          key="usuarios_ativos"
          render={value => (
            <MetricNumber value={Number(value)}>{({ value }) => value}</MetricNumber>
          )}
        />

        <Column
          title="LT"
          dataIndex="lt"
          align="center"
          key="lt"
          render={value => (
            <MetricNumber value={Number(value)}>
              {({ formatedNumber }) => formatedNumber}
            </MetricNumber>
          )}
        />

        <Column
          title="LTV"
          dataIndex="ltv"
          align="center"
          key="ltv"
          render={value => (
            <MetricNumber prefix="R$⠀" value={Number(value)}>
              {({ formatedNumber }) => formatedNumber}
            </MetricNumber>
          )}
        />
      </Table>
    </GenericCard>
  )
}

export { LtvFormaPagamentoCard }
