import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'

import { typography, medias } from 'styles'

const Container = styled.div`
  display: flex;
  flex-direction: ${({ sideLabel }) => (sideLabel ? 'row' : 'column')};
`

const Text = styled.span`
  font-family: ${typography.fontFamily};
  font-size: ${({ isPrimaryNumber }) => (isPrimaryNumber ? '18px' : '14px')};
  height: 16px;
  line-height: 16px;
  font-weight: 700;
  margin-right: 8px;
  color: ${({ number, isPrimaryNumber }) =>
    Number(number) === 0
      ? 'rgba(255, 255, 255, 0.5)'
      : isPrimaryNumber
      ? '#fff'
      : 'rgba(255, 255, 255, 0.85)'};

  @media (max-width: ${medias.xs}) {
    font-size: 13px;
    margin-right: 0;
  }
`

const Label = styled.span`
  font-size: 11px;
  height: 11px;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.6);
`

const MetricNumber = ({
  isPrimaryNumber,
  isMoney,
  children,
  label,
  sideLabel,
  value,
  prefix,
  suffix,
  style,
}) => {
  if (typeof children !== 'function') return 'Error'

  return (
    <Container sideLabel={sideLabel}>
      {label && <Label>{label}</Label>}
      <Text isPrimaryNumber={isPrimaryNumber} number={value}>
        {children({
          formatedNumber:
            typeof value === 'number' ? (
              <NumberFormat
                style={style}
                isNumericString={isMoney ? true : false}
                value={isMoney ? value.toFixed(2) : Number(value.toFixed(2))}
                thousandSeparator="."
                decimalSeparator=","
                displayType="text"
                prefix={prefix}
                suffix={suffix}
              />
            ) : (
              0
            ),
          value,
        })}
      </Text>
      {sideLabel && <Label>{sideLabel}</Label>}
    </Container>
  )
}

MetricNumber.propTypes = {
  isPrimaryNumber: PropTypes.bool,
  isMoney: PropTypes.bool,
  children: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
}

MetricNumber.defaultProps = {
  isPrimaryNumber: false,
  isMoney: false,
  children: () => {},
  prefix: '',
  suffix: '',
}

export default MetricNumber
