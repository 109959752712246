import i18next from 'i18next'
import httpBackend from 'i18next-http-backend/cjs'
// import { initReactI18next } from 'react-i18next'
import { getLegacyWebsiteUrl } from 'utils'

const defaultLanguage = process.env.REACT_APP_LANGUAGE

const initI18n = () =>
  i18next
    // .use(httpBackend)
    // .use(initReactI18next)
    .init({
      lng: defaultLanguage,
      debug: process.env.NODE_ENV === 'development',
      resources: {
        pt: {
          translation: {
            loading: 'Carregando...',
            auth: {
              changeEmail: 'Trocar e-mail',
              loginTitle: 'Digite seu e-mail',
              passwordTitle: 'Digite sua senha',
              signUpTitle: 'Informe seus dados',
              nextPageText: 'Você tentou acessar a página: {{page}}. sem estar logado',
              email: 'E-mail',
              password: 'Sua senha',
              passwordRequired: 'Digite sua senha para entrar',
              passwordMinLength: 'Sua senha deve ter pelo menos 3 digitos',
              passwordMinLength4: 'Sua senha deve ter pelo menos 4 digitos',
              passwordRequiredSignUp: 'Digite sua senha para se cadastrar',
              emailValid: 'Digite um e-mail válido',
              emailRequired: 'Digite seu e-mail para continuar',
              requireField: 'Campo obrigatório',
              nameRequied: 'Digite nome e sobrenome',
              phoneNumberIncomplete: 'Número de telefone incompleto',
              phoneNumberTooShort: 'Número muito curto. Digite um número válido',
              phoneNumberTooLong: 'Número muito longo. Digite um número válido',
              phoneNumberInvalid: 'Digite um número válido',
              solveCaptcha: 'Resolva o Recaptcha para concluir',
              acceptTerms: 'Você precisa aceitar os termos',
              acceptPrivacy: 'Você precisa aceitar a política de privacidade',
              forgotPassword: 'Esqueceu sua senha?',
              signUpSubtitle: 'Digite as informações abaixo para completar o cadastro:',
              fullName: 'Nome completo',
              cellphone: 'Celular (De preferência WhatsApp)',
              gender: 'Gênero',
              notInformed: 'Não informado',
              male: 'Masculino',
              female: 'Feminino',
              setPassword: 'Crie sua senha',
              signIn: 'Entrar',
              continue: 'Continuar',
              finishSignUp: 'Concluir cadastro',
              updateEmail: 'Atualizar e-mail',
              updateYourPassword: 'Atualize sua senha',
              changeName: 'Alterar nome',
              updateProfile: 'Atualizar perfil',
              currentPhoneNumber: 'Número de celular atual',
              messages: {
                signUpError: 'Não foi possível efetuar o cadastro',
                signUpSuccess: 'Cadastro efetuado com sucesso!',
                signInError: 'E-mail ou senha incorreta',
                signInSuccess: 'Login efetuado com sucesso!',
                updateProfileSuccess: 'Cadastro atualizado com sucesso!',
              },
              phoneIdYes: 'Sim, receber via SMS',
              phoneIdNo: 'Não, fechar',
              smsOtpTitle: 'Faça login com código',
              smsOtpDescription: 'Enviamos um código de 4 digitos para o seu telefone:',
              smsOtpDidntReceive: 'Não recebeu o código?',
              smsOtpResend: 'Reenviar código',
              smsOtpResendIn: 'Reenviar em',
              updateProfileTitle: 'Atualize seu cadastro',
              updateProfileDescription:
                'Confirme suas informações abaixo para atualizar o cadastro:',
            },
          },
        },
        en: {
          translation: {
            loading: 'Loading...',
            auth: {
              changeEmail: 'Change email',
              loginTitle: 'Log in to continue',
              nextPageText: 'You tried to access the page: {{page}}. without being logged in',
              email: 'Email',
              password: 'Your password',
              passwordRequired: 'Enter your password to log in',
              passwordMinLength: 'Your password must have at least 3 digits',
              passwordMinLength4: 'Your password must have at least 4 digits',
              passwordRequiredSignUp: 'Enter your password to sign up',
              emailValid: 'Enter a valid email',
              emailRequired: 'Enter your email to continue',
              requireField: 'Required field',
              nameRequied: 'Enter first and last name',
              phoneNumberIncomplete: 'Incomplete phone number',
              phoneNumberTooShort: 'Too short number. Enter a valid number',
              phoneNumberTooLong: 'Too long number. Enter a valid number',
              phoneNumberInvalid: 'Enter a valid number',
              solveCaptcha: 'Solve the Recaptcha to complete',
              acceptTerms: 'You need to accept the terms',
              acceptPrivacy: 'You need to accept the privacy policy',
              forgotPassword: 'Forgot your password?',
              signUpSubtitle: 'Enter the information below to complete the registration:',
              fullName: 'Full name',
              cellphone: 'Cellphone (Preferably WhatsApp)',
              gender: 'Gender',
              notInformed: 'Not informed',
              male: 'Male',
              female: 'Female',
              setPassword: 'Create your password',
              signIn: 'Sign In',
              continue: 'Continue',
              finishSignUp: 'Finish registration',
              updateEmail: 'Update email',
              updateYourPassword: 'Update your password',
              changeName: 'Change name',
              updateProfile: 'Update profile',
              currentPhoneNumber: 'Current phone number',
              messages: {
                signUpError: 'Could not register',
                signUpSuccess: 'Registration successful!',
                signInError: 'Incorrect email or password',
                signInSuccess: 'Login successful!',
                updateProfileSuccess: 'Profile updated successfully!',
              },
              phoneIdYes: 'Yes, receive via SMS',
              phoneIdNo: 'No, close',
              smsOtpTitle: 'Log in with code',
              smsOtpDescription: 'We sent a 4-digit code to your phone:',
              smsOtpDidntReceive: "Didn't receive the code?",
              smsOtpResend: 'Resend code',
              smsOtpResendIn: 'Resend in',
              updateProfileTitle: 'Update your profile',
              updateProfileDescription: 'Confirm your information below to update your profile:',
            },
          },
        },
        es: {
          translation: {
            auth: {
              changeEmail: 'Cambiar email',
              loginTitle: 'Inicia sesión para continuar',
              nextPageText: 'Intentaste acceder a la página: {{page}}. sin estar registrado',
              email: 'Correo electrónico',
              password: 'Tu contraseña',
              passwordRequired: 'Ingresa tu contraseña para iniciar sesión',
              passwordMinLength: 'Tu contraseña debe tener al menos 3 dígitos',
              passwordMinLength4: 'Tu contraseña debe tener al menos 4 dígitos',
              passwordRequiredSignUp: 'Ingresa tu contraseña para registrarte',
              emailValid: 'Ingresa un correo electrónico válido',
              emailRequired: 'Ingresa tu correo electrónico para continuar',
              requireField: 'Campo obligatorio',
              nameRequied: 'Ingresa nombre y apellido',
              phoneNumberIncomplete: 'Número de teléfono incompleto',
              phoneNumberTooShort: 'Número demasiado corto. Ingresa un número válido',
              phoneNumberTooLong: 'Número demasiado largo. Ingresa un número válido',
              phoneNumberInvalid: 'Ingresa un número válido',
              solveCaptcha: 'Resuelve el Recaptcha para completar',
              acceptTerms: 'Debes aceptar los términos',
              acceptPrivacy: 'Debes aceptar la política de privacidad',
              forgotPassword: '¿Olvidaste tu contraseña?',
              signUpSubtitle: 'Ingresa la información a continuación para completar el registro:',
              fullName: 'Nombre completo',
              cellphone: 'Teléfono celular (Preferiblemente WhatsApp)',
              gender: 'Género',
              notInformed: 'No informado',
              male: 'Masculino',
              female: 'Femenino',
              setPassword: 'Crea tu contraseña',
              signIn: 'Iniciar sesión',
              continue: 'Continuar',
              finishSignUp: 'Finalizar registro',
              updateEmail: 'Actualizar correo electrónico',
              updateYourPassword: 'Actualizar tu contraseña',
              changeName: 'Cambiar nombre',
              updateProfile: 'Actualizar perfil',
              currentPhoneNumber: 'Número de teléfono actual',
              messages: {
                signUpError: 'No se pudo registrar',
                signUpSuccess: '¡Registro exitoso!',
                signInError: 'Correo electrónico o contraseña incorrecta',
                signInSuccess: '¡Inicio de sesión exitoso!',
                updateProfileSuccess: '¡Perfil actualizado con éxito!',
              },
              phoneIdYes: 'Sí, recibir por SMS',
              phoneIdNo: 'No, cerrar',
              smsOtpTitle: 'Inicia sesión con código',
              smsOtpDescription: 'Enviamos un código de 4 dígitos a tu teléfono:',
              smsOtpDidntReceive: '¿No recibiste el código?',
              smsOtpResend: 'Reenviar código',
              smsOtpResendIn: 'Reenviar en',
              updateProfileTitle: 'Actualiza su registro',
              updateProfileDescription:
                'Confirma tu información a continuación para actualizar tu perfil:',
            },
          },
        },
      },
    })

export default initI18n
