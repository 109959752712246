const colors = {
  primaryColor: process.env.REACT_APP_PRIMARY_COLOR,
  btnPrimaryColor: process.env.REACT_APP_BUTTON_PRIMARY_COLOR,
  defaultBackground: process.env.REACT_APP_BASE_BACKGROUND_COLOR,
  componentBackground: process.env.REACT_APP_FOREGROUND_COLOR,
  headerBackground: process.env.REACT_APP_HEADER_BACKGROUND_COLOR,
  lightBackground: process.env.REACT_APP_SECONDARY_BACKGROUND_COLOR,
  lighterBackground: process.env.REACT_APP_TERTIARY_BACKGROUND_COLOR,
  darkBackground: '#0e0e0e',
  defaultTextColor: process.env.REACT_APP_BASE_TEXT_COLOR,
  textColorSecondary: process.env.REACT_APP_SECONDARY_TEXT_COLOR,
  tableBorderColor: '#535454',
  disabledBg: '#808080',
  disabledColor: 'rgba(0, 0, 0, 0.6)',
  messageBg: process.env.REACT_APP_TERTIARY_BACKGROUND_COLOR,
  lightBorderColor: 'rgba(255, 255, 255, 0.45)',
  green: '#60E448',
  lightGreen: '#8bc34a',
  orange: '#ff5722',
  blue: '#03a9f4',
  red: '#f11f1f',
}

const typography = {
  fontFamily: '"Montserrat", sans-serif',
  fontText:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  textSize: '16px',
  textSizeSmall: '14px',
}

const sizes = {
  defaultBorderRadius: '8px',
  smallBorderRadius: '4px',
  heightBase: '48px',
  heightLg: '64px',
  heightSm: '40px',
}

const medias = {
  xs: '480px',
  sm: '768px',
  md: '1024px',
  lg: '1400px',
  xl: '1920px',
}

const replaceCamelCaseWithDash = str => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

const applyColorsVars = () => {
  for (let key in colors) {
    document.documentElement.style.setProperty(
      `--${replaceCamelCaseWithDash(key)}`,
      `${colors[key]}`
    )
  }
}

module.exports = {
  colors,
  typography,
  sizes,
  medias,
  applyColorsVars,
}
