import styled from 'styled-components'
import { Button, Menu } from 'antd'

import { colors, medias } from 'styles'

export const Header = styled.header`
  background-color: ${colors.headerBackground};
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;

  @media (max-width: ${medias.xs}) {
    height: 88px;
  }
`

export const LeftCol = styled.div`
  display: flex;
  align-items: center;
  width: 30%;

  & > img {
    margin-left: 1rem;
  }

  @media (max-width: ${medias.xs}) {
    & > img {
      margin-left: 4px;
    }
  }

  @media (max-width: ${medias.md}) {
    width: 60%;
  }
`

export const CenterCol = styled.div`
  width: 35%;
  display: flex;
  algin-items: center;

  .ant-input-search,
  .input-search-mobile {
    border-width: 2px !important;
  }

  .ant-input-suffix {
    display: flex;
    align-items: center;
  }

  .ant-input-search-icon {
    & > svg {
      width: 32px;
      height: 21px;
    }

    &:hover {
      color: ${colors.primaryColor};
    }
  }

  @media (max-width: ${medias.md}) {
    display: none;
  }
`

export const RightCol = styled.div`
  width: 30%;

  .search-item {
    display: none;
  }

  .top-menu {
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
  }

  @media (max-width: ${medias.md}) {
    width: 40%;

    .search-item {
      display: block;
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
      padding: 0 8px;
      display: inline-block;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
      margin: 0 12px;
    }
  }
`

export const SideMenuButton = styled(Button)`
  border: 0;
  background-color: ${colors.headerBackground};

  & > svg {
    font-size: 32px !important;
  }
`

export const StyledMenu = styled(Menu)`
  border: 0;
  background-color: ${colors.headerBackground};

  .ant-menu-item,
  .ant-menu-item-active,
  .ant-menu-item-selected {
    border-bottom: 0 !important;
  }

  & .ant-menu-submenu,
  & ant-menu-submenu-active {
    border-bottom: 0 !important;
  }
`

export const SubMenuLink = styled.a`
  display: flex !important;
  align-items: center;

  & > svg {
    margin-right: 0.5rem;
  }
`

export const CenteredBrand = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
`
