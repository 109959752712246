import React, { useState, useMemo } from 'react'
import { Table } from 'antd'
import { useFetch } from 'use-http'
import _ from 'lodash'

import GenericCard from '../../generic-card'
import MetricNumber from '../../metric-number'
import DateRangePicker from '../../range-picker'

import { createAdminGetUrl } from 'utils'
import TrendInfo from '../../trend-info'
import AlignedTableColumn from '../../aligned-table-column'

const { Column } = Table

const MatriculasPorPlano = () => {
  const [dateRange, setDateRange] = useState({
    startDate: '01%2F01%2F1900',
    endDate: '01%2F01%2F1900',
  })

  const { data, loading, error } = useFetch(
    createAdminGetUrl(
      'DashMKTVendasMatriculasporPlano',
      `${dateRange.startDate}/${dateRange.endDate}`
    ),
    null,
    [dateRange]
  )

  const dataWithTotal = useMemo(() => {
    if (!data || !data.lista) return []

    return [
      ...data.lista,
      {
        plano: 'Total',
        matriculas: _.sumBy(data.lista, i => i.matriculas),
        ativas: _.sumBy(data.lista, i => i.ativas),
        boleto: _.sumBy(data.lista, i => i.boleto),
        boleto_valor: _.sumBy(data.lista, i => Number(i.boleto_valor)),
        cartao: _.sumBy(data.lista, i => i.cartao),
        cartao_valor: _.sumBy(data.lista, i => Number(i.cartao_valor)),
      },
    ]
  }, [data])

  const filters = useMemo(
    () =>
      !!dataWithTotal ? dataWithTotal.map(item => ({ text: item.plano, value: item.plano })) : [],
    [data]
  )

  return (
    <>
      <GenericCard
        error={!!error}
        loading={!!loading}
        title="Matrículas por plano"
        rightComponent={
          <DateRangePicker
            initialValues={{ startDate: data?.inicio, endDate: data?.fim }}
            onDateSelection={setDateRange}
          />
        }
      >
        <Table
          size="small"
          pagination={false}
          dataSource={dataWithTotal}
          pagination={{ defaultPageSize: 9, showSizeChanger: false }}
        >
          <Column
            title="Plano"
            dataIndex="plano"
            key="plano"
            className="small-font"
            filters={filters}
            onFilter={(value, record) => record.plano === value}
          />
          <Column
            title="Matrículas"
            dataIndex="matriculas"
            align="center"
            key="matriculas"
            render={value => (
              <MetricNumber value={Number(value)}>
                {({ formatedNumber }) => formatedNumber}
              </MetricNumber>
            )}
          />
          <Column
            title="Ativas"
            dataIndex="ativas"
            align="center"
            key="ativas"
            render={(value, row) => (
              <AlignedTableColumn>
                <MetricNumber value={Number(value)}>
                  {({ formatedNumber }) => formatedNumber}
                </MetricNumber>
                {row.ativas_perc && <TrendInfo info={row.ativas_perc} isSmall />}
              </AlignedTableColumn>
            )}
          />
          <Column
            title="Boletos"
            dataIndex="boleto"
            align="center"
            key="boleto"
            render={(value, row) => (
              <AlignedTableColumn width={[90, 90, 150]}>
                <MetricNumber value={Number(value)}>
                  {({ formatedNumber }) => formatedNumber}
                </MetricNumber>
                {row.boleto_perc && <TrendInfo info={row.boleto_perc} isSmall />}
                {row.boleto_perc && (
                  <MetricNumber prefix="R$⠀" isMoney value={Number(row.boleto_valor)}>
                    {({ formatedNumber }) => formatedNumber}
                  </MetricNumber>
                )}
              </AlignedTableColumn>
            )}
          />
          <Column
            title="Cartão"
            dataIndex="cartao"
            align="center"
            key="cartao"
            render={(value, row) => (
              <AlignedTableColumn width={[90, 90, 150]}>
                <MetricNumber value={Number(value)}>
                  {({ formatedNumber }) => formatedNumber}
                </MetricNumber>
                {row.cartao_perc && <TrendInfo info={row.cartao_perc} isSmall />}
                {row.cartao_perc && (
                  <MetricNumber prefix="R$⠀" isMoney value={Number(row.cartao_valor)}>
                    {({ formatedNumber }) => formatedNumber}
                  </MetricNumber>
                )}
              </AlignedTableColumn>
            )}
          />
        </Table>
      </GenericCard>
    </>
  )
}

export { MatriculasPorPlano }
