import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { AppContext } from 'context/app-context'
import { getLegacyWebsiteUrl } from 'utils'

const PrivateRoute = ({ children, ...rest }) => {
  const { user } = useContext(AppContext)

  if (user?.isAuth === false) {
    const websiteUrl = getLegacyWebsiteUrl()

    return (window.location.href = `${websiteUrl}/login?redirect=${rest.location.pathname}`)
  }

  return <Route {...rest}>{children}</Route>
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

PrivateRoute.defaultProps = {}

export default PrivateRoute
