import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, Input, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faUserCircle,
  faSignOutAlt,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import Cookie from 'js-cookie'

import { Brand } from 'components'

import * as S from './styles'

const Header = ({ onSideMenuButton, cleanHeader }) => {
  const [searchBar, setSearchBar] = useState(false)
  const [searchQuery, setQuery] = useState('')

  const avatar = (
    <div>
      <FontAwesomeIcon icon={faUserCircle} size="2x" />
    </div>
  )

  const handleSearchSubmit = () =>
    (window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/busca?q=${searchQuery}`)

  const handleLogout = e => {
    e.preventDefault()

    Cookie.remove('user')
    Cookie.remove('userName')
    Cookie.remove('token')
    window.localStorage.removeItem('subscription')
    window.location.href = process.env.REACT_APP_WEBSITE_URL
  }

  return cleanHeader ? (
    <S.Header>
      <S.CenteredBrand>
        <Brand cleanHeader={cleanHeader} />
      </S.CenteredBrand>
    </S.Header>
  ) : (
    <S.Header>
      {!searchBar && (
        <S.LeftCol>
          <S.SideMenuButton
            type="text"
            shape="square"
            onClick={onSideMenuButton}
            icon={<FontAwesomeIcon icon={faBars} size="3x" />}
          ></S.SideMenuButton>
          <Brand />
        </S.LeftCol>
      )}

      <S.CenterCol style={searchBar ? { display: 'flex', width: '100%' } : {}}>
        {searchBar && (
          <Button style={{ border: 0 }} shape="square" onClick={() => setSearchBar(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}

        {searchBar ? (
          <Input
            className="input-search-mobile"
            style={{ margin: '0 8px' }}
            placeholder="Pesquise por cursos ou professores"
            value={searchQuery}
            onChange={e => setQuery(e.target.value)}
            onPressEnter={handleSearchSubmit}
            allowClear
          />
        ) : (
          <Input.Search
            value={searchQuery}
            onChange={e => setQuery(e.target.value)}
            placeholder="Pesquise por cursos ou professores"
            onSearch={handleSearchSubmit}
            allowClear
          />
        )}

        {searchBar && (
          <Button type="primary" shape="square" onClick={() => setSearchBar(false)}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        )}
      </S.CenterCol>

      {!searchBar && (
        <S.RightCol>
          <S.StyledMenu mode="horizontal" className="top-menu">
            <Menu.SubMenu title={avatar} className="menu-avatar">
              <Menu.Item key="submenu:1">
                <S.SubMenuLink href={`${process.env.REACT_APP_WEBSITE_URL}/perfil`}>
                  <FontAwesomeIcon icon={faUserCircle} size="lg" />
                  Perfil
                </S.SubMenuLink>
              </Menu.Item>
              <Menu.Item key="submenu:2">
                <S.SubMenuLink onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
                  Sair
                </S.SubMenuLink>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item className="search-item" key="menu-item:1" onClick={() => setSearchBar(true)}>
              <div style={{ display: 'inline-flex', alignItems: 'center', height: '48px' }}>
                <FontAwesomeIcon icon={faSearch} size="2x" />
              </div>
            </Menu.Item>
          </S.StyledMenu>
        </S.RightCol>
      )}
    </S.Header>
  )
}

Header.propTypes = {
  cleanHeader: PropTypes.bool,
}

Header.defaultProps = {
  cleanHeader: false,
}

export default Header
