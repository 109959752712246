export default {
  search: 'Buscar',
  clear: 'Limpar', // Accessible label on "clear" button
  notfound: 'Nenhum emoji encontrado',
  skintext: 'Escolha o tom de pele padrão',
  categories: {
    search: 'Resultados da busca',
    recent: 'Usados frequentemente',
    smileys: 'Sorrisos e emoção',
    people: 'Pessoas',
    nature: 'Animais e natureza',
    foods: 'Comida e bebida',
    activity: 'Atividades',
    places: 'Viajem e lugares',
    objects: 'Objetos',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
    custom: 'Personalizado',
  },
  categorieslabel: 'Categorias de Emojis', // Accessible title for the list of categories
  skintones: {
    1: 'Tom de pele padrão',
    2: 'Tom de pele claro',
    3: 'Tom de pele médio-claro',
    4: 'Tom de pele médio',
    5: 'Tom de pele médio escuro',
    6: 'Tom de pele escura',
  },
}
