import { Input, Select } from 'antd'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useListClasses } from '../../../hooks/community/use-list-classes'
import { useCommunityStore } from '../../../stores/community'

import * as S from './styles'

export const PostListFilters = ({ placeholder }) => {
  const { courseId } = useParams()

  const classes = useListClasses({ courseId })

  const posts = useCommunityStore(state => state.posts)
  const setFiltedPosts = useCommunityStore(state => state.setFiltedPosts)

  const filter = (filterParams, filterFunction) => {
    if (!filterParams) return setFiltedPosts(null)

    const result = filterFunction(filterParams, posts)

    setFiltedPosts(result.length ? result : [])
  }

  const handleSearch = searchTerm => {
    filter(searchTerm, filterPostByContentSearch)
  }

  const handleFilterByClassId = classId => {
    filter(classId, filterPostByClassId)
  }

  const classesFilterOptions = useMemo(() => classesToSelectOptions(classes.data || []), [
    classes.data,
  ])

  return (
    <S.Filters>
      <S.Search>
        <Input.Search onSearch={handleSearch} size="small" placeholder={placeholder} allowClear />
      </S.Search>
      {/*
      <S.SelectsRow>
        <S.Column>
          {classId === '0' && (
            <Select
              loading={classes.loading}
              size="small"
              placeholder="Filtrar por aula"
              options={classesFilterOptions}
              style={{ minWidth: 300 }}
              onChange={handleFilterByClassId}
            />
          )}
        </S.Column>
      </S.SelectsRow> */}
    </S.Filters>
  )
}

function filterPostByContentSearch(searchTerm, posts) {
  const searchRegex = new RegExp(searchTerm.toLowerCase())
  return posts.filter(post => post.texto.toLowerCase().match(searchRegex))
}

function filterPostByClassId(classId, posts) {
  return posts.filter(post => post.id_aula === classId)
}

function classesToSelectOptions(classes) {
  if (!Array.isArray(classes) && classes.length === 0) return []

  const options = classes.map(classItem => {
    if (!classItem) return {}
    const [module] = classItem
    if (!module) return {}
    return {
      label: module.nome_modulo,
      options: classItem.map(item => ({ label: item.nome_aula, value: item.id_aula })),
    }
  })

  return [{ label: 'Todas as aulas', value: null }, ...options]
}
