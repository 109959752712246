import { message } from 'antd'
import useFetch from 'use-http'
import { createPostUrl, getAuthData } from '../../utils'

export const useReportPost = ({ postId }) => {
  const fetch = useFetch(createPostUrl(''), null)

  const submit = async () => {
    try {
      await fetch.post('/ReportaPublicacao', {
        ...getAuthData(),
        id_post: postId,
      })

      message.success('Obrigado por reportar. Os administradores irão avaliar a postagem.')
    } catch (e) {
      message.error('Ocorreu um erro ao tentar reportar a postagem')
    }
  }

  return [submit, fetch]
}
