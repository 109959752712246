import styled, { css, createGlobalStyle } from 'styled-components'
import { Link } from 'react-router-dom'
import { colors, typography } from 'styles'

export const Overwrites = createGlobalStyle`
  .ant-dropdown-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  button:not(.ant-btn-primary) {
    opacity: 0.65;

    &:hover {
      opacity: 1;
    }
  }
`

export const Card = styled.div`
  position: relative;
  padding: 16px;
  font-family: ${typography.fontText};

  ${({ isComment, isTeacherComment }) =>
    isComment
      ? css`
          border-left: 2px solid ${isTeacherComment ? '#2ef30a' : 'rgba(255, 255, 255, 0.15)'};
          background: transparent;
          border-radius: 0;
          padding: 4px 16px;
        `
      : css`
          background: ${colors.componentBackground};
          border-radius: 8px;
        `}
`

export const Content = styled.h4`
  font-family: ${typography.fontFamily};
  color: ${colors.defaultTextColor};
  font-weight: 400;
  font-size: ${({ isComment }) => (isComment ? '14px' : '18px')};
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: ${({ isComment }) => (isComment ? '4px' : '8px')};
  margin-left: ${({ isComment }) => (isComment ? '0' : '40px')};
  padding-right: 32px;
`

export const MoreButtton = styled.div`
  position: absolute;
  right: 16px;

  ${({ isComment }) =>
    isComment
      ? css`
          bottom: 2px;
          right: 0;
        `
      : css`
          top: 16px;
        `}
`

export const TeacherResponseTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2ef30a;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const FooterColumn = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: ${({ justify }) => justify};
  padding-right: ${({ isComment }) => (isComment ? '32px' : '0')};
`

export const TextDivider = styled.div`
  width: 20px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);
    top: calc(50% - 2px);
    left: calc(50% - 2px);
  }
`

export const Author = styled.div`
  margin-left: 8px;
  color: ${colors.defaultTextColor};
  font-size: ${({ avatarSize }) => (avatarSize === 'sm' ? 12 : 14)}px;
  line-height: 16px;
`

export const Teacher = styled.div`
  color: ${colors.textColorSecondary};
  font-size: 12px;
  line-height: 16px;
`

export const Date = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.textColorSecondary};
`

export const ClassText = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.primaryColor};
`

export const ButtonLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
  font-weight: normal;
`

export const RightCol = styled.div`
  display: flex;
  align-items: center;
`
