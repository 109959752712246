import useFetch from 'use-http'
import { createPostUrl, getAuthData } from '../../utils'

export const useLikePost = ({ postId }) => {
  const fetch = useFetch(createPostUrl(''), null)

  const submit = liked =>
    fetch.post('/GravaLike', {
      ...getAuthData(),
      id_post: postId,
      tipo: liked ? '1' : '0',
    })

  return [submit, fetch]
}
