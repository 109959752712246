import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Typography, Card, Row, Col, message, Modal, Button } from 'antd'
import { useLocation } from 'react-router-dom'
import useFetch from 'use-http'
import Cookie from 'js-cookie'

import {
  BaseTemplate,
  Container,
  EmailValidationForm,
  PasswordForm,
  RegisterForm,
  SmsOtp,
  UpdateProfile,
} from 'components'
import { getTokenApp, createPostUrl, getLegacyWebsiteUrl, cleanPhoneNumber } from 'utils'
import { IS_FALSE, POST_MESSAGES_ACTIONS } from 'enums'
import { useI18n } from 'context/i18n'

const AUTH_STATUS = {
  INITIAL: 'initial',
  SHOW_REGISTER: '0',
  SHOW_LOGIN: '1',
  SMS_OTP: '2',
  UPDATE_PROFILE: '3',
}

const tokenApp = getTokenApp()

const AuthPage = ({ title, isRegisterUrl }) => {
  const { t } = useI18n()
  const { search, pathname } = useLocation()
  const [pageMetadata, setPageMetadata] = useState({})

  const [status, setStatus] = useState(AUTH_STATUS.INITIAL)
  const [correctEmail, setCorrectEmail] = useState()
  const [infoLogin, setInfoLogin] = useState()
  const [phoneIdentityModal, setPhoneIdModal] = useState(false)
  const [userIsNotPhoneId, setUserIsNotPhoneId] = useState(false)
  const [loginTitle, setLoginTitle] = useState(t('auth.loginTitle'))
  const [shouldShowSMSLogin, setShouldShowSMSLogin] = useState(false)

  const searchParams = useMemo(() => new URL(window.location.href).searchParams, [search])

  const tabTitle = pageMetadata.urlTitle || title
  const EMAIL_VALIDATION_URL = `${process.env.REACT_APP_API_URL}PreValidaEmail/${tokenApp}`
  const POLICIES_URL = `${process.env.REACT_APP_API_URL}CarregaTermosPoliticas/${tokenApp}`
  const PHONE_VALIDATION_URL = `${process.env.REACT_APP_API_URL}ValidaTelefone/${tokenApp}`
  const INFO_LOGIN_URL = `${process.env.REACT_APP_API_URL}InfoLogin/${tokenApp}`

  const { get: getInfoLogin, response: infoLoginResponse, loading: infoLoginLoading } = useFetch(
    INFO_LOGIN_URL,
    null
  )

  useEffect(() => {
    if (!correctEmail || status === AUTH_STATUS.INITIAL) {
      setLoginTitle(t('auth.loginTitle'))
    }

    if (correctEmail && status === AUTH_STATUS.SHOW_LOGIN) {
      setLoginTitle(t('auth.passwordTitle'))
    }

    if (correctEmail && status === AUTH_STATUS.SHOW_REGISTER) {
      setLoginTitle(t('auth.signUpTitle'))
    }
  }, [correctEmail, status])

  useEffect(() => {
    if (searchParams.has('afiliado')) {
      Cookie.set('signup-token-afiliado', searchParams.get('afiliado'), {
        expires: 180,
        sameSite: 'strict',
      })
      searchParams.delete('afiliado')
    }

    const metadataPayload = JSON.stringify(
      Object.fromEntries(searchParams.entries()),
      (key, value) => {
        if (key === 'nextSearchParams') {
          return new URLSearchParams(JSON.parse(value)).toString()
        }
        if (value !== null) {
          return value
        }
      }
    )

    if (searchParams.has('next') && searchParams.get('next').match(/checkout/)) {
      getInfoLogin(Cookie.get('checkout-token-plan')).then(data => {
        if (infoLoginResponse.ok) {
          setInfoLogin(data)
        }
      })
    }
    setPageMetadata(JSON.parse(metadataPayload))
  }, [searchParams])

  const {
    get: getEmailValidation,
    response: emailValidationResponse,
    loading: emailValidationLoading,
  } = useFetch(EMAIL_VALIDATION_URL, null)

  const { data: policies, loading: policiesLoading } = useFetch(POLICIES_URL, [])

  const { post: sendLogin, response: loginResponse, loading: loginLoading } = useFetch(
    createPostUrl('ValidaLogin'),
    null
  )

  const { post: registerUser, response: registerResponse, loading: registerLoading } = useFetch(
    createPostUrl('CadastrarRapidoAluno'),
    null
  )

  const {
    get: validatePhone,
    response: phoneValidation,
    loading: isValidatingPhone,
    data: phoneValidationState,
  } = useFetch(PHONE_VALIDATION_URL, null)

  const passAuthDataToParentPage = authData => {
    window.parent.postMessage(
      { action: POST_MESSAGES_ACTIONS.AUTH_DONE, isFromApp: true, state: authData },
      getLegacyWebsiteUrl()
    )
  }

  const handleEmbedLinkClick = url => {
    window.parent.postMessage(
      { action: POST_MESSAGES_ACTIONS.CHANGE_URL, isFromApp: true, state: url },
      getLegacyWebsiteUrl()
    )
  }

  const redirectUser = goToUrl => {
    if (pageMetadata.embed) return

    let redirectUrl = getLegacyWebsiteUrl()

    if (goToUrl) redirectUrl = goToUrl

    if (pageMetadata.next)
      redirectUrl = `${getLegacyWebsiteUrl()}${pageMetadata.next || ''}${
        pageMetadata.nextSearchParams ? '?' + pageMetadata.nextSearchParams : ''
      }`

    window.location.href = redirectUrl
  }

  const storeAuthCookies = responseData => {
    if (pageMetadata.embed) return passAuthDataToParentPage(responseData)

    const { nome, status, token } = responseData

    const now = new Date()
    const expriresAfterAYear = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())

    Cookie.set('token', token, { expires: expriresAfterAYear, sameSite: 'lax' })
    Cookie.set('user', status, { expires: expriresAfterAYear, sameSite: 'lax' })
    Cookie.set('userName', nome, { expires: expriresAfterAYear, sameSite: 'lax' })
  }

  const handleRegisterSubmit = async ({ name, phone, password, gender, country, tokenCap }) => {
    const registerPayload = {
      token_afiliado: Cookie.get('signup-token-afiliado') || '',
      token_afiliado_global: Cookie.get(`${process.env.REACT_APP_APP_ID}_afiliado_global`) || '', // TODO get global affiliate token
      paiscelular: country,
      token_app: tokenApp,
      email: correctEmail,
      senha: password,
      nome: name,
      celular: phone,
      sexo: gender,
    }

    const [data] = await registerUser(registerPayload)

    if (registerResponse.ok) {
      if (data.token === IS_FALSE) {
        return message.error(t('auth.messages.signUpError'))
      }

      Cookie.remove('signup-token-afiliado')

      message.success(t('auth.messages.signUpSuccess'))
      storeAuthCookies({ nome: data.nome, status: data.status_usuario, token: data.token_sessao })

      const [fn, ln] = name.split(' ')

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'generate_lead',
        affiliation: Cookie.get('signup-token-afiliado') || '',
        user_data: {
          em: correctEmail,
          fn,
          ln: ln || '',
          ph: data.telefonecomddi,
          user_id: data.idaluno,
        },
      })

      if (pathname.match(/cadastro/)) {
        redirectUser(data.url_destino)
      } else {
        redirectUser()
      }
    }
  }

  const handleLoginSubmit = async password => {
    try {
      const authPayload = {
        token_app: tokenApp,
        email: correctEmail,
        senha: password,
      }

      const [data] = await sendLogin(authPayload)

      if (loginResponse.ok) {
        if (data.token === IS_FALSE) {
          return message.error(t('auth.messages.signInError'))
        }

        message.success(t('auth.messages.signInSuccess'))
        storeAuthCookies(data)
        redirectUser()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleEmailValidation = async email => {
    const [data] = await getEmailValidation(encodeURIComponent(email))

    if (emailValidationResponse.ok) {
      setStatus(data.status)
      setCorrectEmail(data.email)
    }
  }

  const handleChangeEmail = () => {
    setCorrectEmail(null)
    setStatus(AUTH_STATUS.INITIAL)
  }

  const handlePhoneBlur = async phoneNumber => {
    if (isValidatingPhone || cleanPhoneNumber(phoneNumber).length < 8 || userIsNotPhoneId) return

    const validation = await validatePhone(cleanPhoneNumber(phoneNumber))

    if (!phoneValidation.ok) return
    if (!validation.encontrado) return

    setCorrectEmail(validation.email)
    setPhoneIdModal(true)
  }

  const handleSuccessOtpSubmit = data => {
    storeAuthCookies({ nome: data.nome, status: data.status, token: data.token_sessao })

    setTimeout(() => {
      setStatus(AUTH_STATUS.UPDATE_PROFILE)
      setLoginTitle(t('auth.updateProfileTitle'))
    }, 500)
  }

  const handleSuccessUpdateProfile = () => {
    redirectUser()
  }

  return (
    <BaseTemplate
      cleanHeader={isRegisterUrl}
      removeHeaderAndFooter={!!pageMetadata.embed}
      removeFooter={isRegisterUrl}
    >
      <Helmet title={tabTitle} />
      <Container>
        <Row style={{ minHeight: '70vh' }} gutter={[16, 48]}>
          <Col
            xs={{ span: 24, push: 0 }}
            sm={{ span: 24, push: 0 }}
            md={{ span: 14, push: 5 }}
            lg={{ span: 14, push: 5 }}
            xl={{ span: 10, push: 7 }}
          >
            {pageMetadata.nextPageTitle && (
              <Typography.Text type="secondary" style={{ textAlign: 'center', display: 'block' }}>
                {t('auth.nextPageText', { page: pageMetadata.nextPageTitle })}
              </Typography.Text>
            )}

            {!infoLoginLoading && (
              <Typography.Title level={1} align="center" style={{ marginTop: 16 }}>
                {infoLogin?.titulo_login || loginTitle}
              </Typography.Title>
            )}

            {!infoLoginLoading && infoLogin?.html_login && (
              <Typography.Text type="secondary" style={{ textAlign: 'center', display: 'block' }}>
                <div dangerouslySetInnerHTML={{ __html: infoLogin.html_login }}></div>
              </Typography.Text>
            )}

            <Card>
              {(status === AUTH_STATUS.INITIAL ||
                status === AUTH_STATUS.SHOW_LOGIN ||
                status === AUTH_STATUS.SHOW_REGISTER) && (
                <EmailValidationForm
                  onSubmit={handleEmailValidation}
                  onChangeEmail={handleChangeEmail}
                  correctEmail={correctEmail}
                  loading={emailValidationLoading}
                />
              )}

              {status === AUTH_STATUS.SHOW_LOGIN && (
                <PasswordForm
                  onSubmit={handleLoginSubmit}
                  loading={loginLoading}
                  embed={!!pageMetadata.embed}
                  handleEmbedLinkClick={handleEmbedLinkClick}
                  shouldShowSMSLogin={shouldShowSMSLogin}
                  onSmsLoginClick={() => {
                    setLoginTitle(t('auth.smsOtpTitle'))
                    setStatus(AUTH_STATUS.SMS_OTP)
                  }}
                />
              )}

              {status === AUTH_STATUS.SHOW_REGISTER && (
                <RegisterForm
                  onSubmit={handleRegisterSubmit}
                  loading={registerLoading}
                  policies={policies}
                  policiesLoading={policiesLoading}
                  onPhoneBlur={handlePhoneBlur}
                />
              )}

              {status === AUTH_STATUS.SMS_OTP && (
                <SmsOtp
                  phoneNumber={phoneValidationState?.telefone}
                  email={phoneValidationState?.email}
                  onSuccessOtpSubmit={handleSuccessOtpSubmit}
                />
              )}

              {status === AUTH_STATUS.UPDATE_PROFILE && (
                <UpdateProfile onSuccess={handleSuccessUpdateProfile} />
              )}
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        visible={phoneIdentityModal}
        closable={false}
        footer={[
          <Button
            onClick={() => {
              setUserIsNotPhoneId(true)
              setPhoneIdModal(false)
            }}
          >
            Não, fechar
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              setPhoneIdModal(false)
              setShouldShowSMSLogin(true)
              setStatus(AUTH_STATUS.SHOW_LOGIN)
            }}
          >
            Sim, sou eu
          </Button>,
        ]}
      >
        <Typography.Title level={3} align="center">
          {phoneValidationState?.frase}
        </Typography.Title>
      </Modal>
    </BaseTemplate>
  )
}

AuthPage.propTypes = {
  title: PropTypes.string.isRequired,
  isRegisterUrl: PropTypes.bool,
}

AuthPage.defaultProps = {
  isRegisterUrl: false,
}

export default AuthPage
