import styled from 'styled-components'

import { colors, typography } from 'styles'

export const Container = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const Button = styled.button`
  padding: 0;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0);
  appearance: none;
  box-shadow: ${({ selected }) => (selected ? ` 0 0 0 2px ${colors.primaryColor}` : 0)};
  width: 48px;
  height: 48px;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    box-shadow: ${({ selected }) => (selected ? 0 : `0 0 0 2px rgba(255, 255, 255, 0.5)`)};
  }
`

export const Label = styled.label`
  color: rgba(255, 255, 255, 0.7);
  font-size: ${typography.textSizeSmall};
  height: 16px;
  line-height: 16px;
  margin-bottom: 8px;
`
