import { Avatar, Button, Dropdown, Menu, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCertificate,
  faEllipsisV,
  faCommentDots,
  faTrash,
  faExclamationTriangle,
  faReply,
} from '@fortawesome/free-solid-svg-icons'

import * as S from './styles'
import { PostLikeButton } from '../post-like-button'
import { PostSubmitForm } from '../post-submit-form'
const isPlural = length => length !== 1

export const Card = ({
  postId,
  content,
  photo,
  className,
  authorName,
  createdDate,
  likes,
  liked,
  submitInputPlaceholder,
  isComment,
  responsesCount,
  isTeacherComment,
  isPostAuthor,
  isDeleting,
  isReporting,
  isSubmittigPost,
  hasTeacherResponse,
  canLike,
  onDeletePost,
  onReportPost,
  onSubmitPost,
  footer,
  mention,
  avatarSize = 'md',
}) => {
  const [responseInput, setResponseInput] = useState(false)

  const onResponseClick = () => setResponseInput(!responseInput)

  const responseLabel = isPlural(responsesCount) ? 'respostas' : 'resposta'

  return (
    <S.Card isComment={isComment} isTeacherComment={isTeacherComment} id={postId}>
      <S.Overwrites />
      {hasTeacherResponse && (
        <S.TeacherResponseTitle>
          <Space direction="horizontal" size={2}>
            <FontAwesomeIcon icon={faCertificate} /> <span>Contém resposta do professor</span>
          </Space>
        </S.TeacherResponseTitle>
      )}

      <S.FooterColumn>
        <Avatar src={photo} size={avatarSize === 'sm' ? 24 : 32}>
          {authorName?.substring(0, 1)}
        </Avatar>

        <S.Author avatarSize={avatarSize}>{authorName}</S.Author>

        {isTeacherComment ? (
          <>
            <S.TextDivider />
            <S.Teacher>
              <FontAwesomeIcon icon={faCertificate} style={{ marginRight: 4 }} />
              Professor
            </S.Teacher>
          </>
        ) : null}

        <S.TextDivider />
        {className && (
          <>
            <S.ClassText>{className}</S.ClassText>
            <S.TextDivider />
          </>
        )}
        <S.Date>{createdDate}</S.Date>
      </S.FooterColumn>

      <S.Content isComment={isComment}>
        {mention}
        {content}
      </S.Content>

      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {isPostAuthor && (
              <Menu.Item danger disabled={isDeleting} onClick={onDeletePost}>
                <Space size={8}>
                  <FontAwesomeIcon icon={faTrash} />
                  <span>{isDeleting ? 'Deletando...' : 'Deletar postagem'}</span>
                </Space>
              </Menu.Item>
            )}
            {!isPostAuthor && (
              <Menu.Item disabled={isReporting} onClick={onReportPost}>
                <Space size={8}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <span>{isReporting ? 'Enviando...' : 'Reportar postagem'}</span>
                </Space>
              </Menu.Item>
            )}
          </Menu>
        }
        placement="bottomRight"
      >
        <S.MoreButtton isComment={isComment}>
          <Button type="text" icon={<FontAwesomeIcon icon={faEllipsisV} />} size="small" />
        </S.MoreButtton>
      </Dropdown>

      <S.CardFooter>
        <S.FooterColumn justify="space-between" isComment={isComment}>
          <Button type="text" size="small" onClick={onResponseClick}>
            <FontAwesomeIcon icon={faReply} />
            <S.ButtonLabel>Responder</S.ButtonLabel>
          </Button>

          <S.RightCol>
            {canLike && (
              <PostLikeButton likesCount={likes} postId={postId} authorHasLiked={liked} />
            )}

            <div style={{ opacity: 0.65, marginLeft: 14 }}>
              <FontAwesomeIcon icon={faCommentDots} />
              <S.ButtonLabel>
                {responsesCount} {responseLabel}
              </S.ButtonLabel>
            </div>
          </S.RightCol>
        </S.FooterColumn>
      </S.CardFooter>

      {responseInput && (
        <div style={{ marginTop: 16 }}>
          <PostSubmitForm
            placeholder={submitInputPlaceholder}
            isSubmittig={isSubmittigPost}
            onSubmit={onSubmitPost}
          />
        </div>
      )}

      {footer}
    </S.Card>
  )
}
